const contactUsData = [
  {
    id: 1,
    name: 'Mahmoud Jaber',
    position: 'General Manager',
    title: 'Co-Founder ',
    email: 'mahmoud.jaber@hvahealth.hu',
    phoneNumber: '+962 79 998 8997',
    image: '/assets/Mahmoud-Jaber.jpg',
  },
  {
    id: 2,
    name: 'Peter Hummel',
    position: 'Managing Director ',
    title: 'Co-Founder ',
    email: 'peter.hummel@hvahealth.hu',
    phoneNumber: '+36 70 323 4580',
    image: '/assets/Peter-Hummel5.jpg',
  },
];

export default contactUsData;
