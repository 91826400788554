import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      padding: '1rem',
    },
  },
}));

const PaginationCom = ({
  productsPerPage,
  totalProducts,
  paginate,
  defaultPage,
}) => {
  const classes = useStyles();
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <nav className={classes.root}>
      <Pagination
        count={pageNumbers.length}
        page={defaultPage}
        color='secondary'
        size='small'
        onChange={(obj, number) => paginate(number)}
      />
    </nav>
  );
};

export default PaginationCom;
