import React, { useState, useEffect } from 'react';
import allSpecies from '../data/data';
import allProducts from '../data/products';
import supplementsData from '../data/supplements';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { SideBySideMagnifier } from 'react-image-magnifiers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '6rem',
    padding: '1rem',
    height: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
  },
}));

const SingleProductPage = ({ match }) => {
  const classes = useStyles();
  const productID = match.params.id;
  const [singleProduct, setSingleProduct] = useState({});

  useEffect(() => {
    if (productID >= 172 && productID < 246) {
      allProducts.map((product) => {
        if (product.id.toString() === productID) {
          setSingleProduct(product);
        }
      });
    } else if (productID >= 246) {
      supplementsData.map((product) => {
        if (product.id.toString() === productID) {
          setSingleProduct(product);
        }
      });
    } else {
      allSpecies.map((product) => {
        if (product.id.toString() === productID) {
          setSingleProduct(product);
        }
      });
    }
  }, [singleProduct, productID]);

  return (
    <div className={classes.root}>
      <Grid item sm={3} xs={12} className={classes.imageContainer}>
        <SideBySideMagnifier
          imageSrc={singleProduct.image}
          imageAlt={singleProduct.name}
          alwaysInPlace={true}
        />
      </Grid>

      {productID >= 246 ? (
        <Grid item sm={9} xs={12}>
          <h1>{singleProduct.name}</h1>
          <p>
            <strong>line: </strong> {singleProduct.line}
          </p>
          <p>
            <strong>Indications: </strong> {singleProduct.indications}
          </p>
          <p>
            <strong>Additives Per Liter: </strong>{' '}
            {singleProduct.additives_per_liter}
          </p>
          <p>
            <strong>Excipient: </strong> {singleProduct.excipients}
          </p>
          <p>
            <strong>Recommended Dosage: </strong>{' '}
            {singleProduct.recommended_dosage}
          </p>
          <p>
            <strong>Packaging: </strong> {singleProduct.packaging}
          </p>
        </Grid>
      ) : (
        <Grid item sm={9} xs={12}>
          <h1>{singleProduct.name}</h1>
          <p>
            <strong>Composition: </strong> {singleProduct.composition}
          </p>
          <p>
            <strong>Indications for use: </strong>{' '}
            {singleProduct.indications_for_use}
          </p>
          <p>
            <strong>Packaging: </strong> {singleProduct.packaging}
          </p>
          <p>
            <strong>Withdrawal period: </strong>{' '}
            {singleProduct.withdrawal_period}
          </p>
          <p>
            <strong>Dosage: </strong> {singleProduct.dosage}
          </p>
        </Grid>
      )}
    </div>
  );
};

export default SingleProductPage;
