import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LandingPageCards from './LandingPageCards';
import factories from '../data/landingPageData';
import useWindowPosition from '../hook/useWindowPosition';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const CategoriesLandingPage = () => {
  const classes = useStyles();

  const checked = useWindowPosition('header');

  return (
    <div className={classes.root} id='factory'>
      <LandingPageCards data={factories[0]} checked={checked} />
      <LandingPageCards data={factories[1]} checked={checked} />
      <LandingPageCards data={factories[2]} checked={checked} />
    </div>
  );
};

export default CategoriesLandingPage;
