import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import LabelIcon from '@material-ui/icons/Label';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles((theme) => ({
  appBar: {
    fontFamily: 'Nunito',
  },
  backgroundColor: {
    background: 'none',
  },
  appBarWrapper: {
    width: '80%',
    margin: '0 auto',
  },
  appBarTitle: {
    flexGrow: '1',
  },
  appBarTitleSpanStyle: {
    background: 'white',
    color: '#005605',
    borderRadius: '1rem',
    padding: '0.5rem',
    boxShadow: '5px 5px 20px -5px #2C2C2C',
  },
  subName: {
    color: '#E02A23',
  },
  icon: {
    color: '#333',
    fontSize: '2rem',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  logo: {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/logo.jpg'})`,
    width: '100%',
    height: '100px',
    borderRadius: '2rem',
    backgroundSize: 'cover',
    boxShadow: '5px 5px 20px -5px #2C2C2C',
  },
}));

const Header = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const path = window.location.pathname;
  //   console.log(window.location.pathname);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider /> */}
      <List>
        <ListItem>
          <div className={classes.logo}></div>
        </ListItem>
        <Divider />
        <ListItem button component={Link} to='/'>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary='Home' />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to='/products'>
          <ListItemIcon>
            <LabelIcon />
          </ListItemIcon>
          <ListItemText primary='All Products' />
        </ListItem>
        <ListItem button component={Link} to='/species'>
          <ListItemIcon>
            <LabelIcon />
          </ListItemIcon>
          <ListItemText primary='Products By Species' />
        </ListItem>
        <ListItem button component={Link} to='/generalhealth'>
          <ListItemIcon>
            <LabelIcon />
          </ListItemIcon>
          <ListItemText primary='General Health' />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to='/contactus'>
          <ListItemIcon>
            <LabelIcon />
          </ListItemIcon>
          <ListItemText primary='Contact Us' />
        </ListItem>
      </List>
    </div>
  );
  return (
    <div className={classes.root}>
      <AppBar
        className={
          (classes.appBar,
          path === '/' || path === '/contactus' ? classes.backgroundColor : '')
        }
        elevation={path === '/' || path === '/contactus' ? 0 : 6}
        id='header'
      >
        <Toolbar className={classes.appBarWrapper}>
          <h1 className={classes.appBarTitle}>
            <span className={classes.appBarTitleSpanStyle}>
              HVA <span className={classes.subName}>Health</span>
            </span>
          </h1>
          <React.Fragment>
            <Drawer
              anchor={'right'}
              open={state['right']}
              onClose={toggleDrawer('right', false)}
            >
              {list('right')}
            </Drawer>
            <IconButton
              style={{
                background: '#fff',
                boxShadow: '5px 5px 20px -5px #2C2C2C',
              }}
              onClick={toggleDrawer('right', true)}
            >
              <SortIcon className={classes.icon} />
            </IconButton>
          </React.Fragment>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
