const species = [
  {
    id: 1,
    name: 'Chalkfree Forte',
    type: 'bee',
    image: '/images/bee/Chalkfree-Forte.png',
    composition:
      'Extract of natural essential oils (walnut, thyme, marjoram, wild marjoram), Potassium sorbate',
    indications_for_use:
      'Complementary feed for the development of colonies, particularly in weak colonies of honeybees',
    packaging: '300 ml, 1 l',
    withdrawal_period: 'none',
    dosage:
      'Shake the content of the container vigorously and prepare a 1% solution in sugar syrup. The syrup can be given in a feeder or place a candy on the top of the frames. To stimulate a colony, give a total of 5 liters of the mixture depending on the consumption speed.',
  },
  {
    id: 2,
    name: 'FulvoBee',
    type: 'bee',
    image: '/images/bee/FulvoBee.jpg',
    composition:
      'Moisture: 87,0 %, Humic acids: 9,0 %, Potassium (K): 1,64 %, Sodium (Na): 0,35 %, Calcium (Ca): 0,10 %, Magnesium (Mg): 0,02 %, Phosphorus (P): 64 mg/liter, Iron (Fe): 160 mg/liter, Manganese (Mn): 21 mg/liter, Zinc (Zn): 5 mg/liter',
    indications_for_use:
      'Strenghtens the immune system of honey bees, replenishes mineral and trace elements. The natural antibacterial, antiviral and fungicide effects helps the colonies in the battle against pathogens and stress factors. Mineral feed, administer in drinking water, mixed into sugar syrup. Use it in late summer and autumn. For weak colonys, continuous feeding of Fulvo-BEE is proposed',
    packaging: '1 l plastic bottle',
    withdrawal_period: 'none',
    dosage:
      'Mix 10-30 ml of Fulvoobee to 10 liters of drinking water or sugar syrup.',
  },
  {
    id: 3,
    name: 'Immunobee',
    type: 'bee',
    image: '/images/bee/Immunobee.jpg',
    composition:
      'Moisture: 83,0 %, Humic acids: 11,0 %, Crude ash: 5,50 %, Potassium (K): 1,40 %, Calcium (Ca): 0,25 %, Sodium (Na): 0,20 %, Magnesium (Mg): 0,025 %, Phosphorus (P): 0,005 %, Iron (Fe): 800 mg/liter, Manganese 15 mg/liter, Zinc (Zn): 10 mg/liter, Copper (Cu): 1 mg/liter, Molybdenum (Mo): 1 mg/liter, Cobalt (Co): 0,3 mg/liter',
    indications_for_use:
      'Strenghtens the immune system of honey bees, replenishes mineral and trace elements. The natural antibacterial, antiviral and fungicide effects helps the colonies in the battle against pathogens and stress factors. Mineral feed, adimistered in sugar syrup, particularly in spring. For weak colonys, continuous feeding of Immuno-BEE is proposed.',
    packaging: '1 l plastic bottle',
    withdrawal_period: 'none',
    dosage:
      'Mix 10 ml of ImmunoBee to 10 liters of sugar syrup (50% concentrate)',
  },
  {
    id: 4,
    name: 'Naf',
    type: 'bee',
    image: '/images/bee/Naf.jpg',
    composition: 'Composition: cinnamon, thyme, oregano',
    indications_for_use:
      'ENHANCED LIQUID FEED ADDITIVE FOR HONEYBEES • Natural extract, based on the synergistic mixture of essential oils, among others cinnamon, thyme, oregano • Effective to prevent the American foulbrood disease (caused by Paenibacillus larvae ssp larvae) and to reduce the number of Varroa destructor mites in honeybee colonies',
    packaging: '1 liter',
    withdrawal_period: 'none',
    dosage:
      'Prepare a solution of 5ml NAF in 500ml of sugar syrup (50% sucrose and 50% water). Apply this mixture in ten consecutive days. One treatment consists of 50ml NAF.',
  },
  {
    id: 5,
    name: 'Nonosz Plus',
    type: 'bee',
    image: '/images/bee/Nonosz-Plus.jpg',
    composition: 'natural beet and molasses',
    indications_for_use:
      'ENHANCED LIQUID FEED ADDITIVE FOR HONEYBEES • NONOS PLUS is based on natural beet extract and molasses. • Stimulates development of colony especially in weak colonies • Effective against Nosema apis and Nosema cerenae • Very easy to administer • Safe for human and bee',
    packaging: '1 liter',
    withdrawal_period: 'none',
    dosage:
      'Prepare a 10% solution of NONOS PLUS in sugar syrup (50% sucrose and 50% water). Using a large syringe, sprinkle 100ml of the solution per colony over honeybees in the bee-ways. Repeat the procedure five times, one treatment every other day. Apply in fall after the supers have been removed with the last feeding of the year, and in spring after the first cleaning flights',
  },
  {
    id: 6,
    name: 'BiogenicPet Cod Liver Oil',
    type: 'cat',
    image: '/images/cat/BiogenicPet-Cod-Liver-Oil.jpg',
    composition: 'EPA (Eicosapentaeonic acid) 654 mg',
    indications_for_use:
      'Feed supplement for dogs and cats, oil blend for healthy skin & coat; further maintain for heart and joints.',
    packaging: '100 ml',
    withdrawal_period: 'none',
    dosage:
      'EPA (Eicosapentaeonic acid) 654 mg, DHA (Docosahexaeonic acid) 6540 mg, Retinol (Vitamin A) 81500 IU, Cholecalciferol (Vitamin D3) 10840 IU, Tocoferol (Vitamin E) 800 IU',
  },
  {
    id: 7,
    name: 'BiogenicPet Digestion Dog & Cat',
    type: 'cat',
    image: '/images/cat/BiogenicPet-Digestion-Dog-Cat.jpg',
    composition:
      'Humidity 83.00 %, Humic acids 4.00 %, Calcium (Ca) 0.85 %, Phosphor (P) 0.12 %, Crude ash 11.00 %, Magnesium (Mg) 1.40 %, Iron (Fe) 0.28 %, Sodium (Na) 0.04 % Mineral feed additive for cats and dogs respectively',
    indications_for_use:
      'The active substances in BiogenicPET digestion paste are from humic acids and minerals. These substances were formed over 10 thousand year ago during the plants humification process. Humic acid molecules form complexes with minerals making their absorption more effective. Through the chemical structure and special absorption surface of the molecules bind and remove toxic waist from the intestine and control the intestinal flora. BiogenicPET digestive paste provides efficient complementary therapy for diarrhoeal diseases.',
    packaging: '15 ml',
    withdrawal_period: 'none',
    dosage:
      'In case of mild symptoms: 1 ml once daily for 10 kg bodyweight applied in the morning or at evenings till the symptoms subside, but for at least 3-4 days. Provide spareless quantity of drinking water during treatment. In case of diarrhoea, for supplementary treatment of stomachless, weakened cats: 0.2 ml once daily for 1 kg bodyweight applied in the morning or at evenings till the symptoms subside, but for at least 5-7 days. Provide spareless quantity of drinking water during treatment.',
  },
  {
    id: 8,
    name: 'BiogenicPet Mineral',
    type: 'cat',
    image: '/images/cat/BiogenicPet-Mineral.jpg',
    composition:
      '/1 ml Molibdenum (Mo) 0,067 mg, Copper (Cu) 0,27 mg, Iron (Fe) 2,00 mg, Manganese (Mn) 0,67 mg, Magnesium (Mg) 16,7 mg, Iodine (J) 0,0167 mg, Selenium (Se) 0,017 mg, Zinc (Zn) 1,67 mg',
    indications_for_use:
      'BiogenicPet Mineral drops contains trace elements and minerals in the ideal concentration ratio for dogs and cats. The products can be used alone for prevention, or in combination with medical treatment. Mix into the feed or drinking water. – in case of metabolic disorders, anorexia and weakness: for improving hardiness – health deterioration of unknown origin – complementary therapy of medication during oncologic diseases – in infectious diseases for faster recovery, to support immune system – premedication before surgery, post-operative recovery – replacement of trace elements – improvement of hair quality in alopecia and pale/gray hair – protection of skin and mucous membrane, regeneration of skin, moulting – preparation for dog-shows – pregnancy, lactation – prevention of the above conditions, complementary therapy of medications',
    packaging: '30 ml',
    withdrawal_period: 'none',
    dosage:
      'For dogs: 1 drop/kg bodyweight per day, mixed into a quantity of water or food which is surely taken by the animal. For prevention and roborating BiogenicPet Mineral should be used permanently for 2 months. It is recommended to repeat treatment after 1 month drug pause. In complementary therapy (e.g. in infectious diseases and malignances) it should be used according to the veterinarian’s instruction.For cats: 1 drop/kg bodyweight per day, mixed into a quantity of water or food which is surely taken by the animal.For prevention and roborating BiogenicPet Mineral should be used permanently for 2 months. It is recommended to repeat treatment after 1 month drug pause. In complementary therapy (e.g. in infectious diseases and malignances) it should be used according to the veterinarian’s instruction.',
  },
  {
    id: 9,
    name: 'BiogenicPet Vitality Cat',
    type: 'cat',
    image: '/images/cat/BiogenicPet-Vitality-Cat.jpg',
    composition:
      'Humanofort Feed supplement tablet for cats. Vitality with power of nature.',
    indications_for_use:
      'In cats suffering from chronic joint paints BiogenicPet Vitality Cat significantly improved willingness to exercise. BiogenicPet Vitality Cat improves regeneration of articular cartilage and connected tissues, which results in decreasing of pain. BiogenicPet Vitality Cat induced quicker hair growth. BiogenicPet Vitality Cat has positive effect on healthy function of gastrointestinal tract.',
    packaging: '60 tablet per pack',
    withdrawal_period: 'none',
    dosage:
      'Administer one tablet BiogenicPet Vitality Cat via oral route once',
  },
  {
    id: 10,
    name: 'Caphos Pro',
    type: 'cat',
    image: '/images/cat/Caphos-Pro.jpg',
    composition:
      'Monocalcium phosphate, magnesium phosphate, magnesium chloride',
    indications_for_use:
      'CaPhos Pro is a liquid feed additive serving for the Ca and P supplementation of farm animals as well as for the prevention and treatment of problems caused by locomotor diseases and bone formation disturbances. It is recommended for use as a treatment course in periods of intensive growth, bone development and production. In pigs and in intensively reared poultry flocks its use is recommended in both the starter and the grower phase. In cattle and small ruminants the use of CaPhos Pro is needed in mineral deficiency conditions and when the balance of the calcium–phosphorus metabolism is disturbed. Such conditions include e.g. milk fever, various limb problems and lameness. The product has beneficial effects also in animals with reproductive disturbances (reduced fertility, disturbances of spermatogenesis) and in those with diseases of the skin and hair coat.In pigs, CaPhos Pro is recommended for the prevention and treatment of peripartal problems such as difficult farrowing and MMA syndrome, for supporting the maturation of gametes (ova and spermatozoa) and for the prevention and treatment of various limb problems and lameness.In laying hens, the product is recommended for use mainly at the peak of egg production and when eggshell formation and other production disturbances occur. It has beneficial effects in the prevention of bone development disorders and joint problems, and it also improves fertility. It prevents heat stress under conditions of high ambient temperature. In turkey flocks, CaPhos Pro has been found to be effective in preventing aortic rupture due to copper deficiency.',
    packaging: '1, 20, 5, l',
    withdrawal_period: 'none',
    dosage:
      'The product is administered dissolved in the drinking water, over a period of 5–10 days.',
  },
  {
    id: 11,
    name: 'Illovit E-SE',
    type: 'cat',
    image: '/images/cat/Illovit-E-SE.jpg',
    composition: 'Vitamin-E. selenium',
    indications_for_use:
      'To support the body’s antioxidant system and to prevent diseases, to prevent and treat reproductive disorders associated with vitamin E deficiency. In animals grazed in selenium-deficient areas, it prevents the development of deficiency symptoms and increases the viability of newborn animals. The synergic effects of the added plant extracts boost the immune system, and fix the efficency of physiological processes. Its biological effect is well established even during viral infections and vaccinations. Vascular protective, detoxifying processes are initiated, which accelerate the regeneration mechanisms.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'none',
    dosage:
      'Dissolved in drinking water. Do not use without dilution General dose: 0.5 ml / liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days.It is recommended to have the daily amount be drank in 4-6 hours.',
  },
  {
    id: 12,
    name: 'Illovit Hepar',
    type: 'cat',
    image: '/images/cat/Illovit-Hepar.jpg',
    composition:
      'Vitamin-B1, Vitamin-B6, Niacinamide, Choline Chloride, Inositol, L-Carnitine, Amino Acids, salts and analogs thereof: Lysine, DL-Methionine',
    indications_for_use:
      'It is recommended in all cases when the liver is overloaded. In particular: gastrointestinal diseases, feeding defects, suspicion of poisoning, viral infections, in the presence of feed toxins. To help liver function in herds with intensive growth vigor. It is recommended to use it after reaching peak production. The composition increases the detoxification capacity of the liver by administering methyl donors such as methionine or choline chloride. Following the use of antibiotics, the ability of the intestinal flora to synthesize vitamins is significantly reduced, so it is vital to administer B vitamins after treatment. Illovit Hepar helps herd regenerate and maximize production during and after treatment. Due to its composition, it maximizes the growth capacity of young animals. The benefits of the added plant extracts are based on traditional folk use, which has been confirmed by animal health impact studies. The extracts accelerate the body’s natural regeneration processes through their liver-supporting synergistic effect. They strengthen blood vessel walls, improve blood circulation. Gently activates and aids digestive and respiratory functions in animals with reduced production capacity.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'none',
    dosage:
      '0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg of body weight for 3-7 days. It is recommended to have the daily amount be drank in 4-6 hours',
  },
  {
    id: 13,
    name: 'Illovit Multivitamin',
    type: 'cat',
    image: '/images/cat/Illovit-Multivitamin.jpg',
    composition:
      'Vitamin-A, Vitamin-NED3, Vitamin-NEE, Vitamin-K3, Vitamin-B1, Vitamin-B2, Vitamin-B6, Vitamin-B12, Niacinamide, Choline Chloride, Pantothenic acid, Biotin',
    indications_for_use:
      'Combined multivitamin preparation. To maintain and increase growth vigor in the event of colds and weather front effects. It is suitable for vitamin supplementation of pets of all species and ages, for overcoming stress and deficiency diseases. Accelerates post-disease regeneration. The synergistic beneficial effect of the added plant extracts specifically protects and supports the respiratory system. It cleans the airways, helps to remove stuck mucus. It helps in post-disease regeneration, improves feed utilization, and aids digestion efficiency. It supports the circulatory system and the heart.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'none',
    dosage:
      '0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days. It is recommended to have the daily amoun be drank in 4-6 hours.',
  },
  {
    id: 14,
    name: 'Jolovit',
    type: 'cat',
    image: '/images/cat/Jolovit.jpg',
    composition:
      'Vitamin A 14.000 I.U., Vitamin d3 1.400 I.U., Vitamin E 10.5 mg, Vitamin K3 0.175 mg, Vitamin B1 1.4 mg, Vitamin B2 2.1 mg, Vitamin B6 1.4 mg, VitaminB12 0.014 mg, d-Panthenol 6.55 mg, Niacinamide 14.0 mg, Biotin 0.035 mg, Colin chloride 140 mg, B-carotene 0,01 mg',
    indications_for_use:
      'A combined multivitamin product to be used for vitamin supplementation in farm animals of any age and species, in order to correct vitamin deficiency caused by stress occurring at the time of placement and feed change. Owing to its composition, Jolovit is suitable for ensuring optimum vitamin supply, which is a precondition of adequate production performance. It reduces the negative effects of stress, improves the viability of young animals and the hatchability of eggs, and accelerates recovery after diseases.',
    packaging: '0.1 l bottle, 1 l bottle, 5 l and 20 l plastic container',
    withdrawal_period: 'none',
    dosage: 'see label for the details.',
  },
  {
    id: 15,
    name: 'BiogenicPet Immunity',
    type: 'cattle',
    image: '/images/cattle/BiogenicPetImmunity.jpg',
    composition:
      '/1 ml: BAP-8 (standardised pleuran extract) 10,0 mg, Excipients ad 1,0 ml, Syrup for oral use medicinal preparation',
    indications_for_use:
      'Biogenic Pet Immunity syrup is an immune stimulator product for oral use. Its active ingredient is BAP-8 (standardised pleuran extract) which is a mixture of natural polysaccharides containing a complex of beta-d-glucans. It is isolated from oyster mushroom (Pleurotus ostreatus). BAP-8 is modifying cellular elements of the immune system, enhances resistance and vitality of animals. Alleviates course of disease in diseased animals. It is recommended in case of extreme physical stress and fatigue.',
    packaging: '120 ml',
    withdrawal_period: 'None',
    dosage:
      'Biogenic Pet Immunity syrup should be applied with the included dispenser or with a syringe into the feed or drinking water, or directly into the mouth of the animal. Shake well before use! For dogs: 2 ml/5 kg bodyweight (between 1–5 kg bodyweight use uniformly 2 ml) once daily. For cattle: 1 ml/10 kg bodyweight once daily. For improvement of resistance of the organism it is recommended to apply Biogenic Pet Immuno for a longer period – at least for 2-3 months. daily dose can be doubled in the beginning of treatment, at least for 3 days.',
  },
  {
    id: 16,
    name: 'Caphos Pro',
    type: 'cattle',
    image: '/images/cattle/CaphosPro.jpg',
    composition:
      'Monocalcium phosphate, magnesium phosphate, magnesium chloride',
    indications_for_use:
      'CaPhos Pro is a liquid feed additive serving for the Ca and P supplementation of farm animals as well as for the prevention and treatment of problems caused by locomotor diseases and bone formation disturbances. It is recommended for use as a treatment course in periods of intensive growth, bone development and production. In pigs and in intensively reared poultry flocks its use is recommended in both the starter and the grower phase. In cattle and small ruminants the use of CaPhos Pro is needed in mineral deficiency conditions and when the balance of the calcium–phosphorus metabolism is disturbed. Such conditions include e.g. milk fever, various limb problems and lameness. The product has beneficial effects also in animals with reproductive disturbances (reduced fertility, disturbances of spermatogenesis) and in those with diseases of the skin and hair coat.In pigs, CaPhos Pro is recommended for the prevention and treatment of peripartal problems such as difficult farrowing and MMA syndrome, for supporting the maturation of gametes (ova and spermatozoa) and for the prevention and treatment of various limb problems and lameness.In laying hens, the product is recommended for use mainly at the peak of egg production and when eggshell formation and other production disturbances occur. It has beneficial effects in the prevention of bone development disorders and joint problems, and it also improves fertility. It prevents heat stress under conditions of high ambient temperature. In turkey flocks, CaPhos Pro has been found to be effective in preventing aortic rupture due to copper deficiency.',
    packaging: '1, 20, 5, l',
    withdrawal_period: 'None',
    dosage:
      'The product is administered dissolved in the drinking water, over a period of 5–10 days.',
  },
  {
    id: 17,
    name: 'Illovit E-SE',
    type: 'cattle',
    image: '/images/cattle/IllovitE-SE.jpg',
    composition: 'Vitamin-E. selenium',
    indications_for_use:
      'To support the body’s antioxidant system and to prevent diseases, to prevent and treat reproductive disorders associated with vitamin E deficiency. In animals grazed in selenium-deficient areas, it prevents the development of deficiency symptoms and increases the viability of newborn animals. The synergic effects of the added plant extracts boost the immune system, and fix the efficency of physiological processes. Its biological effect is well established even during viral infections and vaccinations. Vascular protective, detoxifying processes are initiated, which accelerate the regeneration mechanisms.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'Dissolved in drinking water. Do not use without dilution General dose: 0.5 ml / liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days.It is recommended to have the daily amount be drank in 4-6 hours.',
  },
  {
    id: 18,
    name: 'Illovit Hepar',
    type: 'cattle',
    image: '/images/cattle/IllovitHepar.jpg',
    composition:
      'Vitamin-B1, Vitamin-B6, Niacinamide, Choline Chloride, Inositol, L-Carnitine, Amino Acids, salts and analogs thereof: Lysine, DL-Methionine',
    indications_for_use:
      'It is recommended in all cases when the liver is overloaded. In particular: gastrointestinal diseases, feeding defects, suspicion of poisoning, viral infections, in the presence of feed toxins. To help liver function in herds with intensive growth vigor. It is recommended to use it after reaching peak production. The composition increases the detoxification capacity of the liver by administering methyl donors such as methionine or choline chloride. Following the use of antibiotics, the ability of the intestinal flora to synthesize vitamins is significantly reduced, so it is vital to administer B vitamins after treatment. Illovit Hepar helps herd regenerate and maximize production during and after treatment. Due to its composition, it maximizes the growth capacity of young animals. The benefits of the added plant extracts are based on traditional folk use, which has been confirmed by animal health impact studies. The extracts accelerate the body’s natural regeneration processes through their liver-supporting synergistic effect. They strengthen blood vessel walls, improve blood circulation. Gently activates and aids digestive and respiratory functions in animals with reduced production capacity.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      '0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg of body weight for 3-7 days. It is recommended to have the daily amount be drank in 4-6 hours',
  },
  {
    id: 19,
    name: 'Illovit Multivitamin',
    type: 'cattle',
    image: '/images/cattle/IllovitMultivitamin.jpg',
    composition:
      'Vitamin-A, Vitamin-NED3, Vitamin-NEE, Vitamin-K3, Vitamin-B1, Vitamin-B2, Vitamin-B6, Vitamin-B12, Niacinamide, Choline Chloride, Pantothenic acid, Biotin',
    indications_for_use:
      'Combined multivitamin preparation. To maintain and increase growth vigor in the event of colds and weather front effects. It is suitable for vitamin supplementation of pets of all species and ages, for overcoming stress and deficiency diseases. Accelerates post-disease regeneration. The synergistic beneficial effect of the added plant extracts specifically protects and supports the respiratory system. It cleans the airways, helps to remove stuck mucus. It helps in post-disease regeneration, improves feed utilization, and aids digestion efficiency. It supports the circulatory system and the heart.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'General dose: 0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days. It is recommended to have the daily amoun be drank in 4-6 hours.',
  },
  {
    id: 20,
    name: 'Jolovit',
    type: 'cattle',
    image: '/images/cattle/Jolovit.jpg',
    composition:
      'Vitamin A 14.000 I.U., Vitamin d3 1.400 I.U., Vitamin E 10.5 mg, Vitamin K3 0.175 mg, Vitamin B1 1.4 mg, Vitamin B2 2.1 mg, Vitamin B6 1.4 mg, VitaminB12 0.014 mg, d-Panthenol 6.55 mg, Niacinamide 14.0 mg, Biotin 0.035 mg, Colin chloride 140 mg, B-carotene 0,01 mg',
    indications_for_use:
      'A combined multivitamin product to be used for vitamin supplementation in farm animals of any age and species, in order to correct vitamin deficiency caused by stress occurring at the time of placement and feed change. Owing to its composition, Jolovit is suitable for ensuring optimum vitamin supply, which is a precondition of adequate production performance. It reduces the negative effects of stress, improves the viability of young animals and the hatchability of eggs, and accelerates recovery after diseases.',
    packaging: '0.1 l bottle, 1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'see label for the details.',
  },
  {
    id: 21,
    name: 'Neo-Atympan',
    type: 'cattle',
    image: '/images/cattle/Neo-Atympan.png',
    composition:
      'Sunflower oil, antifoaming agent 18.5ml, water ad 100ml. Additives: citric acid E330',
    indications_for_use:
      'Neo-Atympan is an aid in the treatment of acute bloating in ru-minants. It is effective in the decreasing foaming, which is based on the destroying the foam by influencing the surface tension. It causes the merging of the gas bubbles in rumen which then allows easier passing of gas in a few minutes. If the release of the gases is obstructed or unsatisfactory, other instruments (stomach tube, trocar) may be required.',
    packaging: '100 ml',
    withdrawal_period: 'None',
    dosage:
      'It can be applied by long-necked bottle or by tube intraruminally. Shake well before use! cattle: 100 mlcalf: 50 ml sheep, goats: 20-25 ml',
  },
  {
    id: 23,
    name: 'Norovit Hepar',
    type: 'cattle',
    image: '/images/cattle/NorovitHepar.jpg',
    composition:
      '/ ml: Vitamin B1 (3a820) 5.0 mg, Vitamin B2 4.0 mg, Vitamin B6 (3a831) 4.0 mg, D-panthenol (3a842) 20.0 mg, Biotin (3a880) 0.015 mg, Vitamin K3 (3a710) 2.0 mg, Niacinamid (3a315) 40.0 mg, Inositol 5.0 mg, Choline chloride (3a890) 200.0 mg, Lysine HCl 20.0 mg, DL-methionine 10.0 mg. Like choline, inositol has a ‘fat burning’ effect, it prevents the deposition of fats in the liver and accelerates their excretion. Vitamins of the B group provide energy for the body by supporting the metabolism of carbohydrates, proteins and fats.',
    indications_for_use:
      'To support liver function, especially in flocks and herds of intensive growth rate. The product enhances the detoxifying capacity of the liver by introducing into the body methyl donors such as methionine or choline chloride.',
    packaging: '1 liter and 5 liter plastic container.',
    withdrawal_period: 'None',
    dosage: ' for details see the label.',
  },
  {
    id: 24,
    name: 'Norovit Thermo',
    type: 'cattle',
    image: '/images/cattle/NorovitThermo.jpg',
    composition:
      '/ ml: L-carnitine (3a910) 30.0 mg, Vitamin E (3a700) 40.0 mg, Vitamin B1 (3a820) 5.0 mg, Niacinamide (3a315) 10.0 mg, Vitamin B6 (3a831) 4.0 mg, Vitamin B12 0.02 mg, DL-methionine 10.0 mg, Lysine HCl 10.0 mg, Sodium selenite (E8) 0.40 mg, Sorbitol 160.0 mg ',
    indications_for_use:
      ' A product suitable for preventing the adverse effects of heat stress. Its constituents support the thermoregulation of animals. Heat stress damages the antioxidant system, and an elevated vitamin E and selenium level is needed to counter this effect. Heat shock results in a higher water and water-soluble vitamin loss for the organism, and there-fore the supplementation of vitamins of group B is absolutely necessary. In addition, vitamins B support the animals’ metabolism. Niacinamide participates in almost all enzymatic processes as a transmitter, as a signalling compound or as an energy-supplying compound.',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: ' for details see the label.',
  },
  {
    id: 25,
    name: 'Ruminogen pulvis',
    type: 'cattle',
    image: '/images/cattle/Ruminogenpulvis.jpg',
    composition: 'K-propionate',
    indications_for_use:
      'Treatment for cattle, sheep and goat primarily alimentary based, secondary  for acute and intensive craw torpidity cases related to organ diseases, delivery, post operational condition, etc. rumen  dysfunction. Curing acute bloating in calves and therapy for ketosis in cows.  It will restore the normal biological balance in the rumen, it will bring optimal activity in the ﬂora and fauna liquid of the rumen. It uses the fumaric-acid and propionic acid absorbed from it for the glycogen synthesis of the liver, to prevent liver damage.',
    packaging: '100 g, 1 kg, 5 kg',
    withdrawal_period: 'None',
    dosage: '100 g/animal/day in case of rumen problems.',
  },
  {
    id: 26,
    name: 'Ruminostart drench',
    type: 'cattle',
    image: '/images/cattle/Ruminostartdrench.jpg',
    composition:
      'Amino acids, Ca-proprionate, Glucose. Ruminostart Drench is a special feed supplement developed for freshly calved cows.',
    indications_for_use:
      'Use immediately after calving, and during the production for mitigation of energy metabolism disorders and for prevention and adjunctive treatment of various metabolic disorders.',
    packaging: '700 g',
    withdrawal_period: 'None',
    dosage:
      '700 g/cow in twenty liter of warm water after calving or in case of rumen problems.',
  },
  {
    id: 27,
    name: 'Tetrakolin',
    type: 'cattle',
    image: '/images/cattle/Tetrakolin.jpg',
    composition:
      '/1 ml: Vitamin B2 5.00 mg, Niacinamide (3a315) 50.00 mg, Vitamin B6 (3a831) 8.00 mg, Vitamin B12 0.05 mg, d-panthenol (3a842) 86.00 mg, Biotin (3a880) 0.10 mg, Choline chloride (3a890) 21.75 mg',
    indications_for_use:
      'A complex vitamin B product to facilitate utilisation of the young-age growth capacity of farm animals of high growth rate. In laying hens, it helps lengthen the egg production cycle and reduces the severity of diseases and toxicoses of dietary origin. By the regular use of the product the liver damage of intensively pro-ducing farm animals can be prevented. The product increases the resistance of the liver to hepato-toxic effects. Through its hepatoprotective effect, the product supports the prevention and treatment of ketosis in dairy cows.',
    packaging: '1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 28,
    name: 'Tetraselenium 400-E',
    type: 'cattle',
    image: '/images/cattle/Tetraselenium400-E.jpg',
    composition: '/1 ml: Vitamin E (3a700) 80 mg, Sodium selenite (E8) 400 µg',
    indications_for_use:
      'Their deficiency primarily leads to degenerative changes of the myocardium, the skeletal muscles and the wall of blood vessels (skeletal muscle degeneration, encephalomalacia, exudative diathesis). Its use is recommended in the period of seualx maturation and fertilisation, and for the treatment of reproductive disturbances.',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 29,
    name: 'Tetravit ADE',
    type: 'cattle',
    image: '/images/cattle/TetravitADE.jpg',
    composition:
      '/1 ml: Nutritional additives – Vitamins and provitamins: Vitamin A (3a672b) 72.000 IU, Vitamin d3 (E671) 36.000 IU, Vitamin E (3a700) 72 mg. Owing to its high concentrations of vitamins A-, d3 – and E, the product strengthens the resistance of animals to infectious diseases. It supports normal feathering, the healthy development of bones and normal cardiac function. The product is excellently suitable for enhancing the general re-sistance of animals.',
    indications_for_use:
      'In growing animals, it is recommended for use once or twice monthly as a treatment course. When administered to pregnant female animals in the last third of gestation, the product increases the general resistance of the newborn animals, improves the regeneration of postparturient female animals, supports reconception, and shortens the service period. When administered to laying flocks once or twice a month as a treatment course, the product increases eggshell strength and in breeder flocks it improves fertility and hatchability.',
    packaging: '1 l plastic bottle. Packaging: 1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 30,
    name: 'Uppetapol',
    type: 'cattle',
    image: '/images/cattle/Uppetapol.jpg',
    composition: 'humic acid',
    indications_for_use:
      'Aroma and flavor enhancing feed additive supplemented with humic acids. Contains Uppetite product. It gives the feed an excellent taste and aroma. Improves the animal’s resilience.',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      '10 dkg / animal / day mixed with daily feed for dairy cows and beef cattle',
  },
  {
    id: 31,
    name: 'Vitamix B',
    type: 'cattle',
    image: '/images/cattle/VitamixB.jpg',
    composition:
      '/1 ml: Vitamin B1 (3a820) 4.00 mg, Vitamin B2 6.00 mg, Niacinamide (3a315) 30.00 mg, Vitamin B6 (3a831) 3.00 mg, Vitamin B12 0.015 mg, d-panthenol (3a842) 8.6 mg, Biotin (3a880) 0.03 mg, Folic acid (3a316) 0.5 mg',
    indications_for_use:
      'A complex vitamin B product which contains all essential group B vitamins. It increases the general resistance and stress tolerance of animals. It can be used favourably in cases of deficient vitamin synthesis following antibiotic therapy. By its use, vitamin B deficiency manifesting itself in decreased production and impaired appetite can be corrected.',
    packaging: '1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for dosage see the label.',
  },
  {
    id: 32,
    name: 'Vitapol Antimast',
    type: 'cattle',
    image: '/images/cattle/VitapolAntimast.jpg',
    composition:
      'Helps the regeneration of udder tissues and to fight off bacteria, thus reducing milk somatic cell count. In addition, Vitapol AntiMast can also prevent and stop diarrhea on short term and reduce lameness, laminitis, Mortellaro-disease (digital dermatitis) on the long run. ',
    indications_for_use:
      'Helps the regeneration of udder tissues and to fight off bacteria, thus reducing milk somatic cell count. In addition, Vitapol AntiMast can also prevent and stop diarrhea on short term and reduce lameness, laminitis, Mortellaro-disease (digital dermatitis) on the long run.',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      '150 g/cow/day for 14 days. On the 15th day sampling and controlling the somatic cell count in the milk. For more details see the label.',
  },
  {
    id: 33,
    name: 'Vitapol Antitox',
    type: 'cattle',
    image: '/images/cattle/VitapolAntitox.jpg',
    composition:
      'Humic and fulvic acids, yeast cell wall extract, modified clay components',
    indications_for_use:
      'To neutralize the impact of mycotoxins without the drawbacks of conventional toxinbinders. VitaTox is unique on the market, a multi-component, wide spectrum feed additive, which provides an effective neutralisation of the damage caused by the mycotoxins. It eliminates toxic heavy metals from the body, detoxifyes and strengthens the immune system. It is in an easy to mix powder form, for mixing to feeds. It is suggested to mix to the feed before granulating. It can be mixed in one round with all medicals, vitamins and other feed supplements. Its active ingredients are not degraded by heat or pressure, and are stable.',
    packaging: 'in 25 kg paper bag',
    withdrawal_period: 'None',
    dosage:
      'depending on the toxin contamination, its dosage is from 2-4 kg/1000 kg of feed.',
  },
  {
    id: 34,
    name: 'Vitapol Antitox Pulv',
    type: 'cattle',
    image: '/images/cattle/VitapolAntitoxPulv.jpg',
    composition:
      '1m558 modified bentonite, Yeast cell wall extract, Organic mineral with high humic acid content',
    indications_for_use:
      'Functional group: substances for reduction of the contamination of feed by mycotoxins',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      'Starter feed: 2 kg/ton, Grower-finisher feed: 1 kg/ton, Feeds heavily contaminated with mycotoxins: 2-4 kg/ton, The simultaneous oral use with macrolides and with robenidine shall be avoided',
  },
  {
    id: 35,
    name: 'Vitapol Pulvis',
    type: 'cattle',
    image: '/images/cattle/VitapolPulvis.jpg',
    composition:
      'Contains natural materials only. Binds toxicants and chemical residues. Enhances stress endurance. Its use results in a faster weigth gain and in a healthier stock. Decreases mortality.',
    indications_for_use:
      'Vitapol® mineral feed exclusively contains natural raw materials, natural acids, minerals and micro elements. It contains minerals and micronutrients in an organic humic acid complex form, so they are easily absorbed in the digestive tract of animals, thus promoting the digestibility of feed and a better feed consumption. Humic acids as natural agents, are possible alternatives of antibiotics and chemotherapeutic preparations in many ways. Thanks to its unique composition Vitapol® has a positive effect on every species, it strengthens the immune system, and it helps the profitabilty of farming.',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      'The recommended amount is added mixed with feed. It can also be mixed into foods at home. It does not interact with other active ingredients, is sterilizable and stable. Sows: 0.5% continuously mixed from the choice into the sow diet. Newborn piglets: 0.5-1.0% mixed or sprinkled on the first solid feed.',
  },
  {
    id: 36,
    name: 'Vitapol suspension',
    type: 'cattle',
    image: '/images/cattle/Vitapolsuspension.jpg',
    composition:
      'Moisture 85% Humic acids 80 %, Sodium (Na) 0.02 %, Crude ash 4 %, Calcium (Ca) 1.1 % Magnesium (Mg) 0,1 %, Phosphorus (P) 0.02 % Iron (Fe) 600 mg/kg, Manganese (Mn) 18 mg/kg, Zinc (Zn) 8.0 mg/kg, Molybdenum (Mo) 0.6 mg/kg, Cobalt (Co) 0.4 mg/kg',
    indications_for_use:
      'It can be mixed into the milk or milk replacer of calves or poured on the feed. For piglets serve on a tray on the firs week. Strengthens the immune system and prevents diarrhea.',
    packaging: 'in 20 kg plastic bucke',
    withdrawal_period: 'None',
    dosage:
      'Cattle Calf: 1 dl/day/calf mixed into the milk replacer, In case of severe diarrhoea 2 dl/day/calf. Adult cattle: 2 dl/animal/day. Swine Newborn piglets: 1 dl/litter/day. Sows: 1 dl/day/animal during the late pregnancy and lactation.',
  },
  {
    id: 37,
    name: 'Caphos Pro',
    type: 'chicken',
    image: '/images/chicken/CaphosPro.jpg',
    composition:
      'Monocalcium phosphate, magnesium phosphate, magnesium chloride',
    indications_for_use:
      'CaPhos Pro is a liquid feed additive serving for the Ca and P supplementation of farm animals as well as for the prevention and treatment of problems caused by locomotor diseases and bone formation disturbances. It is recommended for use as a treatment course in periods of intensive growth, bone development and production. In pigs and in intensively reared poultry flocks its use is recommended in both the starter and the grower phase. In cattle and small ruminants the use of CaPhos Pro is needed in mineral deficiency conditions and when the balance of the calcium–phosphorus metabolism is disturbed. Such conditions include e.g. milk fever, various limb problems and lameness. The product has beneficial effects also in animals with reproductive disturbances (reduced fertility, disturbances of spermatogenesis) and in those with diseases of the skin and hair coat.In pigs, CaPhos Pro is recommended for the prevention and treatment of peripartal problems such as difficult farrowing and MMA syndrome, for supporting the maturation of gametes (ova and spermatozoa) and for the prevention and treatment of various limb problems and lameness.In laying hens, the product is recommended for use mainly at the peak of egg production and when eggshell formation and other production disturbances occur. It has beneficial effects in the prevention of bone development disorders and joint problems, and it also improves fertility. It prevents heat stress under conditions of high ambient temperature. In turkey flocks, CaPhos Pro has been found to be effective in preventing aortic rupture due to copper deficiency.',
    packaging: '1, 20, 5, l',
    withdrawal_period: 'None',
    dosage:
      'The product is administered dissolved in the drinking water, over a period of 5–10 days.',
  },
  {
    id: 38,
    name: 'Doxyphyl WSP A.U.V.',
    type: 'chicken',
    image: '/images/chicken/DoxyphylWSPA.U.V..jpg',
    composition:
      'doxycycline hyclate 300 mg/g. Treatment of infections caused by Mycplasma spp. and other bacteria sensitive for doxycycline.',
    indications_for_use:
      'Fowl cholera (Pasteurella multocida), Infectious synovitis (Mycoplasma synoviae), Infectious sinusitis of turkey (Mycoplasma gallisepticum), Chronic respiratory disease of chickens (Mycoplasma gallisepticum) The product should be used on the basis of the results of antibiotic susceptibility tests by taking into consideration the guidelines for the use of antimicrobial agents.',
    packaging: '1 kg in polyethylene box closed with a polyethylene screw cap.',
    withdrawal_period: 'None',
    dosage:
      'General dosage is 15 mg doxycycline-hyclate powder / 1 kilogram of bodyweight for the preparation of a solution for internal use for animal treatment over a period of 3–5 days. Calculated amount of product shall be added to drinking water of animals taken in within three hours. Before treatment animals shall be withdrawn from water.',
  },
  {
    id: 39,
    name: 'Illovit Hepar',
    type: 'chicken',
    image: '/images/chicken/IllovitHepar.jpg',
    composition:
      'Vitamin-B1, Vitamin-B6, Niacinamide, Choline Chloride, Inositol, L-Carnitine, Amino Acids, salts and analogs thereof: Lysine, DL-Methionine',
    indications_for_use:
      'It is recommended in all cases when the liver is overloaded. In particular: gastrointestinal diseases, feeding defects, suspicion of poisoning, viral infections, in the presence of feed toxins. To help liver function in herds with intensive growth vigor. It is recommended to use it after reaching peak production. The composition increases the detoxification capacity of the liver by administering methyl donors such as methionine or choline chloride. Following the use of antibiotics, the ability of the intestinal flora to synthesize vitamins is significantly reduced, so it is vital to administer B vitamins after treatment. Illovit Hepar helps herd regenerate and maximize production during and after treatment. Due to its composition, it maximizes the growth capacity of young animals. The benefits of the added plant extracts are based on traditional folk use, which has been confirmed by animal health impact studies. The extracts accelerate the body’s natural regeneration processes through their liver-supporting synergistic effect. They strengthen blood vessel walls, improve blood circulation. Gently activates and aids digestive and respiratory functions in animals with reduced production capacity.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      '0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg of body weight for 3-7 days. It is recommended to have the daily amount be drank in 4-6 hours',
  },
  {
    id: 40,
    name: 'Illovit Multivitamin',
    type: 'chicken',
    image: '/images/chicken/IllovitMultivitamin.jpg',
    composition:
      'Vitamin-A, Vitamin-NED3, Vitamin-NEE, Vitamin-K3, Vitamin-B1, Vitamin-B2, Vitamin-B6, Vitamin-B12, Niacinamide, Choline Chloride, Pantothenic acid, Biotin',
    indications_for_use:
      'Combined multivitamin preparation. To maintain and increase growth vigor in the event of colds and weather front effects. It is suitable for vitamin supplementation of pets of all species and ages, for overcoming stress and deficiency diseases. Accelerates post-disease regeneration. The synergistic beneficial effect of the added plant extracts specifically protects and supports the respiratory system. It cleans the airways, helps to remove stuck mucus. It helps in post-disease regeneration, improves feed utilization, and aids digestion efficiency. It supports the circulatory system and the heart.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'General dose: 0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days. It is recommended to have the daily amoun be drank in 4-6 hours.',
  },
  {
    id: 41,
    name: 'Jolovit',
    type: 'chicken',
    image: '/images/chicken/Jolovit.jpg',
    composition:
      'Vitamin A 14.000 I.U., Vitamin d3 1.400 I.U., Vitamin E 10.5 mg, Vitamin K3 0.175 mg, Vitamin B1 1.4 mg, Vitamin B2 2.1 mg, Vitamin B6 1.4 mg, VitaminB12 0.014 mg, d-Panthenol 6.55 mg, Niacinamide 14.0 mg, Biotin 0.035 mg, Colin chloride 140 mg, B-carotene 0,01 mg',
    indications_for_use:
      'A combined multivitamin product to be used for vitamin supplementation in farm animals of any age and species, in order to correct vitamin deficiency caused by stress occurring at the time of placement and feed change. Owing to its composition, Jolovit is suitable for ensuring optimum vitamin supply, which is a precondition of adequate production performance. It reduces the negative effects of stress, improves the viability of young animals and the hatchability of eggs, and accelerates recovery after diseases.',
    packaging: '0.1 l bottle, 1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'see label for the details.',
  },
  {
    id: 42,
    name: 'Neo-Te-Sol pulvis A.U.V.',
    type: 'chicken',
    image: '/images/chicken/Neo-Te-SolpulvisA.U.V.jpg',
    composition:
      '/ g: Oxytetracycline-HCI 0.18 g, Neomycin-sulfate 0.12 g, Sacharose ad 1 g',
    indications_for_use:
      'For treatment of infections caused by Pasteurella multocida, Enterococcus spp., E. coli, Staphylococcus spp., Campylobacter jejuni, sensitive to oxytetracycline and neomycine',
    packaging: '50 g, 250 g, 1000 g',
    withdrawal_period: 'Meat and offal: 3 days Egg: 0 day',
    dosage:
      'For oral use, mixed into the drinking water. Use 120 mg/body weight kg daily for 5 days. Prepare fresh mixture for every days.',
  },
  {
    id: 43,
    name: 'Norovit Amino Forte',
    type: 'chicken',
    image: '/images/chicken/NorovitAminoForte.jpg',
    composition:
      '/ ml: Vitamin A (3a672b) 40,000, IU Vitamin D3 (E671) 5,000, IU Vitamin E (3a700) 20.0 mg, Vitamin K3 (3a710) 2.0 mg, Vitamin B1 (3a820) 2.0 mg, Vitamin B2 0.2 mg, Vitamin B6 (3a831) 2.0 mg, Niacinamide (3a315) 14.0 mg, Vitamin C (E301) 5.0 mg, DL-methionine 8.2 mg, Lysine HCl 11.0 mg, Tryptophan 1.8 mg, Threonine 5.0 mg',
    indications_for_use:
      'A combination of essential amino acids and vitamins. It enhances muscle building and weight gain. Its use is recommended after placement to achieve higher constitutional strength and to pre-vent the adverse effects of stress due to movement and vaccination of the animals.',
    packaging: '1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 44,
    name: 'Norovit Hepar',
    type: 'chicken',
    image: '/images/chicken/NorovitHepar.jpg',
    composition:
      '/ ml: Vitamin B1 (3a820) 5.0 mg, Vitamin B2 4.0 mg, Vitamin B6 (3a831) 4.0 mg, D-panthenol (3a842) 20.0 mg, Biotin (3a880) 0.015 mg, Vitamin K3 (3a710) 2.0 mg, Niacinamid (3a315) 40.0 mg, Inositol 5.0 mg, Choline chloride (3a890) 200.0 mg, Lysine HCl 20.0 mg, DL-methionine 10.0 mg. Like choline, inositol has a ‘fat burning’ effect, it prevents the deposition of fats in the liver and accelerates their excretion. Vitamins of the B group provide energy for the body by supporting the metabolism of carbohydrates, proteins and fats.',
    indications_for_use:
      'To support liver function, especially in flocks and herds of intensive growth rate. The product enhances the detoxifying capacity of the liver by introducing into the body methyl donors such as methionine or choline chloride.',
    packaging: '1 liter and 5 liter plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 45,
    name: 'Norovit Thermo',
    type: 'chicken',
    image: '/images/chicken/NorovitThermo.jpg',
    composition:
      '/ ml: L-carnitine (3a910) 30.0 mg, Vitamin E (3a700) 40.0 mg, Vitamin B1 (3a820) 5.0 mg, Niacinamide (3a315) 10.0 mg, Vitamin B6 (3a831) 4.0 mg, Vitamin B12 0.02 mg, DL-methionine 10.0 mg, Lysine HCl 10.0 mg, Sodium selenite (E8) 0.40 mg, Sorbitol 160.0 mg',
    indications_for_use:
      'A product suitable for preventing the adverse effects of heat stress. Its constituents support the thermoregulation of animals. Heat stress damages the antioxidant system, and an elevated vitamin E and selenium level is needed to counter this effect. Heat shock results in a higher water and water-soluble vitamin loss for the organism, and there-fore the supplementation of vitamins of group B is absolutely necessary. In addition, vitamins B support the animals’ metabolism. Niacinamide participates in almost all enzymatic processes as a transmitter, as a signalling compound or as an energy-supplying compound.',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 46,
    name: 'Phylamic',
    type: 'chicken',
    image: '/images/chicken/Phylamic.jpg',
    composition:
      '/1 ml: Mn (E5) 12.0 mg, Zn (E6) 8.0 mg, Cu (E4) 1.6 mg, Se (E8) 0.03 mg',
    indications_for_use:
      'A microelement complex that supports enzyme reactions in the body and cartilage development in animals of a high growth rate. Its use is recommended after placement and in case of locomotor problems (extremital abnormalities caused by microelement deficiencies in poultry and turkeys, thickening of joints, hyperkeratosis, anaemia, developmental disturbances). Regular use of the product increases egg production, fertility and hatchability. When using the product, the microelement content of the diets fed to the birds must be taken into consideration.',
    packaging:
      '100 ml and 1 liter, in plastic bottle; 5 l and 20 l in plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 47,
    name: 'Phylamox 100 % WSP A.U.V.',
    type: 'chicken',
    image: '/images/chicken/Phylamox100WSPA.U.V.jpg',
    composition:
      'amoxicilin. The veterinary medicinal product serves for the treatment of infectious diseases caused by amoxicillin-sensitive Gram-positive and Gram-negative bacteria in domestic fowl (broiler chickens) and pigs.',
    indications_for_use:
      'Pasteurellosis (Pasteurella multocida), Infections caused by Streptococcus suis • Pleuropneumonia of pigs (Actinobacillus pleuropneumoniae), Glässer’s disease of pigs (Haemophilus parasuis) domestic fowl, Fowl cholera (Pasteurella multocida). Colibacillosis (Escherichia coli) The product should be used on the basis of the results of antibiotic susceptibility tests by taking into consideration the guidelines for the use of antimicrobial agents.',
    packaging:
      '80 g in a polyethylene box closed with a polyethylene lid having a warranty seal. 250 g, 1 kg in polyethylene box closed with a polyethylene screw cap.',
    withdrawal_period:
      'domestic fowl (broiler chicken) meat and offal: 4 days. Pig meat and offal: 4 days. The veterinary medicinal product is not authorized for the treatment of laying hens producing eggs intended for human consumption.',
    dosage:
      'Broiler chickens: 20 mg Phylamox 100% powder A.U.V./1 kilogram of body weight for the preparation of a solution for internal use for animal treatment over a period of 3–5 days. Calculated amount of product shall be added to drinking water of animals taken in within three hours. Before treatment animals shall be withdrawn from water. Pigs: 10–20 mg Phylamox 100% powder A.U.V./1kilogram of body weight for the preparation of a solution for internal use for animal treatment over a period of 3–5 days. Calculated amount of product shall be mixed into 5-10 litres water. This pre-dilution shall be added to drinking water of animals. Before treatment animals shall be withdrawn from water.',
  },
  {
    id: 48,
    name: 'Tetrakolin',
    type: 'chicken',
    image: '/images/chicken/Tetrakolin.jpg',
    composition:
      '/1 ml: Vitamin B2 5.00 mg, Niacinamide (3a315) 50.00 mg, Vitamin B6 (3a831) 8.00 mg, Vitamin B12 0.05 mg, d-panthenol (3a842) 86.00 mg, Biotin (3a880) 0.10 mg, Choline chloride (3a890) 21.75 mg',
    indications_for_use:
      ' A complex vitamin B product to facilitate utilisation of the young-age growth capacity of farm animals of high growth rate. In laying hens, it helps lengthen the egg production cycle and reduces the severity of diseases and toxicoses of dietary origin. By the regular use of the product the liver damage of intensively pro-ducing farm animals can be prevented. The product increases the resistance of the liver to hepato-toxic effects. Through its hepatoprotective effect, the product supports the prevention and treatment of ketosis in dairy cows.',
    packaging: '1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 49,
    name: 'Tetraselenium 400-E',
    type: 'chicken',
    image: '/images/chicken/Tetraselenium400-E.jpg',
    composition:
      '/1 ml: Vitamin E (3a700) 80 mg, Sodium selenite (E8) 400 µg. The product is indicated for supporting the antioxidant system of animals, for increasing their stress tolerance and for the prevention and treatment of reproductive disturbances associated with vitamin E deficiency. Selenium, which is a constituent of an enzyme playing a key role in the antioxidant system, and vitamin E, which has antioxidant properties, protect cells from the harmful effects of peroxides.',
    indications_for_use:
      ' Their deficiency primarily leads to degenerative changes of the myocardium, the skeletal muscles and the wall of blood vessels (skeletal muscle degeneration, encephalomalacia, exudative diathesis). Its use is recommended in the period of seualx maturation and fertilisation, and for the treatment of reproductive disturbances.',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 50,
    name: 'Tetravit ADE',
    type: 'chicken',
    image: '/images/chicken/TetravitADE.jpg',
    composition:
      '/1 ml: Nutritional additives – Vitamins and provitamins: Vitamin A (3a672b) 72.000 IU, Vitamin d3 (E671) 36.000 IU, Vitamin E (3a700) 72 mg. Owing to its high concentrations of vitamins A-, d3 – and E, the product strengthens the resistance of animals to infectious diseases. It supports normal feathering, the healthy development of bones and normal cardiac function. The product is excellently suitable for enhancing the general re-sistance of animals.',
    indications_for_use:
      ' In growing animals, it is recommended for use once or twice monthly as a treatment course. When administered to pregnant female animals in the last third of gestation, the product increases the general resistance of the newborn animals, improves the regeneration of postparturient female animals, supports reconception, and shortens the service period. When administered to laying flocks once or twice a month as a treatment course, the product increases eggshell strength and in breeder flocks it improves fertility and hatchability.',
    packaging: ' 1 l plastic bottle. Packaging: 1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 51,
    name: 'Vitamix B',
    type: 'chicken',
    image: '/images/chicken/VitamixB.jpg',
    composition:
      '/1 ml: Vitamin B1 (3a820) 4.00 mg, Vitamin B2 6.00 mg, Niacinamide (3a315) 30.00 mg, Vitamin B6 (3a831) 3.00 mg, Vitamin B12 0.015 mg, d-panthenol (3a842) 8.6 mg, Biotin (3a880) 0.03 mg, Folic acid (3a316) 0.5 mg',
    indications_for_use:
      'A complex vitamin B product which contains all essential group B vitamins. It increases the general resistance and stress tolerance of animals. It can be used favourably in cases of deficient vitamin synthesis following antibiotic therapy. By its use, vitamin B deficiency manifesting itself in decreased production and impaired appetite can be corrected.',
    packaging: ' 1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 52,
    name: 'Vitapol Antitox',
    type: 'chicken',
    image: '/images/chicken/VitapolAntitox.jpg',
    composition:
      'Humic and fulvic acids, yeast cell wall extract, modified clay components',
    indications_for_use:
      ' To neutralize the impact of mycotoxins without the drawbacks of conventional toxinbinders. VitaTox is unique on the market, a multi-component, wide spectrum feed additive, which provides an effective neutralisation of the damage caused by the mycotoxins. It eliminates toxic heavy metals from the body, detoxifyes and strengthens the immune system. It is in an easy to mix powder form, for mixing to feeds. It is suggested to mix to the feed before granulating. It can be mixed in one round with all medicals, vitamins and other feed supplements. Its active ingredients are not degraded by heat or pressure, and are stable.',
    packaging: ' in 25 kg paper bag',
    withdrawal_period: 'None',
    dosage:
      'depending on the toxin contamination, its dosage is from 2-4 kg/1000 kg of feed.',
  },
  {
    id: 53,
    name: 'Vitapol Antitox Pulv.',
    type: 'chicken',
    image: '/images/chicken/VitapolAntitoxPulv..jpg',
    composition:
      '1m558 modified bentonite, Yeast cell wall extract, Organic mineral with high humic acid content',
    indications_for_use:
      ' Functional group: substances for reduction of the contamination of feed by mycotoxins',
    packaging: ' 25 kg',
    withdrawal_period: 'None',
    dosage:
      'Starter feed: 2 kg/ton, Grower-finisher feed: 1 kg/ton, Feeds heavily contaminated with mycotoxins: 2-4 kg/ton, The simultaneous oral use with macrolides and with robenidine shall be avoided',
  },
  {
    id: 54,
    name: 'Vitapol Liquid',
    type: 'chicken',
    image: '/images/chicken/VitapolLiquid.jpg',
    composition: 'humic and fulvic acids',
    indications_for_use:
      'VITAPOL® Liquid is a natural mineral feed for livestock, which supports immunesystem and production of animals. It is easy to be used in drinking water dispensing systems. The main active ingredients are humic and fulvic acids derived from the natural humification process of plant materials. It contains minerals and microelements in humic acid-complex form.',
    packaging: '100 ml, 1 – 5 – 10 – 20 liter',
    withdrawal_period: 'None',
    dosage:
      '1-2 l per 1000 liters of drinking water, depending on the immune status of the animals.',
  },
  {
    id: 55,
    name: 'Vitapol Pulvis',
    type: 'chicken',
    image: '/images/chicken/VitapolPulvis.jpg',
    composition:
      'Contains natural materials only. Binds toxicants and chemical residues. Enhances stress endurance. Its use results in a faster weigth gain and in a healthier stock. Decreases mortality.',
    indications_for_use:
      'Vitapol® mineral feed exclusively contains natural raw materials, natural acids, minerals and micro elements. It contains minerals and micronutrients in an organic humic acid complex form, so they are easily absorbed in the digestive tract of animals, thus promoting the digestibility of feed and a better feed consumption. Humic acids as natural agents, are possible alternatives of antibiotics and chemotherapeutic preparations in many ways. Thanks to its unique composition Vitapol® has a positive effect on every species, it strengthens the immune system, and it helps the profitabilty of farming.',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      'The recommended amount is added mixed with feed. It can also be mixed into foods at home. It does not interact with other active ingredients, is sterilizable and stable. Sows: 0.5% continuously mixed from the choice into the sow diet. Newborn piglets: 0.5-1.0% mixed or sprinkled on the first solid feed.',
  },
  {
    id: 56,
    name: 'ALPHADERM Plus cutaneous spray solution for dogs',
    type: 'dog',
    image: '/images/dog/ALPHADERMPluscutaneousspraysolutionfordogs.jpg',
    composition:
      'Marboflxacin 1.025 mg/ml, Ketoconazole 2.041 mg/ml, Prednisolone 0.926 mg/ml',
    indications_for_use:
      'Treatment of acute dermatitis in dogs, when mixed infection caused by Pseudomonas aeruginosa or Staphylococcus pseudointermedius susceptible to marboflxacin and Malassezia pachydermatis susceptible to ketoconazole occures.',
    packaging:
      '30 ml and 100 ml PET bottle with a spray pump in cardboard box.',
    withdrawal_period: 'None',
    dosage:
      'Thorough treatment of the wound is inevitable. Trim the hair off the infected area, then clean and disinfect it. Spray the AlphaDerm Plus solution onto the infected area. Apply the spray from about 10 cm far,	pushing the dispenser twice, treating an area of 5 by 5 cm. Apply the spray twice a day for 7 to 14 days.',
  },
  {
    id: 57,
    name: 'BiogenicPet Balance',
    type: 'dog',
    image: '/images/dog/BiogenicPetBalance.jpg',
    composition:
      'Humic acids* 80.00 %, Moisture 20.00 %, Calcium 4.50 % , Magnesium (Mg) 0.80 %, Phosphorus (P) 0.10 %, Sodium (Na) 0.10 %, Iron (Fe) 16 000 mg/kg, Manganese Mn) 150 mg/kg. Natural humic acids play an important role as supplementation of the (alternative) veterinary medicine. They have such physiological effects which are prominently important in strengthening the immune system of the organism, detoxication and uptake of minerals. due to their natural origin it is important to be part of healthy feed of our animals.',
    indications_for_use:
      'The product is proposed first of all for prevention, but it is strongly recommended in digestive disorders as well (feed swith, antibiotic treatment, diseases, medical treatment, stress situations).',
    packaging: '250 g in plastic jar',
    withdrawal_period: 'None',
    dosage:
      'BiogenicPet Balance is recommended to be mixed into or poured on to the feed of the animal with the included measuring spoon (4.5 g). For dogs (per 20 kg s of bodyweight): For prevention 2 spoonful daily. In digestive disorders: 4 spoonful daily. For cats: For prevention 1 spoonful daily. In digestive disorders: 2 spoonful daily',
  },
  {
    id: 58,
    name: 'BiogenicPet Cod Liver Oil',
    type: 'dog',
    image: '/images/dog/BiogenicPetCodLiverOil.jpg',
    composition: 'EPA (Eicosapentaeonic acid) 654 mg',
    indications_for_use:
      'Feed supplement for dogs and cats, oil blend for healthy skin & coat; further maintain for heart and joints.',
    packaging: '100 ml',
    withdrawal_period: 'None',
    dosage:
      'EPA (Eicosapentaeonic acid) 654 mg. DHA (Docosahexaeonic acid) 6540 mg. Retinol (Vitamin A) 81500 IU. Cholecalciferol (Vitamin D3) 10840 IU. Tocoferol (Vitamin E) 800 IU',
  },
  {
    id: 59,
    name: 'BiogenicPet Digestion Dog & Cat',
    type: 'dog',
    image: '/images/dog/BiogenicPetDigestionDog&Cat.jpg',
    composition:
      'Humidity 83.00 %, Humic acids 4.00 %, Calcium (Ca) 0.85 %, Phosphor (P) 0.12 %, Crude ash 11.00 %, Magnesium (Mg) 1.40 %, Iron (Fe) 0.28 %, Sodium (Na) 0.04 %. Mineral feed additive for cats and dogs respectively',
    indications_for_use:
      'The active substances in BiogenicPET digestion paste are from humic acids and minerals. These substances were formed over 10 thousand year ago during the plants humification process. Humic acid molecules form complexes with minerals making their absorption more effective. Through the chemical structure and special absorption surface of the molecules bind and remove toxic waist from the intestine and control the intestinal flora. BiogenicPET digestive paste provides efficient complementary therapy for diarrhoeal diseases.',
    packaging: '15 ml',
    withdrawal_period: 'None',
    dosage:
      'In case of mild symptoms: 1 ml once daily for 10 kg bodyweight applied in the morning or at evenings till the symptoms subside, but for at least 3-4 days. Provide spareless quantity of drinking water during treatment. In case of diarrhoea, for supplementary treatment of stomachless, weakened cats: 0.2 ml once daily for 1 kg bodyweight applied in the morning or at evenings till the symptoms subside, but for at least 5-7 days. Provide spareless quantity of drinking water during treatment.',
  },
  {
    id: 60,
    name: 'BiogenicPet Immunity',
    type: 'dog',
    image: '/images/dog/BiogenicPetImmunity.jpg',
    composition:
      '/1 ml: BAP-8 (standardised pleuran extract) 10,0 mg, Excipients ad 1,0 ml. Syrup for oral use medicinal preparation ',
    indications_for_use:
      'Biogenic Pet Immunity syrup is an immune stimulator product for oral use. Its active ingredient is BAP-8 (standardised pleuran extract) which is a mixture of natural polysaccharides containing a complex of beta-d-glucans. It is isolated from oyster mushroom (Pleurotus ostreatus). BAP-8 is modifying cellular elements of the immune system, enhances resistance and vitality of animals. Alleviates course of disease in diseased animals. It is recommended in case of extreme physical stress and fatigue.',
    packaging: '120 ml',
    withdrawal_period: 'None',
    dosage:
      'Biogenic Pet Immunity syrup should be applied with the included dispenser or with a syringe into the feed or drinking water, or directly into the mouth of the animal. Shake well before use! For dogs: 2 ml/5 kg bodyweight (between 1–5 kg bodyweight use uniformly 2 ml) once daily. For cattle: 1 ml/10 kg bodyweight once daily. For improvement of resistance of the organism it is recommended to apply Biogenic Pet Immuno for a longer period – at least for 2-3 months. daily dose can be doubled in the beginning of treatment, at least for 3 days.',
  },
  {
    id: 61,
    name: 'BiogenicPet Liquid-Arthro',
    type: 'dog',
    image: '/images/dog/BiogenicPetLiquid-Arthro.jpg',
    composition:
      '100% pure Aloe vera juice, beef aroma, citric acid, purified water, potassium sorbate, sodium ',
    indications_for_use:
      'Joint and cartilage restorative liquid feed supplement for dogs',
    packaging: '300 ml',
    withdrawal_period: 'None',
    dosage:
      'Under 5 kg bodyweight 1 teaspoonfull (5 ml). 5 to 12 kg bodyweight 2 teaspoonfull (10 ml). 12 to 25 kg bodyweight 2 teaspoonfull (10 ml) in the morning, 1 teaspoonfull (5 ml) in the afternoon. 25 to 50 kg bodyweight 2 teaspoonfull (10 ml) in the morning, 2 teaspoonfull (10 ml) in the afternoon. Over 50 kg bodyweight 3 teaspoonfull (15 ml) in the morning, 3 teaspoonfull (15 ml) in the afternoon ',
  },
  {
    id: 62,
    name: 'BiogenicPet Mineral',
    type: 'dog',
    image: '/images/dog/BiogenicPetMineral.jpg',
    composition:
      '/1 ml: Molibdenum (Mo) 0,067 mg, Copper (Cu) 0,27 mg, Iron (Fe) 2,00 mg, Manganese (Mn) 0,67 mg, Magnesium (Mg) 16,7 mg, Iodine (J) 0,0167 mg, Selenium (Se) 0,017 mg, Zinc (Zn) 1,67 mg',
    indications_for_use:
      'BiogenicPet Mineral drops contains trace elements and minerals in the ideal concentration ratio for dogs and cats. The products can be used alone for prevention, or in combination with medical treatment. Mix into the feed or drinking water. – in case of metabolic disorders, anorexia and weakness: for improving hardiness – health deterioration of unknown origin – complementary therapy of medication during oncologic diseases – in infectious diseases for faster recovery, to support immune system – premedication before surgery, post-operative recovery – replacement of trace elements – improvement of hair quality in alopecia and pale/gray hair – protection of skin and mucous membrane, regeneration of skin, moulting – preparation for dog-shows – pregnancy, lactation – prevention of the above conditions, complementary therapy of medications',
    packaging: '30 ml',
    withdrawal_period: 'None',
    dosage:
      'For dogs: 1 drop/kg bodyweight per day, mixed into a quantity of water or food which is surely taken by the animal. For prevention and roborating BiogenicPet Mineral should be used permanently for 2 months. It is recommended to repeat treatment after 1 month drug pause. In complementary therapy (e.g. in infectious diseases and malignances) it should be used according to the veterinarian’s instruction. For cats: 1 drop/kg bodyweight per day, mixed into a quantity of water or food which is surely taken by the animal. For prevention and roborating BiogenicPet Mineral should be used permanently for 2 months. It is recommended to repeat treatment after 1 month drug pause. In complementary therapy (e.g. in infectious diseases and malignances) it should be used according to the veterinarian’s instruction.',
  },
  {
    id: 63,
    name: 'BiogenicPet Mobility',
    type: 'dog',
    image: '/images/dog/BiogenicPetMobility.jpg',
    composition:
      'Salto-3® 300 mg, Methyl-sulphonyl-methan 100 mg Hyaluronic-acid 50 mg Seefood Powder Extract 70 mg, Calcium-hydrogen-phoshate 50 mg, Ham and liver aroma 900 mg. Feed supplement tablets for dogs',
    indications_for_use:
      'BiogenicPet Mobility tablet contains every important element necessary for building and regeneration of cartilage tissue in optimal combination. For cartilage function it is essential to renew the macromolecules which build up its structure. d-glucosamine and chondroitin supplementation is inevitable, because they are elements of proteoglicane chains, which provide flexibility of hyaline cartilage',
    packaging: '5 Kg and 25 Kg sack',
    withdrawal_period: 'None',
    dosage:
      'For small dogs (under 15 kg): 1 tablet/day, For middle-size dogs (15-40 kg): 2 tablets/day, For big dogs (above 40 kg): 3 tablets/day, Administration must last for at least 4 weeks, because the positive effect is built up step by step.',
  },
  {
    id: 64,
    name: 'BiogenicPet Vitality Small & Large',
    type: 'dog',
    image: '/images/dog/BiogenicPetVitalitySmall&Large.jpg',
    composition:
      'Brewer’s yeast, Maltodextrin, Humanofort Small 25 mg / Large 50 mg Selenium (3b8.10) Small 6 µg / Large 8 µg Vitamin E (3a700) Small 4,5 mg / Large 9 mg, Protein Small 25,3% / Large 25,5%, Crude fiber 0,5%, Fat content 0,2%, inorganic matter 0,5%. Feed supplement tablets for dogs',
    indications_for_use:
      'BiogenicPet Vitality tablet contains three active ingredients: Humanofort®, Selenium and Vitamin-E. Humanofort contains pharmaceutical grade extract of growth factors from natural source. These growth factors are low molecular weight proteins (oligopeptides) isolated from eggs. Humanofort has clinically proven proliferative activity for different cell types involved in tissue regeneration in the body. Humanofort has been widely used as a human food supplement as well. Selenium and Vitamin-E has common effect. Selenium is a cofactor of glutation-peroxidase enzyme which – in cooperation with Vitamin E and with other antioxidants – is able to decrease impairing effect of free radicals in the body. Experience shows that administration of Selenium and Vitamin E is beneficial in degeneration of heart and skeletal muscle and in reproductive disorders as well.',
    packaging: '60 tablets',
    withdrawal_period: 'None',
    dosage:
      'Administer BiogenicPet Vitality via oral route twice daily every day. For smaller dogs (under 25 kg bodyweight) give 2×1 tablet of BiogenicPet Vitality SMALL. For larger dogs (over 25 kg bodyweight) give 2×1 tablet of BiogenicPet Vitality LARGE. Use BiogenicPet Vitality for at least one month. In case of permanent disorders you may continue BiogenicPet Vitality feeding for longer period.',
  },
  {
    id: 65,
    name: 'Caphos Pro',
    type: 'dog',
    image: '/images/dog/CaphosPro.jpg',
    composition:
      'Monocalcium phosphate, magnesium phosphate, magnesium chloride',
    indications_for_use:
      'CaPhos Pro is a liquid feed additive serving for the Ca and P supplementation of farm animals as well as for the prevention and treatment of problems caused by locomotor diseases and bone formation disturbances. It is recommended for use as a treatment course in periods of intensive growth, bone development and production. In pigs and in intensively reared poultry flocks its use is recommended in both the starter and the grower phase. In cattle and small ruminants the use of CaPhos Pro is needed in mineral deficiency conditions and when the balance of the calcium–phosphorus metabolism is disturbed. Such conditions include e.g. milk fever, various limb problems and lameness. The product has beneficial effects also in animals with reproductive disturbances (reduced fertility, disturbances of spermatogenesis) and in those with diseases of the skin and hair coat.In pigs, CaPhos Pro is recommended for the prevention and treatment of peripartal problems such as difficult farrowing and MMA syndrome, for supporting the maturation of gametes (ova and spermatozoa) and for the prevention and treatment of various limb problems and lameness.In laying hens, the product is recommended for use mainly at the peak of egg production and when eggshell formation and other production disturbances occur. It has beneficial effects in the prevention of bone development disorders and joint problems, and it also improves fertility. It prevents heat stress under conditions of high ambient temperature. In turkey flocks, CaPhos Pro has been found to be effective in preventing aortic rupture due to copper deficiency.',
    packaging: '1, 20, 5, l',
    withdrawal_period: 'None',
    dosage:
      'The product is administered dissolved in the drinking water, over a period of 5–10 days.',
  },
  {
    id: 66,
    name: 'Illovit E-SE',
    type: 'dog',
    image: '/images/dog/IllovitE-SE.jpg',
    composition: 'Vitamin-E. selenium',
    indications_for_use:
      'To support the body’s antioxidant system and to prevent diseases, to prevent and treat reproductive disorders associated with vitamin E deficiency. In animals grazed in selenium-deficient areas, it prevents the development of deficiency symptoms and increases the viability of newborn animals. The synergic effects of the added plant extracts boost the immune system, and fix the efficency of physiological processes. Its biological effect is well established even during viral infections and vaccinations. Vascular protective, detoxifying processes are initiated, which accelerate the regeneration mechanisms.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'Dissolved in drinking water. Do not use without dilution General dose: 0.5 ml / liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days.It is recommended to have the daily amount be drank in 4-6 hours.',
  },
  {
    id: 67,
    name: 'Illovit Hepar',
    type: 'dog',
    image: '/images/dog/IllovitHepar.jpg',
    composition:
      'Vitamin-B1, Vitamin-B6, Niacinamide, Choline Chloride, Inositol, L-Carnitine, Amino Acids, salts and analogs thereof: Lysine, DL-Methionine',
    indications_for_use:
      'It is recommended in all cases when the liver is overloaded. In particular: gastrointestinal diseases, feeding defects, suspicion of poisoning, viral infections, in the presence of feed toxins. To help liver function in herds with intensive growth vigor. It is recommended to use it after reaching peak production. The composition increases the detoxification capacity of the liver by administering methyl donors such as methionine or choline chloride. Following the use of antibiotics, the ability of the intestinal flora to synthesize vitamins is significantly reduced, so it is vital to administer B vitamins after treatment. Illovit Hepar helps herd regenerate and maximize production during and after treatment. Due to its composition, it maximizes the growth capacity of young animals. The benefits of the added plant extracts are based on traditional folk use, which has been confirmed by animal health impact studies. The extracts accelerate the body’s natural regeneration processes through their liver-supporting synergistic effect. They strengthen blood vessel walls, improve blood circulation. Gently activates and aids digestive and respiratory functions in animals with reduced production capacity.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      '0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg of body weight for 3-7 days. It is recommended to have the daily amount be drank in 4-6 hours',
  },
  {
    id: 68,
    name: 'Illovit Multivitamin',
    type: 'dog',
    image: '/images/dog/IllovitMultivitamin.jpg',
    composition:
      'Vitamin-A, Vitamin-NED3, Vitamin-NEE, Vitamin-K3, Vitamin-B1, Vitamin-B2, Vitamin-B6, Vitamin-B12, Niacinamide, Choline Chloride, Pantothenic acid, Biotin',
    indications_for_use:
      'Combined multivitamin preparation. To maintain and increase growth vigor in the event of colds and weather front effects. It is suitable for vitamin supplementation of pets of all species and ages, for overcoming stress and deficiency diseases. Accelerates post-disease regeneration. The synergistic beneficial effect of the added plant extracts specifically protects and supports the respiratory system. It cleans the airways, helps to remove stuck mucus. It helps in post-disease regeneration, improves feed utilization, and aids digestion efficiency. It supports the circulatory system and the heart.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'General dose: 0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days. It is recommended to have the daily amoun be drank in 4-6 hours.',
  },
  {
    id: 69,
    name: 'Jolovit',
    type: 'dog',
    image: '/images/dog/Jolovit.jpg',
    composition:
      'Vitamin A 14.000 I.U., Vitamin d3 1.400 I.U., Vitamin E 10.5 mg, Vitamin K3 0.175 mg, Vitamin B1 1.4 mg, Vitamin B2 2.1 mg, Vitamin B6 1.4 mg, VitaminB12 0.014 mg, d-Panthenol 6.55 mg, Niacinamide 14.0 mg, Biotin 0.035 mg, Colin chloride 140 mg, B-carotene 0,01 mg',
    indications_for_use:
      'A combined multivitamin product to be used for vitamin supplementation in farm animals of any age and species, in order to correct vitamin deficiency caused by stress occurring at the time of placement and feed change. Owing to its composition, Jolovit is suitable for ensuring optimum vitamin supply, which is a precondition of adequate production performance. It reduces the negative effects of stress, improves the viability of young animals and the hatchability of eggs, and accelerates recovery after diseases.',
    packaging: ' 0.1 l bottle, 1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'see label for the details.',
  },
  {
    id: 70,
    name: 'Marbogen Complex ear drops for dogs A.U.V.',
    type: 'dog',
    image: '/images/dog/MarbogenComplexeardropsfordogsA.U.V..jpg',
    composition:
      '/1 ml: Marbofloxacin 2.041 mg, Gentamicin-sulfate 2.044 mg, Ketoconazole 4.081 mg, Prednisolone 1.850 mg',
    indications_for_use:
      'Acute otitis externa caused by Staphylococcus pseudointermedius, Pseudomonas aeruginosa and Malessezia pachydermatis sensitive to ketoconazole.',
    packaging: '10 ml in plastic drop-bottl',
    withdrawal_period: 'None',
    dosage:
      'Administer 5 drops (0,1 ml) into the ear canal 2 times per day for 14 consecutive days.',
  },
  {
    id: 71,
    name: 'Caphos Pro',
    type: 'goat',
    image: '/images/goat/CaphosPro.jpg',
    composition:
      'Monocalcium phosphate, magnesium phosphate, magnesium chloride',
    indications_for_use:
      'CaPhos Pro is a liquid feed additive serving for the Ca and P supplementation of farm animals as well as for the prevention and treatment of problems caused by locomotor diseases and bone formation disturbances. It is recommended for use as a treatment course in periods of intensive growth, bone development and production. In pigs and in intensively reared poultry flocks its use is recommended in both the starter and the grower phase. In cattle and small ruminants the use of CaPhos Pro is needed in mineral deficiency conditions and when the balance of the calcium–phosphorus metabolism is disturbed. Such conditions include e.g. milk fever, various limb problems and lameness. The product has beneficial effects also in animals with reproductive disturbances (reduced fertility, disturbances of spermatogenesis) and in those with diseases of the skin and hair coat.In pigs, CaPhos Pro is recommended for the prevention and treatment of peripartal problems such as difficult farrowing and MMA syndrome, for supporting the maturation of gametes (ova and spermatozoa) and for the prevention and treatment of various limb problems and lameness.In laying hens, the product is recommended for use mainly at the peak of egg production and when eggshell formation and other production disturbances occur. It has beneficial effects in the prevention of bone development disorders and joint problems, and it also improves fertility. It prevents heat stress under conditions of high ambient temperature. In turkey flocks, CaPhos Pro has been found to be effective in preventing aortic rupture due to copper deficiency.',
    packaging: '1, 20, 5, l',
    withdrawal_period: 'None',
    dosage:
      'The product is administered dissolved in the drinking water, over a period of 5–10 days.',
  },
  {
    id: 72,
    name: 'Illovit E-SE',
    type: 'goat',
    image: '/images/goat/IllovitE-SE.jpg',
    composition: 'Vitamin-E. selenium',
    indications_for_use:
      'To support the body’s antioxidant system and to prevent diseases, to prevent and treat reproductive disorders associated with vitamin E deficiency. In animals grazed in selenium-deficient areas, it prevents the development of deficiency symptoms and increases the viability of newborn animals. The synergic effects of the added plant extracts boost the immune system, and fix the efficency of physiological processes. Its biological effect is well established even during viral infections and vaccinations. Vascular protective, detoxifying processes are initiated, which accelerate the regeneration mechanisms.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'Dissolved in drinking water. Do not use without dilution General dose: 0.5 ml / liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days.It is recommended to have the daily amount be drank in 4-6 hours.',
  },
  {
    id: 73,
    name: 'Illovit Hepar',
    type: 'goat',
    image: '/images/goat/IllovitHepar.jpg',
    composition:
      'Vitamin-B1, Vitamin-B6, Niacinamide, Choline Chloride, Inositol, L-Carnitine, Amino Acids, salts and analogs thereof: Lysine, DL-Methionine',
    indications_for_use:
      'It is recommended in all cases when the liver is overloaded. In particular: gastrointestinal diseases, feeding defects, suspicion of poisoning, viral infections, in the presence of feed toxins. To help liver function in herds with intensive growth vigor. It is recommended to use it after reaching peak production. The composition increases the detoxification capacity of the liver by administering methyl donors such as methionine or choline chloride. Following the use of antibiotics, the ability of the intestinal flora to synthesize vitamins is significantly reduced, so it is vital to administer B vitamins after treatment. Illovit Hepar helps herd regenerate and maximize production during and after treatment. Due to its composition, it maximizes the growth capacity of young animals. The benefits of the added plant extracts are based on traditional folk use, which has been confirmed by animal health impact studies. The extracts accelerate the body’s natural regeneration processes through their liver-supporting synergistic effect. They strengthen blood vessel walls, improve blood circulation. Gently activates and aids digestive and respiratory functions in animals with reduced production capacity.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      '0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg of body weight for 3-7 days. It is recommended to have the daily amount be drank in 4-6 hours',
  },
  {
    id: 74,
    name: 'Illovit Multivitamin',
    type: 'goat',
    image: '/images/goat/IllovitMultivitamin.jpg',
    composition:
      'Vitamin-A, Vitamin-NED3, Vitamin-NEE, Vitamin-K3, Vitamin-B1, Vitamin-B2, Vitamin-B6, Vitamin-B12, Niacinamide, Choline Chloride, Pantothenic acid, Biotin',
    indications_for_use:
      'Combined multivitamin preparation. To maintain and increase growth vigor in the event of colds and weather front effects. It is suitable for vitamin supplementation of pets of all species and ages, for overcoming stress and deficiency diseases. Accelerates post-disease regeneration. The synergistic beneficial effect of the added plant extracts specifically protects and supports the respiratory system. It cleans the airways, helps to remove stuck mucus. It helps in post-disease regeneration, improves feed utilization, and aids digestion efficiency. It supports the circulatory system and the heart.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'General dose: 0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days. It is recommended to have the daily amoun be drank in 4-6 hours.',
  },
  {
    id: 75,
    name: 'Jolovit',
    type: 'goat',
    image: '/images/goat/Jolovit.jpg',
    composition:
      'Vitamin A 14.000 I.U., Vitamin d3 1.400 I.U., Vitamin E 10.5 mg, Vitamin K3 0.175 mg, Vitamin B1 1.4 mg, Vitamin B2 2.1 mg, Vitamin B6 1.4 mg, VitaminB12 0.014 mg, d-Panthenol 6.55 mg, Niacinamide 14.0 mg, Biotin 0.035 mg, Colin chloride 140 mg, B-carotene 0,01 mg',
    indications_for_use:
      'A combined multivitamin product to be used for vitamin supplementation in farm animals of any age and species, in order to correct vitamin deficiency caused by stress occurring at the time of placement and feed change. Owing to its composition, Jolovit is suitable for ensuring optimum vitamin supply, which is a precondition of adequate production performance. It reduces the negative effects of stress, improves the viability of young animals and the hatchability of eggs, and accelerates recovery after diseases.',
    packaging: '0.1 l bottle, 1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'see label for the details.',
  },
  {
    id: 76,
    name: 'Neo-Atympan',
    type: 'goat',
    image: '/images/goat/Neo-Atympan.png',
    composition:
      'Sunflower oil, antifoaming agent 18.5ml, water ad 100ml. Additives: citric acid E330',
    indications_for_use:
      'Neo-Atympan is an aid in the treatment of acute bloating in ru-minants. It is effective in the decreasing foaming, which is based on the destroying the foam by influencing the surface tension. It causes the merging of the gas bubbles in rumen which then allows easier passing of gas in a few minutes. If the release of the gases is obstructed or unsatisfactory, other instruments (stomach tube, trocar) may be required.',
    packaging: '100 ml',
    withdrawal_period: 'None',
    dosage:
      'It can be applied by long-necked bottle or by tube intraruminally. Shake well before use! cattle: 100 ml. calf: 50 ml. sheep, goats: 20-25 ml',
  },
  {
    id: 77,
    name: 'Norovit Amino Forte',
    type: 'goat',
    image: '/images/goat/NorovitAminoForte.jpg',
    composition:
      '/ ml: Vitamin A (3a672b) 40,000, IU Vitamin D3 (E671) 5,000, IU Vitamin E (3a700) 20.0 mg, Vitamin K3 (3a710) 2.0 mg, Vitamin B1 (3a820) 2.0 mg, Vitamin B2 0.2 mg, Vitamin B6 (3a831) 2.0 mg, Niacinamide (3a315) 14.0 mg, Vitamin C (E301) 5.0 mg, DL-methionine 8.2 mg, Lysine HCl 11.0 mg, Tryptophan 1.8 mg, Threonine 5.0 mg',
    indications_for_use:
      'A combination of essential amino acids and vitamins. It enhances muscle building and weight gain. Its use is recommended after placement to achieve higher constitutional strength and to pre-vent the adverse effects of stress due to movement and vaccination of the animals.',
    packaging: '1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 78,
    name: 'Norovit Hepar',
    type: 'goat',
    image: '/images/goat/NorovitHepar.jpg',
    composition:
      '/ ml: Vitamin B1 (3a820) 5.0 mg, Vitamin B2 4.0 mg, Vitamin B6 (3a831) 4.0 mg, D-panthenol (3a842) 20.0 mg, Biotin (3a880) 0.015 mg, Vitamin K3 (3a710) 2.0 mg, Niacinamid (3a315) 40.0 mg, Inositol 5.0 mg, Choline chloride (3a890) 200.0 mg, Lysine HCl 20.0 mg, DL-methionine 10.0 mg. Like choline, inositol has a ‘fat burning’ effect, it prevents the deposition of fats in the liver and accelerates their excretion. Vitamins of the B group provide energy for the body by supporting the metabolism of carbohydrates, proteins and fats.',
    indications_for_use:
      'To support liver function, especially in flocks and herds of intensive growth rate. The product enhances the detoxifying capacity of the liver by introducing into the body methyl donors such as methionine or choline chloride.',
    packaging: '1 liter and 5 liter plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 79,
    name: 'Norovit Thermo',
    type: 'goat',
    image: '/images/goat/NorovitThermo.jpg',
    composition:
      '/ ml: L-carnitine (3a910) 30.0 mg, Vitamin E (3a700) 40.0 mg, Vitamin B1 (3a820) 5.0 mg, Niacinamide (3a315) 10.0 mg, Vitamin B6 (3a831) 4.0 mg, Vitamin B12 0.02 mg, DL-methionine 10.0 mg, Lysine HCl 10.0 mg, Sodium selenite (E8) 0.40 mg, Sorbitol 160.0 mg',
    indications_for_use:
      'A product suitable for preventing the adverse effects of heat stress. Its constituents support the thermoregulation of animals. Heat stress damages the antioxidant system, and an elevated vitamin E and selenium level is needed to counter this effect. Heat shock results in a higher water and water-soluble vitamin loss for the organism, and there-fore the supplementation of vitamins of group B is absolutely necessary. In addition, vitamins B support the animals’ metabolism. Niacinamide participates in almost all enzymatic processes as a transmitter, as a signalling compound or as an energy-supplying compound.',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 80,
    name: 'Ruminogen pulvis',
    type: 'goat',
    image: '/images/goat/Ruminogenpulvis.jpg',
    composition: 'K-propionate',
    indications_for_use:
      'Treatment for cattle, sheep and goat primarily alimentary based, secondary  for acute and intensive craw torpidity cases related to organ diseases, delivery, post operational condition, etc. rumen  dysfunction. Curing acute bloating in calves and therapy for ketosis in cows.  It will restore the normal biological balance in the rumen, it will bring optimal activity in the ﬂora and fauna liquid of the rumen. It uses the fumaric-acid and propionic acid absorbed from it for the glycogen synthesis of the liver, to prevent liver damage.',
    packaging: '100 g, 1 kg, 5 kg',
    withdrawal_period: 'None',
    dosage: '100 g/animal/day in case of rumen problems.',
  },
  {
    id: 81,
    name: 'Ruminostart drench',
    type: 'goat',
    image: '/images/goat/Ruminostartdrench.jpg',
    composition:
      'Amino acids, Ca-proprionate, Glucose. Ruminostart Drench is a special feed supplement developed for freshly calved cows.',
    indications_for_use:
      'Use immediately after calving, and during the production for mitigation of energy metabolism disorders and for prevention and adjunctive treatment of various metabolic disorders.',
    packaging: '700 g',
    withdrawal_period: 'None',
    dosage:
      '700 g/cow in twenty liter of warm water after calving or in case of rumen problems.',
  },
  {
    id: 82,
    name: 'Tetrakolin',
    type: 'goat',
    image: '/images/goat/Tetrakolin.jpg',
    composition:
      '/1 ml: Vitamin B2 5.00 mg, Niacinamide (3a315) 50.00 mg, Vitamin B6 (3a831) 8.00 mg, Vitamin B12 0.05 mg, d-panthenol (3a842) 86.00 mg, Biotin (3a880) 0.10 mg, Choline chloride (3a890) 21.75 mg',
    indications_for_use:
      'A complex vitamin B product to facilitate utilisation of the young-age growth capacity of farm animals of high growth rate. In laying hens, it helps lengthen the egg production cycle and reduces the severity of diseases and toxicoses of dietary origin. By the regular use of the product the liver damage of intensively pro-ducing farm animals can be prevented. The product increases the resistance of the liver to hepato-toxic effects. Through its hepatoprotective effect, the product supports the prevention and treatment of ketosis in dairy cows.',
    packaging: '1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 83,
    name: 'Tetraselenium 400-E',
    type: 'goat',
    image: '/images/goat/Tetraselenium400-E.jpg',
    composition:
      '/1 ml: Vitamin E (3a700) 80 mg, Sodium selenite (E8) 400 µg. The product is indicated for supporting the antioxidant system of animals, for increasing their stress tolerance and for the prevention and treatment of reproductive disturbances associated with vitamin E deficiency. Selenium, which is a constituent of an enzyme playing a key role in the antioxidant system, and vitamin E, which has antioxidant properties, protect cells from the harmful effects of peroxides.',
    indications_for_use:
      'Their deficiency primarily leads to degenerative changes of the myocardium, the skeletal muscles and the wall of blood vessels (skeletal muscle degeneration, encephalomalacia, exudative diathesis). Its use is recommended in the period of seualx maturation and fertilisation, and for the treatment of reproductive disturbances.',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 84,
    name: 'Tetravit ADE',
    type: 'goat',
    image: '/images/goat/TetravitADE.jpg',
    composition:
      '/1 ml: Nutritional additives – Vitamins and provitamins: Vitamin A (3a672b) 72.000 IU, Vitamin d3 (E671) 36.000 IU, Vitamin E (3a700) 72 mg. Owing to its high concentrations of vitamins A-, d3 – and E, the product strengthens the resistance of animals to infectious diseases. It supports normal feathering, the healthy development of bones and normal cardiac function. The product is excellently suitable for enhancing the general re-sistance of animals.',
    indications_for_use:
      'In growing animals, it is recommended for use once or twice monthly as a treatment course. When administered to pregnant female animals in the last third of gestation, the product increases the general resistance of the newborn animals, improves the regeneration of postparturient female animals, supports reconception, and shortens the service period. When administered to laying flocks once or twice a month as a treatment course, the product increases eggshell strength and in breeder flocks it improves fertility and hatchability.',
    packaging: '1 l plastic bottle. Packaging: 1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 85,
    name: 'Vitamix B',
    type: 'goat',
    image: '/images/goat/VitamixB.jpg',
    composition:
      '/1 ml: Nutritional additives – Vitamins and provitamins: Vitamin A (3a672b) 72.000 IU, Vitamin d3 (E671) 36.000 IU, Vitamin E (3a700) 72 mg. Owing to its high concentrations of vitamins A-, d3 – and E, the product strengthens the resistance of animals to infectious diseases. It supports normal feathering, the healthy development of bones and normal cardiac function. The product is excellently suitable for enhancing the general re-sistance of animals.',
    indications_for_use:
      'A complex vitamin B product which contains all essential group B vitamins. It increases the general resistance and stress tolerance of animals. It can be used favourably in cases of deficient vitamin synthesis following antibiotic therapy. By its use, vitamin B deficiency manifesting itself in decreased production and impaired appetite can be corrected.',
    packaging: '1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 86,
    name: 'Vitapol Antimast',
    type: 'goat',
    image: '/images/goat/VitapolAntimast.jpg',
    composition:
      'Helps the regeneration of udder tissues and to fight off bacteria, thus reducing milk somatic cell count. In addition, Vitapol AntiMast can also prevent and stop diarrhea on short term and reduce lameness, laminitis, Mortellaro-disease (digital dermatitis) on the long run.',
    indications_for_use:
      'Helps the regeneration of udder tissues and to fight off bacteria, thus reducing milk somatic cell count. In addition, Vitapol AntiMast can also prevent and stop diarrhea on short term and reduce lameness, laminitis, Mortellaro-disease (digital dermatitis) on the long run.',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      '150 g/cow/day for 14 days. On the 15th day sampling and controlling the somatic cell count in the milk. For more details see the label.',
  },
  {
    id: 87,
    name: 'Vitapol Antitox',
    type: 'goat',
    image: '/images/goat/VitapolAntitox.jpg',
    composition:
      'Humic and fulvic acids, yeast cell wall extract, modified clay components',
    indications_for_use:
      'To neutralize the impact of mycotoxins without the drawbacks of conventional toxinbinders. VitaTox is unique on the market, a multi-component, wide spectrum feed additive, which provides an effective neutralisation of the damage caused by the mycotoxins. It eliminates toxic heavy metals from the body, detoxifyes and strengthens the immune system. It is in an easy to mix powder form, for mixing to feeds. It is suggested to mix to the feed before granulating. It can be mixed in one round with all medicals, vitamins and other feed supplements. Its active ingredients are not degraded by heat or pressure, and are stable.',
    packaging: 'in 25 kg paper bag',
    withdrawal_period: 'None',
    dosage:
      'depending on the toxin contamination, its dosage is from 2-4 kg/1000 kg of feed.',
  },
  {
    id: 88,
    name: 'Vitapol Pulvis',
    type: 'goat',
    image: '/images/goat/VitapolPulvis.jpg',
    composition:
      'Contains natural materials only. Binds toxicants and chemical residues. Enhances stress endurance. Its use results in a faster weigth gain and in a healthier stock. Decreases mortality.',
    indications_for_use:
      'Vitapol® mineral feed exclusively contains natural raw materials, natural acids, minerals and micro elements. It contains minerals and micronutrients in an organic humic acid complex form, so they are easily absorbed in the digestive tract of animals, thus promoting the digestibility of feed and a better feed consumption. Humic acids as natural agents, are possible alternatives of antibiotics and chemotherapeutic preparations in many ways. Thanks to its unique composition Vitapol® has a positive effect on every species, it strengthens the immune system, and it helps the profitabilty of farming.',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      'The recommended amount is added mixed with feed. It can also be mixed into foods at home. It does not interact with other active ingredients, is sterilizable and stable. Sows: 0.5% continuously mixed from the choice into the sow diet. Newborn piglets: 0.5-1.0% mixed or sprinkled on the first solid feed.',
  },
  {
    id: 89,
    name: 'Vitapol suspension',
    type: 'goat',
    image: '/images/goat/Vitapolsuspension.jpg',
    composition:
      'Moisture 85% Humic acids 80 %, Sodium (Na) 0.02 %, Crude ash 4 %, Calcium (Ca) 1.1 % Magnesium (Mg) 0,1 %, Phosphorus (P) 0.02 % Iron (Fe) 600 mg/kg, Manganese (Mn) 18 mg/kg, Zinc (Zn) 8.0 mg/kg, Molybdenum (Mo) 0.6 mg/kg, Cobalt (Co) 0.4 mg/kg',
    indications_for_use:
      'It can be mixed into the milk or milk replacer of calves or poured on the feed. For piglets serve on a tray on the firs week. Strengthens the immune system and prevents diarrhea.',
    packaging: 'in 20 kg plastic bucke',
    withdrawal_period: 'None',
    dosage:
      'Cattle Calf: 1 dl/day/calf mixed into the milk replacer, In case of severe diarrhoea 2 dl/day/calf. Adult cattle: 2 dl/animal/day. Swine Newborn piglets: 1 dl/litter/day. Sows: 1 dl/day/animal during the late pregnancy and lactation.',
  },
  {
    id: 90,
    name: 'Caphos Pro',
    type: 'horse',
    image: '/images/horse/CaphosPro.jpg',
    composition:
      'Monocalcium phosphate, magnesium phosphate, magnesium chloride',
    indications_for_use:
      'for use: CaPhos Pro is a liquid feed additive serving for the Ca and P supplementation of farm animals as well as for the prevention and treatment of problems caused by locomotor diseases and bone formation disturbances. It is recommended for use as a treatment course in periods of intensive growth, bone development and production. In pigs and in intensively reared poultry flocks its use is recommended in both the starter and the grower phase. In cattle and small ruminants the use of CaPhos Pro is needed in mineral deficiency conditions and when the balance of the calcium–phosphorus metabolism is disturbed. Such conditions include e.g. milk fever, various limb problems and lameness. The product has beneficial effects also in animals with reproductive disturbances (reduced fertility, disturbances of spermatogenesis) and in those with diseases of the skin and hair coat.In pigs, CaPhos Pro is recommended for the prevention and treatment of peripartal problems such as difficult farrowing and MMA syndrome, for supporting the maturation of gametes (ova and spermatozoa) and for the prevention and treatment of various limb problems and lameness.In laying hens, the product is recommended for use mainly at the peak of egg production and when eggshell formation and other production disturbances occur. It has beneficial effects in the prevention of bone development disorders and joint problems, and it also improves fertility. It prevents heat stress under conditions of high ambient temperature. In turkey flocks, CaPhos Pro has been found to be effective in preventing aortic rupture due to copper deficiency.',
    packaging: '1, 20, 5, l',
    withdrawal_period: 'None',
    dosage:
      'The product is administered dissolved in the drinking water, over a period of 5–10 days.',
  },
  {
    id: 91,
    name: 'Vitahorse Pulvis COMPLEMENTARY FEED FOR HORSES',
    type: 'horse',
    image: '/images/horse/VitahorsePulvis.jpg',
    composition:
      'Organic mineral with high humic acid content, extruded linseed meal, dried apple pulp. Humic acids 16%, Crude protein 19,5%, Crude fibre 8,3%, Crude oils and fats 25%, Inorganic matter 5,2%, Sodium 0,03%, Methionine 0,35%',
    indications_for_use:
      'Humic acids are molecules of natural origin which have complex physiological effects by stimulating the immune system and enhancing uptake of minerals, promoting detoxification functions of organism, having anti-viral effect. Linseed contains polyunsaturated fatty acids which play an important role in the physiological structure and function of the central nervous system, influencing among others the operation of sex hormones and the immune system. The product can improve health condition, performance and fertility of horses.',
    packaging: 'in 14 kg plastic bucket',
    withdrawal_period: 'None',
    dosage:
      'Breeding animals: mare: 200 g / animal / day stallion: 200 g / animal / day Race Horses (dep ending o n activity): 220-250 g / animal / day Hobby Horses: 80-100 g / animal / day Foals: 30-50 g / animal / day',
  },
  {
    id: 92,
    name: 'Vitahorse Suspension',
    type: 'horse',
    image: '/images/horse/VitahorseSuspension.jpg',
    composition:
      'Moisture 85% Humic acids 80 % Sodium (Na) 0.02 %, Crude ash 4 %, Calcium (Ca) 1.1% Magnesium (Mg) 0.1 %, Phosphorus (P) 0.02 %, Iron (Fe) 600 mg/kg, Manganese (Mn) 18 mg/kg, Zinc (Zn) 8 mg/kg, Molybdenum (Mo) 0.6 mg/kg, Cobalt (Co) 0.4 mg/kg',
    indications_for_use:
      'improved vitality, excellent race performance, well-balanced and stabile neuro-system, reduced risk of injuries. Vitahorse® Suspension contains exclsively natural raw materials, organic acids, minerals and micronutritives. the minerals and micronutrients are in an organic humat-complex form, so the bioavailability in the animal’s digestive tract are excellent.',
    packaging: 'in 20 kg plastic bucket',
    withdrawal_period: 'None',
    dosage:
      'within 3-4 weeks before foaling, to have healthier foals born, and to improve the quality of milk. The daily intake is 1-2 dl/mare, add VITAHORSE suspension to the fodder. Foals: 1 dl/foal/day, mix it into the fodder. race horses: starting 1 month before racing season, 1-2 dl/horse/day, add into the fodder.',
  },
  {
    id: 93,
    name: 'Vitapol Antitox Pulv.',
    type: 'horse',
    image: '/images/horse/VitapolAntitoxPulv..jpg',
    composition:
      '1m558 modified bentonite, Yeast cell wall extract, Organic mineral with high humic acid content',
    indications_for_use:
      'Functional group: substances for reduction of the contamination of feed by mycotoxins',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      'Starter feed: 2 kg/ton, Grower-finisher feed: 1 kg/ton, Feeds heavily contaminated with mycotoxins: 2-4 kg/ton, The simultaneous oral use with macrolides and with robenidine shall be avoided',
  },
  {
    id: 94,
    name: 'Caphos Pro',
    type: 'rabbit',
    image: '/images/rabbit/CaphosPro.jpg',
    composition:
      'Monocalcium phosphate, magnesium phosphate, magnesium chloride',
    indications_for_use:
      'CaPhos Pro is a liquid feed additive serving for the Ca and P supplementation of farm animals as well as for the prevention and treatment of problems caused by locomotor diseases and bone formation disturbances. It is recommended for use as a treatment course in periods of intensive growth, bone development and production. In pigs and in intensively reared poultry flocks its use is recommended in both the starter and the grower phase. In cattle and small ruminants the use of CaPhos Pro is needed in mineral deficiency conditions and when the balance of the calcium–phosphorus metabolism is disturbed. Such conditions include e.g. milk fever, various limb problems and lameness. The product has beneficial effects also in animals with reproductive disturbances (reduced fertility, disturbances of spermatogenesis) and in those with diseases of the skin and hair coat.In pigs, CaPhos Pro is recommended for the prevention and treatment of peripartal problems such as difficult farrowing and MMA syndrome, for supporting the maturation of gametes (ova and spermatozoa) and for the prevention and treatment of various limb problems and lameness.In laying hens, the product is recommended for use mainly at the peak of egg production and when eggshell formation and other production disturbances occur. It has beneficial effects in the prevention of bone development disorders and joint problems, and it also improves fertility. It prevents heat stress under conditions of high ambient temperature. In turkey flocks, CaPhos Pro has been found to be effective in preventing aortic rupture due to copper deficiency.',
    packaging: '1, 20, 5, l',
    withdrawal_period: 'None',
    dosage:
      'The product is administered dissolved in the drinking water, over a period of 5–10 days.',
  },
  {
    id: 95,
    name: 'Illovit E-SE',
    type: 'rabbit',
    image: '/images/rabbit/IllovitE-SE.jpg',
    composition: 'Vitamin-E. selenium',
    indications_for_use:
      'To support the body’s antioxidant system and to prevent diseases, to prevent and treat reproductive disorders associated with vitamin E deficiency. In animals grazed in selenium-deficient areas, it prevents the development of deficiency symptoms and increases the viability of newborn animals. The synergic effects of the added plant extracts boost the immune system, and fix the efficency of physiological processes. Its biological effect is well established even during viral infections and vaccinations. Vascular protective, detoxifying processes are initiated, which accelerate the regeneration mechanisms.',
    packaging: ' 1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'Dissolved in drinking water. Do not use without dilution General dose: 0.5 ml / liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days.It is recommended to have the daily amount be drank in 4-6 hours.',
  },
  {
    id: 96,
    name: 'Illovit Hepar',
    type: 'rabbit',
    image: '/images/rabbit/IllovitHepar.jpg',
    composition:
      'Vitamin-B1, Vitamin-B6, Niacinamide, Choline Chloride, Inositol, L-Carnitine, Amino Acids, salts and analogs thereof: Lysine, DL-Methionine',
    indications_for_use:
      'It is recommended in all cases when the liver is overloaded. In particular: gastrointestinal diseases, feeding defects, suspicion of poisoning, viral infections, in the presence of feed toxins. To help liver function in herds with intensive growth vigor. It is recommended to use it after reaching peak production. The composition increases the detoxification capacity of the liver by administering methyl donors such as methionine or choline chloride. Following the use of antibiotics, the ability of the intestinal flora to synthesize vitamins is significantly reduced, so it is vital to administer B vitamins after treatment. Illovit Hepar helps herd regenerate and maximize production during and after treatment. Due to its composition, it maximizes the growth capacity of young animals. The benefits of the added plant extracts are based on traditional folk use, which has been confirmed by animal health impact studies. The extracts accelerate the body’s natural regeneration processes through their liver-supporting synergistic effect. They strengthen blood vessel walls, improve blood circulation. Gently activates and aids digestive and respiratory functions in animals with reduced production capacity.',
    packaging: ' 1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      '0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg of body weight for 3-7 days. It is recommended to have the daily amount be drank in 4-6 hours',
  },
  {
    id: 97,
    name: 'Illovit Multivitamin',
    type: 'rabbit',
    image: '/images/rabbit/IllovitMultivitamin.jpg',
    composition:
      'Vitamin-A, Vitamin-NED3, Vitamin-NEE, Vitamin-K3, Vitamin-B1, Vitamin-B2, Vitamin-B6, Vitamin-B12, Niacinamide, Choline Chloride, Pantothenic acid, Biotin',
    indications_for_use:
      'Combined multivitamin preparation. To maintain and increase growth vigor in the event of colds and weather front effects. It is suitable for vitamin supplementation of pets of all species and ages, for overcoming stress and deficiency diseases. Accelerates post-disease regeneration. The synergistic beneficial effect of the added plant extracts specifically protects and supports the respiratory system. It cleans the airways, helps to remove stuck mucus. It helps in post-disease regeneration, improves feed utilization, and aids digestion efficiency. It supports the circulatory system and the heart.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      '0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days. It is recommended to have the daily amoun be drank in 4-6 hours.',
  },
  {
    id: 98,
    name: 'Jolovit',
    type: 'rabbit',
    image: '/images/rabbit/Jolovit.jpg',
    composition:
      'Vitamin A 14.000 I.U., Vitamin d3 1.400 I.U., Vitamin E 10.5 mg, Vitamin K3 0.175 mg, Vitamin B1 1.4 mg, Vitamin B2 2.1 mg, Vitamin B6 1.4 mg, VitaminB12 0.014 mg, d-Panthenol 6.55 mg, Niacinamide 14.0 mg, Biotin 0.035 mg, Colin chloride 140 mg, B-carotene 0,01 mg',
    indications_for_use:
      'A combined multivitamin product to be used for vitamin supplementation in farm animals of any age and species, in order to correct vitamin deficiency caused by stress occurring at the time of placement and feed change.	Owing to its composition, Jolovit is suitable for ensuring optimum vitamin supply, which is a precondition of adequate production performance. It reduces the negative effects of stress, improves the viability of young animals and the hatchability of eggs, and accelerates recovery after diseases.',
    packaging: '0.1 l bottle, 1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'see label for the details.',
  },
  {
    id: 99,
    name: 'Norovit Amino Forte',
    type: 'rabbit',
    image: '/images/rabbit/NorovitAminoForte.jpg',
    composition:
      '/ ml: Vitamin A (3a672b) 40,000, IU Vitamin D3 (E671) 5,000, IU Vitamin E (3a700) 20.0 mg, Vitamin K3 (3a710) 2.0 mg, Vitamin B1 (3a820) 2.0 mg, Vitamin B2 0.2 mg, Vitamin B6 (3a831) 2.0 mg, Niacinamide (3a315) 14.0 mg, Vitamin C (E301) 5.0 mg, DL-methionine 8.2 mg, Lysine HCl 11.0 mg, Tryptophan 1.8 mg, Threonine 5.0 mg',
    indications_for_use:
      'A combination of essential amino acids and vitamins. It enhances muscle building and weight gain. Its use is recommended after placement to achieve higher constitutional strength and to pre-vent the adverse effects of stress due to movement and vaccination of the animals.',
    packaging: '1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'see label for the details.',
  },
  {
    id: 100,
    name: 'Norovit Thermo',
    type: 'rabbit',
    image: '/images/rabbit/NorovitThermo.jpg',
    composition:
      '/ ml: L-carnitine (3a910) 30.0 mg, Vitamin E (3a700) 40.0 mg, Vitamin B1 (3a820) 5.0 mg, Niacinamide (3a315) 10.0 mg, Vitamin B6 (3a831) 4.0 mg, Vitamin B12 0.02 mg, DL-methionine 10.0 mg, Lysine HCl 10.0 mg, Sodium selenite (E8) 0.40 mg, Sorbitol 160.0 mg',
    indications_for_use:
      'A product suitable for preventing the adverse effects of heat stress.Its constituents support the thermoregulation of animals. Heat stress damages the antioxidant system, and an elevated vitamin E and selenium level is needed to counter this effect. Heat shock results in a higher water and water-soluble vitamin loss for the organism, and there-fore the supplementation of vitamins of group B is absolutely necessary. In addition, vitamins B support the animals’ metabolism. Niacinamide participates in almost all enzymatic processes as a transmitter, as a signalling compound or as an energy-supplying compound. ',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: 'see label for the details.',
  },
  {
    id: 101,
    name: 'Tetravit ADE',
    type: 'rabbit',
    image: '/images/rabbit/TetravitADE.jpg',
    composition:
      '/1 ml: Nutritional additives – Vitamins and provitamins: Vitamin A (3a672b) 72.000 IU, Vitamin d3 (E671) 36.000 IU, Vitamin E (3a700) 72 mg. Owing to its high concentrations of vitamins A-, d3 – and E, the product strengthens the resistance of animals to infectious diseases. It supports normal feathering, the healthy development of bones and normal cardiac function. The product is excellently suitable for enhancing the general re-sistance of animals.',
    indications_for_use:
      'In growing animals, it is recommended for use once or twice monthly as a treatment course. When administered to pregnant female animals in the last third of gestation, the product increases the general resistance of the newborn animals, improves the regeneration of postparturient female animals, supports reconception, and shortens the service period. When administered to laying flocks once or twice a month as a treatment course, the product increases eggshell strength and in breeder flocks it improves fertility and hatchability.',
    packaging: '1 l plastic bottle. Packaging: 1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'see label for the details.',
  },
  {
    id: 102,
    name: 'TRIERRA medicated premix concentrate A.U.V.',
    type: 'rabbit',
    image: '/images/rabbit/TRIERRAmedicatedpremixconcentrateA.U.V.jpg',
    composition:
      '/1 kg: Oxytetracycline – dihydrate 150 g, Zinc-bacitracin 75 g, Excipients ad 1 000 g',
    indications_for_use:
      'Treatment of Clostridium spirioforme infections and additional epizootic rabbit enterocolitis.',
    packaging: '30 kg in multilayered, polyethylene lined paper sack',
    withdrawal_period: 'Meat and offal: 11 days',
    dosage:
      'Per os, mixed in feed. General dosage is 14 mg zinc-bacitracin and 28 mg. oxytetracycline – dihydrate / kilograms of bodyweight / day, during 10 days. Taking into account feed intake of 7 – 10 weeks old rabbits, dosage is as follows: 201 g zinc-bac-itracin and 402 g oxytetracikline – dihydrate / 1000 kg feed, which corresponds to 2,68 kg TRIERRA medicated premix concentrate A.U. V. / 1000 kg feed. Mixed medicated feed can be pelletized under 85°C.',
  },
  {
    id: 103,
    name: 'Vitapol Antitox',
    type: 'rabbit',
    image: '/images/rabbit/VitapolAntitox.jpg',
    composition:
      'Humic and fulvic acids, yeast cell wall extract, modified clay components',
    indications_for_use:
      'To neutralize the impact of mycotoxins without the drawbacks of conventional toxinbinders. VitaTox is unique on the market, a multi-component, wide spectrum feed additive, which provides an effective neutralisation of the damage caused by the mycotoxins. It eliminates toxic heavy metals from the body, detoxifyes and strengthens the immune system. It is in an easy to mix powder form, for mixing to feeds.',
    packaging: 'in 25 kg paper bag',
    withdrawal_period: 'None',
    dosage:
      'depending on the toxin contamination, its dosage is from 2-4 kg/1000 kg of feed.',
  },
  {
    id: 104,
    name: 'Vitapol Antitox Pulv.',
    type: 'rabbit',
    image: '/images/rabbit/VitapolAntitoxPulv..jpg',
    composition:
      '1m558 modified bentonite, Yeast cell wall extract, Organic mineral with high humic acid content',
    indications_for_use:
      'Functional group: substances for reduction of the contamination of feed by mycotoxins',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      '2 kg/ton, Grower-finisher feed: 1 kg/ton, Feeds heavily contaminated with mycotoxins: 2-4 kg/ton, The simultaneous oral use with macrolides and with robenidine shall be avoided',
  },
  {
    id: 105,
    name: 'Vitapol Liquid',
    type: 'rabbit',
    image: '/images/rabbit/VitapolLiquid.jpg',
    composition: 'humic and fulvic acids',
    indications_for_use:
      'VITAPOL® Liquid is a natural mineral feed for livestock, which supports immunesystem and production of animals. It is easy to be used in drinking water dispensing systems. The main active ingredients are humic and fulvic acids derived from the natural humification process of plant materials. It contains minerals and microelements in humic acid-complex form.',
    packaging: '100 ml, 1 – 5 – 10 – 20 liter',
    withdrawal_period: 'None',
    dosage:
      '1-2 l per 1000 liters of drinking water, depending on the immune status of the animals.',
  },
  {
    id: 106,
    name: 'Vitapol Pulvis',
    type: 'rabbit',
    image: '/images/rabbit/VitapolPulvis.jpg',
    composition:
      'Contains natural materials only. Binds toxicants and chemical residues. Enhances stress endurance. Its use results in a faster weigth gain and in a healthier stock. Decreases mortality.',
    indications_for_use:
      'Vitapol® mineral feed exclusively contains natural raw materials, natural acids, minerals and micro elements. It contains minerals and micronutrients in an organic humic acid complex form, so they are easily absorbed in the digestive tract of animals, thus promoting the digestibility of feed and a better feed consumption. Humic acids as natural agents, are possible alternatives of antibiotics and chemotherapeutic preparations in many ways. Thanks to its unique composition	Vitapol® has a positive effect on every species, it strengthens the immune system, and it helps the profitabilty of farming.',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      'The recommended amount is added mixed with feed. It can also be mixed into foods at home. It does not interact with other active ingredients, is sterilizable and stable. Sows: 0.5% continuously mixed from the choice into the sow diet. Newborn piglets: 0.5-1.0% mixed or sprinkled on the first solid feed.',
  },
  {
    id: 107,
    name: 'Caphos Pro',
    type: 'sheep',
    image: '/images/sheep/CaphosPro.jpg',
    composition:
      'Monocalcium phosphate, magnesium phosphate, magnesium chloride',
    indications_for_use:
      'CaPhos Pro is a liquid feed additive serving for the Ca and P supplementation of farm animals as well as for the prevention and treatment of problems caused by locomotor diseases and bone formation disturbances. It is recommended for use as a treatment course in periods of intensive growth, bone development and production. In pigs and in intensively reared poultry flocks its use is recommended in both the starter and the grower phase. In cattle and small ruminants the use of CaPhos Pro is needed in mineral deficiency conditions and when the balance of the calcium–phosphorus metabolism is disturbed. Such conditions include e.g. milk fever, various limb problems and lameness. The product has beneficial effects also in animals with reproductive disturbances (reduced fertility, disturbances of spermatogenesis) and in those with diseases of the skin and hair coat.In pigs, CaPhos Pro is recommended for the prevention and treatment of peripartal problems such as difficult farrowing and MMA syndrome, for supporting the maturation of gametes (ova and spermatozoa) and for the prevention and treatment of various limb problems and lameness.In laying hens, the product is recommended for use mainly at the peak of egg production and when eggshell formation and other production disturbances occur. It has beneficial effects in the prevention of bone development disorders and joint problems, and it also improves fertility. It prevents heat stress under conditions of high ambient temperature. In turkey flocks, CaPhos Pro has been found to be effective in preventing aortic rupture due to copper deficiency.',
    packaging: '1, 20, 5, l',
    withdrawal_period: 'None',
    dosage:
      'The product is administered dissolved in the drinking water, over a period of 5–10 days',
  },
  {
    id: 108,
    name: 'Illovit E-SE',
    type: 'sheep',
    image: '/images/sheep/IllovitE-SE.jpg',
    composition: 'Vitamin-E. selenium',
    indications_for_use:
      'To support the body’s antioxidant system and to prevent diseases, to prevent and treat reproductive disorders associated with vitamin E deficiency. In animals grazed in selenium-deficient areas, it prevents the development of deficiency symptoms and increases the viability of newborn animals. The synergic effects of the added plant extracts boost the immune system, and fix the efficency of physiological processes. Its biological effect is well established even during viral infections and vaccinations. Vascular protective, detoxifying processes are initiated, which accelerate the regeneration mechanisms.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'Dissolved in drinking water. Do not use without dilution General dose: 0.5 ml / liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days.It is recommended to have the daily amount be drank in 4-6 hours.',
  },
  {
    id: 109,
    name: 'Illovit Hepar',
    type: 'sheep',
    image: '/images/sheep/IllovitHepar.jpg',
    composition:
      'Vitamin-B1, Vitamin-B6, Niacinamide, Choline Chloride, Inositol, L-Carnitine, Amino Acids, salts and analogs thereof: Lysine, DL-Methionine',
    indications_for_use:
      'It is recommended in all cases when the liver is overloaded. In particular: gastrointestinal diseases, feeding defects, suspicion of poisoning, viral infections, in the presence of feed toxins. To help liver function in herds with intensive growth vigor. It is recommended to use it after reaching peak production. The composition increases the detoxification capacity of the liver by administering methyl donors such as methionine or choline chloride. Following the use of antibiotics, the ability of the intestinal flora to synthesize vitamins is significantly reduced, so it is vital to administer B vitamins after treatment. Illovit Hepar helps herd regenerate and maximize production during and after treatment. Due to its composition, it maximizes the growth capacity of young animals. The benefits of the added plant extracts are based on traditional folk use, which has been confirmed by animal health impact studies. The extracts accelerate the body’s natural regeneration processes through their liver-supporting synergistic effect. They strengthen blood vessel walls, improve blood circulation. Gently activates and aids digestive and respiratory functions in animals with reduced production capacity.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      '0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg of body weight for 3-7 days. It is recommended to have the daily amount be drank in 4-6 hours',
  },
  {
    id: 110,
    name: 'Illovit Multivitamin',
    type: 'sheep',
    image: '/images/sheep/IllovitMultivitamin.jpg',
    composition:
      '/ ml: Vitamin A (3a672b) 40,000, IU Vitamin D3 (E671) 5,000, IU Vitamin E (3a700) 20.0 mg, Vitamin K3 (3a710) 2.0 mg, Vitamin B1 (3a820) 2.0 mg, Vitamin B2 0.2 mg, Vitamin B6 (3a831) 2.0 mg, Niacinamide (3a315) 14.0 mg, Vitamin C (E301) 5.0 mg, DL-methionine 8.2 mg, Lysine HCl 11.0 mg, Tryptophan 1.8 mg, Threonine 5.0 mg',
    indications_for_use:
      'A combination of essential amino acids and vitamins. It enhances muscle building and weight gain. Its use is recommended after placement to achieve higher constitutional strength and to pre-vent the adverse effects of stress due to movement and vaccination of the animals.',
    packaging: '1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 111,
    name: 'Norovit Amino Forte',
    type: 'sheep',
    image: '/images/sheep/NorovitAminoForte.jpg',
    composition:
      '/ ml: Vitamin A (3a672b) 40,000, IU Vitamin D3 (E671) 5,000, IU Vitamin E (3a700) 20.0 mg, Vitamin K3 (3a710) 2.0 mg, Vitamin B1 (3a820) 2.0 mg, Vitamin B2 0.2 mg, Vitamin B6 (3a831) 2.0 mg, Niacinamide (3a315) 14.0 mg, Vitamin C (E301) 5.0 mg, DL-methionine 8.2 mg, Lysine HCl 11.0 mg, Tryptophan 1.8 mg, Threonine 5.0 mg',
    indications_for_use:
      'A combination of essential amino acids and vitamins. It enhances muscle building and weight gain. Its use is recommended after placement to achieve higher constitutional strength and to pre-vent the adverse effects of stress due to movement and vaccination of the animals.',
    packaging: '1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 112,
    name: 'Norovit Hepar',
    type: 'sheep',
    image: '/images/sheep/NorovitHepar.jpg',
    composition:
      '/ ml: Vitamin B1 (3a820) 5.0 mg, Vitamin B2 4.0 mg, Vitamin B6 (3a831) 4.0 mg, D-panthenol (3a842) 20.0 mg, Biotin (3a880) 0.015 mg, Vitamin K3 (3a710) 2.0 mg, Niacinamid (3a315) 40.0 mg, Inositol 5.0 mg, Choline chloride (3a890) 200.0 mg, Lysine HCl 20.0 mg, DL-methionine 10.0 mg. Like choline, inositol has a ‘fat burning’ effect, it prevents the deposition of fats in the liver and accelerates their excretion. Vitamins of the B group provide energy for the body by supporting the metabolism of carbohydrates, proteins and fats.',
    indications_for_use:
      'To support liver function, especially in flocks and herds of intensive growth rate. The product enhances the detoxifying capacity of the liver by introducing into the body methyl donors such as methionine or choline chloride.',
    packaging: '1 liter and 5 liter plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 113,
    name: 'Norovit Thermo',
    type: 'sheep',
    image: '/images/sheep/NorovitThermo.jpg',
    composition:
      '/ ml: L-carnitine (3a910) 30.0 mg, Vitamin E (3a700) 40.0 mg, Vitamin B1 (3a820) 5.0 mg, Niacinamide (3a315) 10.0 mg, Vitamin B6 (3a831) 4.0 mg, Vitamin B12 0.02 mg, DL-methionine 10.0 mg, Lysine HCl 10.0 mg, Sodium selenite (E8) 0.40 mg, Sorbitol 160.0 mg',
    indications_for_use:
      'A product suitable for preventing the adverse effects of heat stress. Its constituents support the thermoregulation of animals. Heat stress damages the antioxidant system, and an elevated vitamin E and selenium level is needed to counter this effect. Heat shock results in a higher water and water-soluble vitamin loss for the organism, and there-fore the supplementation of vitamins of group B is absolutely necessary. In addition, vitamins B support the animals’ metabolism. Niacinamide participates in almost all enzymatic processes as a transmitter, as a signalling compound or as an energy-supplying compound.',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 114,
    name: 'Ruminogen pulvis',
    type: 'sheep',
    image: '/images/sheep/Ruminogenpulvis.jpg',
    composition: 'K-propionate',
    indications_for_use:
      'Treatment for cattle, sheep and goat primarily alimentary based, secondary  for acute and intensive craw torpidity cases related to organ diseases, delivery, post operational condition, etc. rumen  dysfunction. Curing acute bloating in calves and therapy for ketosis in cows.  It will restore the normal biological balance in the rumen, it will bring optimal activity in the ﬂora and fauna liquid of the rumen. It uses the fumaric-acid and propionic acid absorbed from it for the glycogen synthesis of the liver, to prevent liver damage.',
    packaging: '100 g, 1 kg, 5 kg',
    withdrawal_period: 'None',
    dosage: '100 g/animal/day in case of rumen problems.',
  },
  {
    id: 115,
    name: 'Ruminostart drench',
    type: 'sheep',
    image: '/images/sheep/Ruminostartdrench.jpg',
    composition:
      'Amino acids, Ca-proprionate, Glucose. Ruminostart Drench is a special feed supplement developed for freshly calved cows.',
    indications_for_use:
      'Use immediately after calving, and during the production for mitigation of energy metabolism disorders and for prevention and adjunctive treatment of various metabolic disorders.',
    packaging: '700 g',
    withdrawal_period: 'None',
    dosage:
      '700 g/cow in twenty liter of warm water after calving or in case of rumen problems.',
  },
  {
    id: 116,
    name: 'Soluselen injection A.U.V.',
    type: 'sheep',
    image: '/images/sheep/SoluseleninjectionA.U.V..jpg',
    composition:
      '/ 1 ml: Selenium (in form of Natrium-Selenite) 0,5 mg, excipients ad 1 ml',
    indications_for_use:
      'Prevention of skeletal and heart muscle abnormalities in young lambs, and prevention and treatment lack of selenium caused by insufficent selenium content of feed.',
    packaging: '100 ml',
    withdrawal_period: '0 day.',
    dosage: '1 ml/10 bwkg',
  },
  {
    id: 117,
    name: 'Tetrakolin',
    type: 'sheep',
    image: '/images/sheep/Tetrakolin.jpg',
    composition:
      '/1 ml: Vitamin B2 5.00 mg, Niacinamide (3a315) 50.00 mg, Vitamin B6 (3a831) 8.00 mg, Vitamin B12 0.05 mg, d-panthenol (3a842) 86.00 mg, Biotin (3a880) 0.10 mg, Choline chloride (3a890) 21.75 mg',
    indications_for_use:
      'A complex vitamin B product to facilitate utilisation of the young-age growth capacity of farm animals of high growth rate. In laying hens, it helps lengthen the egg production cycle and reduces the severity of diseases and toxicoses of dietary origin. By the regular use of the product the liver damage of intensively pro-ducing farm animals can be prevented. The product increases the resistance of the liver to hepato-toxic effects. Through its hepatoprotective effect, the product supports the prevention and treatment of ketosis in dairy cows.',
    packaging: '1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 118,
    name: 'Tetraselenium 400-E',
    type: 'sheep',
    image: '/images/sheep/Tetraselenium400-E.jpg',
    composition:
      '/1 ml: Vitamin E (3a700) 80 mg, Sodium selenite (E8) 400 µg. The product is indicated for supporting the antioxidant system of animals, for increasing their stress tolerance and for the prevention and treatment of reproductive disturbances associated with vitamin E deficiency. Selenium, which is a constituent of an enzyme playing a key role in the antioxidant system, and vitamin E, which has antioxidant properties, protect cells from the harmful effects of peroxides.',
    indications_for_use:
      'Their deficiency primarily leads to degenerative changes of the myocardium, the skeletal muscles and the wall of blood vessels (skeletal muscle degeneration, encephalomalacia, exudative diathesis). Its use is recommended in the period of seualx maturation and fertilisation, and for the treatment of reproductive disturbances.',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 119,
    name: 'Tetravit ADE',
    type: 'sheep',
    image: '/images/sheep/TetravitADE.jpg',
    composition:
      '/1 ml: Nutritional additives – Vitamins and provitamins: Vitamin A (3a672b) 72.000 IU, Vitamin d3 (E671) 36.000 IU, Vitamin E (3a700) 72 mg. Owing to its high concentrations of vitamins A-, d3 – and E, the product strengthens the resistance of animals to infectious diseases. It supports normal feathering, the healthy development of bones and normal cardiac function. The product is excellently suitable for enhancing the general re-sistance of animals.',
    indications_for_use:
      'In growing animals, it is recommended for use once or twice monthly as a treatment course. When administered to pregnant female animals in the last third of gestation, the product increases the general resistance of the newborn animals, improves the regeneration of postparturient female animals, supports reconception, and shortens the service period. When administered to laying flocks once or twice a month as a treatment course, the product increases eggshell strength and in breeder flocks it improves fertility and hatchability.',
    packaging: '1 l plastic bottle. Packaging: 1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 120,
    name: 'Vitamix B',
    type: 'sheep',
    image: '/images/sheep/VitamixB.jpg',
    composition:
      '/1 ml: Vitamin B1 (3a820) 4.00 mg, Vitamin B2 6.00 mg, Niacinamide (3a315) 30.00 mg, Vitamin B6 (3a831) 3.00 mg, Vitamin B12 0.015 mg, d-panthenol (3a842) 8.6 mg, Biotin (3a880) 0.03 mg, Folic acid (3a316) 0.5 mg',
    indications_for_use:
      'A complex vitamin B product which contains all essential group B vitamins. It increases the general resistance and stress tolerance of animals. It can be used favourably in cases of deficient vitamin synthesis following antibiotic therapy. By its use, vitamin B deficiency manifesting itself in decreased production and impaired appetite can be corrected.',
    packaging: '1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 121,
    name: 'Vitapol Antimast',
    type: 'sheep',
    image: '/images/sheep/VitapolAntimast.jpg',
    composition:
      'Helps the regeneration of udder tissues and to fight off bacteria, thus reducing milk somatic cell count. In addition, Vitapol AntiMast can also prevent and stop diarrhea on short term and reduce lameness, laminitis, Mortellaro-disease (digital dermatitis) on the long run.',
    indications_for_use:
      'Helps the regeneration of udder tissues and to fight off bacteria, thus reducing milk somatic cell count. In addition, Vitapol AntiMast can also prevent and stop diarrhea on short term and reduce lameness, laminitis, Mortellaro-disease (digital dermatitis) on the long run.',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      '150 g/cow/day for 14 days. On the 15th day sampling and controlling the somatic cell count in the milk. For more details see the label.',
  },
  {
    id: 122,
    name: 'Vitapol Antitox',
    type: 'sheep',
    image: '/images/sheep/VitapolAntitox.jpg',
    composition:
      'Humic and fulvic acids, yeast cell wall extract, modified clay components',
    indications_for_use:
      'To neutralize the impact of mycotoxins without the drawbacks of conventional toxinbinders. VitaTox is unique on the market, a multi-component, wide spectrum feed additive, which provides an effective neutralisation of the damage caused by the mycotoxins. It eliminates toxic heavy metals from the body, detoxifyes and strengthens the immune system. It is in an easy to mix powder form, for mixing to feeds. It is suggested to mix to the feed before granulating. It can be mixed in one round with all medicals, vitamins and other feed supplements. Its active ingredients are not degraded by heat or pressure, and are stable.',
    packaging: 'in 25 kg paper bag',
    withdrawal_period: 'None',
    dosage:
      'depending on the toxin contamination, its dosage is from 2-4 kg/1000 kg of feed.',
  },
  {
    id: 123,
    name: 'Vitapol Liquid',
    type: 'sheep',
    image: '/images/sheep/VitapolLiquid.jpg',
    composition: 'humic and fulvic acids',
    indications_for_use:
      'VITAPOL® Liquid is a natural mineral feed for livestock, which supports immunesystem and production of animals. It is easy to be used in drinking water dispensing systems. The main active ingredients are humic and fulvic acids derived from the natural humification process of plant materials. It contains minerals and microelements in humic acid-complex form.',
    packaging: '100 ml, 1 – 5 – 10 – 20 liter',
    withdrawal_period: 'None',
    dosage:
      '1-2 l per 1000 liters of drinking water, depending on the immune status of the animals.',
  },
  {
    id: 124,
    name: 'Vitapol Pulvis',
    type: 'sheep',
    image: '/images/sheep/VitapolPulvis.jpg',
    composition:
      'Contains natural materials only. Binds toxicants and chemical residues. Enhances stress endurance. Its use results in a faster weigth gain and in a healthier stock. Decreases mortality.',
    indications_for_use:
      'Vitapol® mineral feed exclusively contains natural raw materials, natural acids, minerals and micro elements. It contains minerals and micronutrients in an organic humic acid complex form, so they are easily absorbed in the digestive tract of animals, thus promoting the digestibility of feed and a better feed consumption. Humic acids as natural agents, are possible alternatives of antibiotics and chemotherapeutic preparations in many ways. Thanks to its unique composition Vitapol® has a positive effect on every species, it strengthens the immune system, and it helps the profitabilty of farming.',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      'The recommended amount is added mixed with feed. It can also be mixed into foods at home. It does not interact with other active ingredients, is sterilizable and stable. Sows: 0.5% continuously mixed from the choice into the sow diet. Newborn piglets: 0.5-1.0% mixed or sprinkled on the first solid feed.',
  },
  {
    id: 125,
    name: 'Vitapol suspension',
    type: 'sheep',
    image: '/images/sheep/Vitapolsuspension.jpg',
    composition:
      'Moisture 85% Humic acids 80 %, Sodium (Na) 0.02 %, Crude ash 4 %, Calcium (Ca) 1.1 % Magnesium (Mg) 0,1 %, Phosphorus (P) 0.02 % Iron (Fe) 600 mg/kg, Manganese (Mn) 18 mg/kg, Zinc (Zn) 8.0 mg/kg, Molybdenum (Mo) 0.6 mg/kg, Cobalt (Co) 0.4 mg/kg',
    indications_for_use:
      'It can be mixed into the milk or milk replacer of calves or poured on the feed. For piglets serve on a tray on the firs week. Strengthens the immune system and prevents diarrhea.',
    packaging: 'in 20 kg plastic bucke',
    withdrawal_period: 'None',
    dosage:
      'Cattle Calf: 1 dl/day/calf mixed into the milk replacer, In case of severe diarrhoea 2 dl/day/calf. Adult cattle: 2 dl/animal/day. Swine Newborn piglets: 1 dl/litter/day. Sows: 1 dl/day/animal during the late pregnancy and lactation.',
  },
  {
    id: 126,
    name: 'Alphadoxan',
    type: 'swine',
    image: '/images/swine/Alphadoxan.jpg',
    composition: 'Doxycycline 100 mg',
    indications_for_use:
      'For the treatment of swine doxycycline-sensitive Actinobacillus pleuropneumoniae, Pasteurella multocida, Bordetella bronchiseptica respiratory infections and infections caused by Streptococcus suis.',
    packaging: '10 kg',
    withdrawal_period: 'meat and other edible tissues 8 days.',
    dosage:
      '10 mg doxycycline / kg body weight / day for 5 consecutive days. Feed intake depends on the clinical condition of the animals, therefore the incorporation rate should be based on the current feed intake.',
  },
  {
    id: 127,
    name: 'Caphos Pro',
    type: 'swine',
    image: '/images/swine/CaphosPro.jpg',
    composition:
      'Monocalcium phosphate, magnesium phosphate, magnesium chloride',
    indications_for_use:
      'CaPhos Pro is a liquid feed additive serving for the Ca and P supplementation of farm animals as well as for the prevention and treatment of problems caused by locomotor diseases and bone formation disturbances. It is recommended for use as a treatment course in periods of intensive growth, bone development and production. In pigs and in intensively reared poultry flocks its use is recommended in both the starter and the grower phase. In cattle and small ruminants the use of CaPhos Pro is needed in mineral deficiency conditions and when the balance of the calcium–phosphorus metabolism is disturbed. Such conditions include e.g. milk fever, various limb problems and lameness. The product has beneficial effects also in animals with reproductive disturbances (reduced fertility, disturbances of spermatogenesis) and in those with diseases of the skin and hair coat.In pigs, CaPhos Pro is recommended for the prevention and treatment of peripartal problems such as difficult farrowing and MMA syndrome, for supporting the maturation of gametes (ova and spermatozoa) and for the prevention and treatment of various limb problems and lameness.In laying hens, the product is recommended for use mainly at the peak of egg production and when eggshell formation and other production disturbances occur. It has beneficial effects in the prevention of bone development disorders and joint problems, and it also improves fertility. It prevents heat stress under conditions of high ambient temperature. In turkey flocks, CaPhos Pro has been found to be effective in preventing aortic rupture due to copper deficiency.',
    packaging: '1, 20, 5, l',
    withdrawal_period: 'None',
    dosage:
      'The product is administered dissolved in the drinking water, over a period of 5–10 days.',
  },
  {
    id: 128,
    name: 'Coliphyl premix for pigs A.U.V.',
    type: 'swine',
    image: '/images/swine/ColiphylpremixforpigsA.U.V.jpg',
    composition:
      'Colistin-sulfat 120 g/kg. Treatment of colistin sensitive intestinal infections caused by E. coli.',
    indications_for_use:
      'General dosage is 6 mg colistin-sulfat/ 1 kilogram of bodyweight/day during 5 consecutive days.Products shall be administered in feed by mixing 1 g Coliphyl premix /1 kg feed. Calculated amount of product shall be added by using a calibrated beaker. Product can be used in pelletized feed, if presteaming happened on less than 80°C.',
    packaging: '10 kg multilayer and polyethylene lined paper sack.',
    withdrawal_period: 'Meat and offal: 2 days',
    dosage:
      'General dosage is 6 mg colistin-sulfat/ 1 kilogram of bodyweight/day',
  },
  {
    id: 129,
    name: 'Erra-6 medicated premix A.U.V',
    type: 'swine',
    image: '/images/swine/Erra-6medicatedpremixA.U.V.jpg',
    composition:
      'Treatment of respiratory and intestinal infections caused by oxytetracycline sensitive bacteria.',
    indications_for_use:
      'Pasteurellosis (Pasteurella multocida), Enzootic pneumonia (Mycoplasma hyopneumoniae), Mycoplasma arthritis (Mycoplasma hyosynoviae), Glässer’s disease of pigs (Haemophilus parasuis)',
    packaging: '30 kg',
    withdrawal_period: 'None',
    dosage:
      'bodyweight/day, during 3 – 5 days. This amount corresponds 10 – 15 kilograms ERRA-6 medicated premix / tonnes of feed.',
  },
  {
    id: 130,
    name: 'Erra-600 medicated premix A.U.V.',
    type: 'swine',
    image: '/images/swine/Erra-600medicatedpremixA.U.V..jpg',
    composition: 'Oxytetracycline – dihydrate 600 g/kg',
    indications_for_use:
      'Pasteurellosis (Pasteurella multocida), Enzootic pneumonia (Mycoplasma hyopneumoniae), Mycoplasma arthritis (Mycoplasma hyosynoviae), Glässer’s disease of pigs (Haemophilus parasuis)',
    packaging: '30 kg in multilayered, polyethylene lined paper sack',
    withdrawal_period: 'Meat and offal: 11 days',
    dosage:
      'General dosage is 40 mg oxytetracycline – dihydrate/kilograms of bodyweight/day, during 3–5 days. This amount corresponds 1–1,5 kilograms ERRA-6 medicated premix/tonnes of feed. Treatment of respiratory and intestinal infections caused by oxytetracyclin sensitive bacteria.',
  },
  {
    id: 131,
    name: 'Illovit E-SE',
    type: 'swine',
    image: '/images/swine/IllovitE-SE.jpg',
    composition: 'Vitamin-E. selenium',
    indications_for_use:
      'To support the body’s antioxidant system and to prevent diseases, to prevent and treat reproductive disorders associated with vitamin E deficiency. In animals grazed in selenium-deficient areas, it prevents the development of deficiency symptoms and increases the viability of newborn animals. The synergic effects of the added plant extracts boost the immune system, and fix the efficency of physiological processes. Its biological effect is well established even during viral infections and vaccinations. Vascular protective, detoxifying processes are initiated, which accelerate the regeneration mechanisms.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'Dissolved in drinking water. Do not use without dilution General dose: 0.5 ml / liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days.It is recommended to have the daily amount be drank in 4-6 hours.',
  },
  {
    id: 132,
    name: 'Illovit Hepar',
    type: 'swine',
    image: '/images/swine/IllovitHepar.jpg',
    composition:
      'Vitamin-B1, Vitamin-B6, Niacinamide, Choline Chloride, Inositol, L-Carnitine, Amino Acids, salts and analogs thereof: Lysine, DL-Methionine',
    indications_for_use:
      'It is recommended in all cases when the liver is overloaded. In particular: gastrointestinal diseases, feeding defects, suspicion of poisoning, viral infections, in the presence of feed toxins. To help liver function in herds with intensive growth vigor. It is recommended to use it after reaching peak production. The composition increases the detoxification capacity of the liver by administering methyl donors such as methionine or choline chloride. Following the use of antibiotics, the ability of the intestinal flora to synthesize vitamins is significantly reduced, so it is vital to administer B vitamins after treatment. Illovit Hepar helps herd regenerate and maximize production during and after treatment. Due to its composition, it maximizes the growth capacity of young animals. The benefits of the added plant extracts are based on traditional folk use, which has been confirmed by animal health impact studies. The extracts accelerate the body’s natural regeneration processes through their liver-supporting synergistic effect. They strengthen blood vessel walls, improve blood circulation. Gently activates and aids digestive and respiratory functions in animals with reduced production capacity.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      '0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg of body weight for 3-7 days. It is recommended to have the daily amount be drank in 4-6 hours',
  },
  {
    id: 133,
    name: 'Illovit Multivitamin',
    type: 'swine',
    image: '/images/swine/IllovitMultivitamin.jpg',
    composition:
      'Vitamin-A, Vitamin-NED3, Vitamin-NEE, Vitamin-K3, Vitamin-B1, Vitamin-B2, Vitamin-B6, Vitamin-B12, Niacinamide, Choline Chloride, Pantothenic acid, Biotin',
    indications_for_use:
      'Combined multivitamin preparation. To maintain and increase growth vigor in the event of colds and weather front effects. It is suitable for vitamin supplementation of pets of all species and ages, for overcoming stress and deficiency diseases. Accelerates post-disease regeneration. The synergistic beneficial effect of the added plant extracts specifically protects and supports the respiratory system. It cleans the airways, helps to remove stuck mucus. It helps in post-disease regeneration, improves feed utilization, and aids digestion efficiency. It supports the circulatory system and the heart.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'General dose: 0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days. It is recommended to have the daily amoun be drank in 4-6 hours.',
  },
  {
    id: 134,
    name: 'Jolovit',
    type: 'swine',
    image: '/images/swine/Jolovit.jpg',
    composition:
      'Vitamin A 14.000 I.U., Vitamin d3 1.400 I.U., Vitamin E 10.5 mg, Vitamin K3 0.175 mg, Vitamin B1 1.4 mg, Vitamin B2 2.1 mg, Vitamin B6 1.4 mg, VitaminB12 0.014 mg, d-Panthenol 6.55 mg, Niacinamide 14.0 mg, Biotin 0.035 mg, Colin chloride 140 mg, B-carotene 0,01 mg',
    indications_for_use:
      'A combined multivitamin product to be used for vitamin supplementation in farm animals of any age and species, in order to correct vitamin deficiency caused by stress occurring at the time of placement and feed change. Owing to its composition, Jolovit is suitable for ensuring optimum vitamin supply, which is a precondition of adequate production performance. It reduces the negative effects of stress, improves the viability of young animals and the hatchability of eggs, and accelerates recovery after diseases.',
    packaging: '0.1 l bottle, 1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'see label for the details.',
  },
  {
    id: 135,
    name: 'Klorinvet Granular 161 mg/g medicated premix',
    type: 'swine',
    image: '/images/swine/KlorinvetGranular161mggmedicatedpremix.jpg',
    composition:
      'Chlortetracycline hydrochloride (equals 150,0 g chlortetracycline) 161 g / kg. Treatment of infections of turkey and swine caused by microorganisms sensitive for chlortetracycline.',
    indications_for_use:
      'For oral use, mixed to the feed Average dose: Swine: 20 mg/bwkg per day for 5-7 days Turkeys: for treatment 30 mg/bwkg per day for 7 days for herd treatment 10 mg/bwkg per day for 21 days',
    packaging: '1 kg in PE container; 5, 20, 25 kg in triplex foil sack',
    withdrawal_period: 'Turkeys: 3 days Swine. 10 days',
    dosage:
      '500 g/cow/day in the fresh and high milk yield group of cows. For high genetical value herds we recommend it in the medium milk yield group, too.',
  },
  {
    id: 136,
    name: 'Klorinvet Granular 500 mg/g medicated premix',
    type: 'swine',
    image: '/images/swine/KlorinvetGranular500mggmedicatedpremix.jpg',
    composition:
      'Chlortetracycline hydrochloride 500 g/kg (equals 465 g chlortetracycline)',
    indications_for_use:
      'Turkey: Infections caused by Pasteurella multocida (MIC 0,125-2) and Clostridium perfringens (MIC 0,0625-4). Swine: infections caused by Pasteurella multocida (MIC 0,125-1).',
    packaging: '25 kg in triplex foil sack',
    withdrawal_period: 'Turkeys at dose of 30 mg/bwkg: 3 days Swine 15 days',
    dosage:
      'For oral use, mixed to the feed Average dose: Swine: 20 mg/bwkg per day for 5-7 days Turkeys: 30 mg/bwkg per day for 7 days',
  },
  {
    id: 137,
    name: 'Lincophyl 110 medicated premix A.U.V.',
    type: 'swine',
    image: '/images/swine/Lincophyl110medicatedpremixA.U.V..jpg',
    composition: 'Lincomycin (in form of hydrochloride) 110 g/kg',
    indications_for_use:
      'Treatment of pig dysentery (Brachyspira hyodysenteriae). Prevention and treatment of pig pneumonia caused by Mycoplasma hyopneumoniae (enzootic pneumonia) as well as proliferative enteropathia (Lawsonia intracellularis).',
    packaging: '30 kg in multi layered, polyethylene lined paper sack',
    withdrawal_period: 'Meat and offal: 7 days',
    dosage:
      'Treatment of pig dysentery 110 mg / 1 kilogram feed during 3 weeks, or until ceasing of symptoms of disease (light, mucous, bloody faeces), respectively. This amount corresponds to 1 kilogram of Lincophyl 110 medicated premix A.U.V. / tonnes of feed. Treatment of enzootic pneumonia (Mycoplasma hyopneumoniae) 220 mg/1 kilogram feed during 3 weeks, or until ceasing of symptoms of  disease, respectively. This amount corresponds to 2 kilogram of Lincophyl 110 medicated premix A.U.V. / tonnes of feed. Treatment of proliferative enteropathia (Lawsonia intracellularis) 220 mg/1 kilogram feed during 3 weeks, or until ceasing of symptoms of disease, respectively. This amount corresponds to 2 kilogram of Lincophyl 110 medicated premix A.U.V. / tonnes of feed. Route of administration: per os mixed in feed.',
  },
  {
    id: 138,
    name: 'Neohemogen pulvis complementary feed',
    type: 'swine',
    image: '/images/swine/Neohemogenpulviscomplementaryfeed.jpg',
    composition:
      'Iron-fumarate. Organic iron complex product for avoiding anemia of young piglets. It contains the most important micronutrients, too, for providing an optimal enzymatic function in the suckling period.',
    indications_for_use:
      'To prevent, or treat suckling pig anemia. The composition of the product depends on the physiological needs of the newborn piglet. The trace elements are supporting the haemopoetic effect of ironfumarate, and the additives mean additional energy.',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage: 'On a tray, about 5 g/piglet/day until weaning.',
  },
  {
    id: 139,
    name: 'Norovit Amino Forte',
    type: 'swine',
    image: '/images/swine/NorovitAminoForte.jpg',
    composition:
      '/ ml: Vitamin A (3a672b) 40,000, IU Vitamin D3 (E671) 5,000, IU Vitamin E (3a700) 20.0 mg, Vitamin K3 (3a710) 2.0 mg, Vitamin B1 (3a820) 2.0 mg, Vitamin B2 0.2 mg, Vitamin B6 (3a831) 2.0 mg, Niacinamide (3a315) 14.0 mg, Vitamin C (E301) 5.0 mg, DL-methionine 8.2 mg, Lysine HCl 11.0 mg, Tryptophan 1.8 mg, Threonine 5.0 mg',
    indications_for_use:
      'A combination of essential amino acids and vitamins. It enhances muscle building and weight gain. Its use is recommended after placement to achieve higher constitutional strength and to pre-vent the adverse effects of stress due to movement and vaccination of the animals.',
    packaging: '1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 140,
    name: 'Norovit Hepar',
    type: 'swine',
    image: '/images/swine/NorovitHepar.jpg',
    composition:
      '/ ml: Vitamin B1 (3a820) 5.0 mg, Vitamin B2 4.0 mg, Vitamin B6 (3a831) 4.0 mg, D-panthenol (3a842) 20.0 mg, Biotin (3a880) 0.015 mg, Vitamin K3 (3a710) 2.0 mg, Niacinamid (3a315) 40.0 mg, Inositol 5.0 mg, Choline chloride (3a890) 200.0 mg, Lysine HCl 20.0 mg, DL-methionine 10.0 mg. Like choline, inositol has a ‘fat burning’ effect, it prevents the deposition of fats in the liver and accelerates their excretion. Vitamins of the B group provide energy for the body by supporting the metabolism of carbohydrates, proteins and fats.',
    indications_for_use:
      'To support liver function, especially in flocks and herds of intensive growth rate. The product enhances the detoxifying capacity of the liver by introducing into the body methyl donors such as methionine or choline chloride.',
    packaging: '1 liter and 5 liter plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 141,
    name: 'Norovit Thermo',
    type: 'swine',
    image: '/images/swine/NorovitThermo.jpg',
    composition:
      '/ ml: L-carnitine (3a910) 30.0 mg, Vitamin E (3a700) 40.0 mg, Vitamin B1 (3a820) 5.0 mg, Niacinamide (3a315) 10.0 mg, Vitamin B6 (3a831) 4.0 mg, Vitamin B12 0.02 mg, DL-methionine 10.0 mg, Lysine HCl 10.0 mg, Sodium selenite (E8) 0.40 mg, Sorbitol 160.0 mg',
    indications_for_use:
      'A product suitable for preventing the adverse effects of heat stress. Its constituents support the thermoregulation of animals. Heat stress damages the antioxidant system, and an elevated vitamin E and selenium level is needed to counter this effect. Heat shock results in a higher water and water-soluble vitamin loss for the organism, and there-fore the supplementation of vitamins of group B is absolutely necessary. In addition, vitamins B support the animals’ metabolism. Niacinamide participates in almost all enzymatic processes as a transmitter, as a signalling compound or as an energy-supplying compound.',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 142,
    name: 'Phylamic',
    type: 'swine',
    image: '/images/swine/Phylamic.jpg',
    composition:
      '/1 ml: Mn (E5) 12.0 mg, Zn (E6) 8.0 mg, Cu (E4) 1.6 mg, Se (E8) 0.03 mg',
    indications_for_use:
      'A microelement complex that supports enzyme reactions in the body and cartilage development in animals of a high growth rate. Its use is recommended after placement and in case of locomotor problems (extremital abnormalities caused by microelement deficiencies in poultry and turkeys, thickening of joints, hyperkeratosis, anaemia, developmental disturbances). Regular use of the product increases egg production, fertility and hatchability. When using the product, the microelement content of the diets fed to the birds must be taken into consideration.',
    packaging:
      '100 ml and 1 liter, in plastic bottle; 5 l and 20 l in plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 143,
    name: 'Phylamox 100 % WSP A.U.V.',
    type: 'swine',
    image: '/images/swine/Phylamox100WSPA.U.V.jpg',
    composition:
      'amoxicilin. The veterinary medicinal product serves for the treatment of infectious diseases caused by amoxicillin-sensitive Gram-positive and Gram-negative bacteria in domestic fowl (broiler chickens) and pigs.',
    indications_for_use:
      'Pasteurellosis (Pasteurella multocida), Infections caused by Streptococcus suis • Pleuropneumonia of pigs (Actinobacillus pleuropneumoniae), Glässer’s disease of pigs (Haemophilus parasuis) domestic fowl, Fowl cholera (Pasteurella multocida). Colibacillosis (Escherichia coli) The product should be used on the basis of the results of antibiotic susceptibility tests by taking into consideration the guidelines for the use of antimicrobial agents.',
    packaging:
      '80 g in a polyethylene box closed with a polyethylene lid having a warranty seal. 250 g, 1 kg in polyethylene box closed with a polyethylene screw cap.',
    withdrawal_period:
      'domestic fowl (broiler chicken) meat and offal: 4 days. Pig meat and offal: 4 days. The veterinary medicinal product is not authorized for the treatment of laying hens producing eggs intended for human consumption.',
    dosage:
      'Broiler chickens: 20 mg Phylamox 100% powder A.U.V./1 kilogram of body weight for the preparation of a solution for internal use for animal treatment over a period of 3–5 days. Calculated amount of product shall be added to drinking water of animals taken in within three hours. Before treatment animals shall be withdrawn from water. Pigs: 10–20 mg Phylamox 100% powder A.U.V./1kilogram of body weight for the preparation of a solution for internal use for animal treatment over a period of 3–5 days. Calculated amount of product shall be mixed into 5-10 litres water. This pre-dilution shall be added to drinking water of animals. Before treatment animals shall be withdrawn from water.',
  },
  {
    id: 144,
    name: 'Tetrakolin',
    type: 'swine',
    image: '/images/swine/Tetrakolin.jpg',
    composition:
      '/1 ml: Vitamin B2 5.00 mg, Niacinamide (3a315) 50.00 mg, Vitamin B6 (3a831) 8.00 mg, Vitamin B12 0.05 mg, d-panthenol (3a842) 86.00 mg, Biotin (3a880) 0.10 mg, Choline chloride (3a890) 21.75 mg',
    indications_for_use:
      'A complex vitamin B product to facilitate utilisation of the young-age growth capacity of farm animals of high growth rate. In laying hens, it helps lengthen the egg production cycle and reduces the severity of diseases and toxicoses of dietary origin. By the regular use of the product the liver damage of intensively pro-ducing farm animals can be prevented. The product increases the resistance of the liver to hepato-toxic effects. Through its hepatoprotective effect, the product supports the prevention and treatment of ketosis in dairy cows.',
    packaging: '1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 145,
    name: 'Tetraselenium 400-E',
    type: 'swine',
    image: '/images/swine/Tetraselenium400-E.jpg',
    composition:
      '/1 ml: Vitamin E (3a700) 80 mg, Sodium selenite (E8) 400 µg. The product is indicated for supporting the antioxidant system of animals, for increasing their stress tolerance and for the prevention and treatment of reproductive disturbances associated with vitamin E deficiency. Selenium, which is a constituent of an enzyme playing a key role in the antioxidant system, and vitamin E, which has antioxidant properties, protect cells from the harmful effects of peroxides.',
    indications_for_use:
      'Their deficiency primarily leads to degenerative changes of the myocardium, the skeletal muscles and the wall of blood vessels (skeletal muscle degeneration, encephalomalacia, exudative diathesis). Its use is recommended in the period of seualx maturation and fertilisation, and for the treatment of reproductive disturbances.',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 146,
    name: 'Tetravit ADE',
    type: 'swine',
    image: '/images/swine/TetravitADE.jpg',
    composition:
      '/1 ml: Nutritional additives – Vitamins and provitamins: Vitamin A (3a672b) 72.000 IU, Vitamin d3 (E671) 36.000 IU, Vitamin E (3a700) 72 mg. Owing to its high concentrations of vitamins A-, d3 – and E, the product strengthens the resistance of animals to infectious diseases. It supports normal feathering, the healthy development of bones and normal cardiac function. The product is excellently suitable for enhancing the general re-sistance of animals.',
    indications_for_use:
      'In growing animals, it is recommended for use once or twice monthly as a treatment course. When administered to pregnant female animals in the last third of gestation, the product increases the general resistance of the newborn animals, improves the regeneration of postparturient female animals, supports reconception, and shortens the service period. When administered to laying flocks once or twice a month as a treatment course, the product increases eggshell strength and in breeder flocks it improves fertility and hatchability.',
    packaging: '1 l plastic bottle. Packaging: 1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 147,
    name: 'Vitamix B',
    type: 'swine',
    image: '/images/swine/VitamixB.jpg',
    composition:
      '/1 ml: Vitamin B1 (3a820) 4.00 mg, Vitamin B2 6.00 mg, Niacinamide (3a315) 30.00 mg, Vitamin B6 (3a831) 3.00 mg, Vitamin B12 0.015 mg, d-panthenol (3a842) 8.6 mg, Biotin (3a880) 0.03 mg, Folic acid (3a316) 0.5 mg',
    indications_for_use:
      'A complex vitamin B product which contains all essential group B vitamins. It increases the general resistance and stress tolerance of animals. It can be used favourably in cases of deficient vitamin synthesis following antibiotic therapy. By its use, vitamin B deficiency manifesting itself in decreased production and impaired appetite can be corrected.',
    packaging: '1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 148,
    name: 'Vitapol Antitox',
    type: 'swine',
    image: '/images/swine/VitapolAntitox.jpg',
    composition:
      'Humic and fulvic acids, yeast cell wall extract, modified clay components',
    indications_for_use:
      'To neutralize the impact of mycotoxins without the drawbacks of conventional toxinbinders. VitaTox is unique on the market, a multi-component, wide spectrum feed additive, which provides an effective neutralisation of the damage caused by the mycotoxins. It eliminates toxic heavy metals from the body, detoxifyes and strengthens the immune system. It is in an easy to mix powder form, for mixing to feeds. It is suggested to mix to the feed before granulating. It can be mixed in one round with all medicals, vitamins and other feed supplements. Its active ingredients are not degraded by heat or pressure, and are stable.',
    packaging: 'in 25 kg paper bag',
    withdrawal_period: 'None',
    dosage:
      'depending on the toxin contamination, its dosage is from 2-4 kg/1000 kg of feed.',
  },
  {
    id: 149,
    name: 'Vitapol Antitox Pulv.',
    type: 'swine',
    image: '/images/swine/VitapolAntitoxPulv..jpg',
    composition:
      '1m558 modified bentonite, Yeast cell wall extract, Organic mineral with high humic acid content',
    indications_for_use:
      'Functional group: substances for reduction of the contamination of feed by mycotoxins',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      'Starter feed: 2 kg/ton, Grower-finisher feed: 1 kg/ton, Feeds heavily contaminated with mycotoxins: 2-4 kg/ton, The simultaneous oral use with macrolides and with robenidine shall be avoided',
  },
  {
    id: 150,
    name: 'Vitapol Liquid',
    type: 'swine',
    image: '/images/swine/VitapolLiquid.jpg',
    composition: 'humic and fulvic acids',
    indications_for_use:
      'VITAPOL® Liquid is a natural mineral feed for livestock, which supports immunesystem and production of animals. It is easy to be used in drinking water dispensing systems. The main active ingredients are humic and fulvic acids derived from the natural humification process of plant materials. It contains minerals and microelements in humic acid-complex form.',
    packaging: '100 ml, 1 – 5 – 10 – 20 liter',
    withdrawal_period: 'None',
    dosage:
      '1-2 l per 1000 liters of drinking water, depending on the immune status of the animals.',
  },
  {
    id: 151,
    name: 'Vitapol Pulvis',
    type: 'swine',
    image: '/images/swine/VitapolPulvis.jpg',
    composition:
      'Contains natural materials only. Binds toxicants and chemical residues. Enhances stress endurance. Its use results in a faster weigth gain and in a healthier stock. Decreases mortality.',
    indications_for_use:
      'Vitapol® mineral feed exclusively contains natural raw materials, natural acids, minerals and micro elements. It contains minerals and micronutrients in an organic humic acid complex form, so they are easily absorbed in the digestive tract of animals, thus promoting the digestibility of feed and a better feed consumption. Humic acids as natural agents, are possible alternatives of antibiotics and chemotherapeutic preparations in many ways. Thanks to its unique composition Vitapol® has a positive effect on every species, it strengthens the immune system, and it helps the profitabilty of farming.',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      'The recommended amount is added mixed with feed. It can also be mixed into foods at home. It does not interact with other active ingredients, is sterilizable and stable. Sows: 0.5% continuously mixed from the choice into the sow diet. Newborn piglets: 0.5-1.0% mixed or sprinkled on the first solid feed.',
  },
  {
    id: 152,
    name: 'Vitapol suspension',
    type: 'swine',
    image: '/images/swine/Vitapolsuspension.jpg',
    composition:
      'Moisture 85% Humic acids 80 %, Sodium (Na) 0.02 %, Crude ash 4 %, Calcium (Ca) 1.1 % Magnesium (Mg) 0,1 %, Phosphorus (P) 0.02 % Iron (Fe) 600 mg/kg, Manganese (Mn) 18 mg/kg, Zinc (Zn) 8.0 mg/kg, Molybdenum (Mo) 0.6 mg/kg, Cobalt (Co) 0.4 mg/kg',
    indications_for_use:
      'It can be mixed into the milk or milk replacer of calves or poured on the feed. For piglets serve on a tray on the firs week. Strengthens the immune system and prevents diarrhea.',
    packaging: 'in 20 kg plastic bucke',
    withdrawal_period: 'None',
    dosage:
      'Cattle Calf: 1 dl/day/calf mixed into the milk replacer, In case of severe diarrhoea 2 dl/day/calf. Adult cattle: 2 dl/animal/day. Swine Newborn piglets: 1 dl/litter/day. Sows: 1 dl/day/animal during the late pregnancy and lactation.',
  },
  {
    id: 153,
    name: 'Caphos Pro',
    type: 'turkey',
    image: '/images/turkey/CaphosPro.jpg',
    composition:
      'Monocalcium phosphate, magnesium phosphate, magnesium chloride',
    indications_for_use:
      'CaPhos Pro is a liquid feed additive serving for the Ca and P supplementation of farm animals as well as for the prevention and treatment of problems caused by locomotor diseases and bone formation disturbances. It is recommended for use as a treatment course in periods of intensive growth, bone development and production. In pigs and in intensively reared poultry flocks its use is recommended in both the starter and the grower phase. In cattle and small ruminants the use of CaPhos Pro is needed in mineral deficiency conditions and when the balance of the calcium–phosphorus metabolism is disturbed. Such conditions include e.g. milk fever, various limb problems and lameness. The product has beneficial effects also in animals with reproductive disturbances (reduced fertility, disturbances of spermatogenesis) and in those with diseases of the skin and hair coat.In pigs, CaPhos Pro is recommended for the prevention and treatment of peripartal problems such as difficult farrowing and MMA syndrome, for supporting the maturation of gametes (ova and spermatozoa) and for the prevention and treatment of various limb problems and lameness.In laying hens, the product is recommended for use mainly at the peak of egg production and when eggshell formation and other production disturbances occur. It has beneficial effects in the prevention of bone development disorders and joint problems, and it also improves fertility. It prevents heat stress under conditions of high ambient temperature. In turkey flocks, CaPhos Pro has been found to be effective in preventing aortic rupture due to copper deficiency.',
    packaging: '1, 20, 5, l',
    withdrawal_period: 'None',
    dosage:
      'The product is administered dissolved in the drinking water, over a period of 5–10 days.',
  },
  {
    id: 154,
    name: 'Doxyphyl WSP A.U.V.',
    type: 'turkey',
    image: '/images/turkey/DoxyphylWSPA.U.V..jpg',
    composition:
      'doxycycline hyclate 300 mg/g. Treatment of infections caused by Mycplasma spp. and other bacteria sensitive for doxycycline.',
    indications_for_use:
      'Fowl cholera (Pasteurella multocida), Infectious synovitis (Mycoplasma synoviae), Infectious sinusitis of turkey (Mycoplasma gallisepticum), Chronic respiratory disease of chickens (Mycoplasma gallisepticum) The product should be used on the basis of the results of antibiotic susceptibility tests by taking into consideration the guidelines for the use of antimicrobial agents.',
    packaging: '1 kg in polyethylene box closed with a polyethylene screw cap.',
    withdrawal_period: 'None',
    dosage:
      'General dosage is 15 mg doxycycline-hyclate powder / 1 kilogram of bodyweight for the preparation of a solution for internal use for animal treatment over a period of 3–5 days. Calculated amount of product shall be added to drinking water of animals taken in within three hours. Before treatment animals shall be withdrawn from water.',
  },
  {
    id: 155,
    name: 'Illovit E-SE',
    type: 'turkey',
    image: '/images/turkey/IllovitE-SE.jpg',
    composition: 'Vitamin-E. selenium',
    indications_for_use:
      'To support the body’s antioxidant system and to prevent diseases, to prevent and treat reproductive disorders associated with vitamin E deficiency. In animals grazed in selenium-deficient areas, it prevents the development of deficiency symptoms and increases the viability of newborn animals. The synergic effects of the added plant extracts boost the immune system, and fix the efficency of physiological processes. Its biological effect is well established even during viral infections and vaccinations. Vascular protective, detoxifying processes are initiated, which accelerate the regeneration mechanisms.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'Dissolved in drinking water. Do not use without dilution General dose: 0.5 ml / liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days.It is recommended to have the daily amount be drank in 4-6 hours.',
  },
  {
    id: 156,
    name: 'Illovit Hepar',
    type: 'turkey',
    image: '/images/turkey/IllovitHepar.jpg',
    composition:
      'Vitamin-B1, Vitamin-B6, Niacinamide, Choline Chloride, Inositol, L-Carnitine, Amino Acids, salts and analogs thereof: Lysine, DL-Methionine',
    indications_for_use:
      'It is recommended in all cases when the liver is overloaded. In particular: gastrointestinal diseases, feeding defects, suspicion of poisoning, viral infections, in the presence of feed toxins. To help liver function in herds with intensive growth vigor. It is recommended to use it after reaching peak production. The composition increases the detoxification capacity of the liver by administering methyl donors such as methionine or choline chloride. Following the use of antibiotics, the ability of the intestinal flora to synthesize vitamins is significantly reduced, so it is vital to administer B vitamins after treatment. Illovit Hepar helps herd regenerate and maximize production during and after treatment. Due to its composition, it maximizes the growth capacity of young animals. The benefits of the added plant extracts are based on traditional folk use, which has been confirmed by animal health impact studies. The extracts accelerate the body’s natural regeneration processes through their liver-supporting synergistic effect. They strengthen blood vessel walls, improve blood circulation. Gently activates and aids digestive and respiratory functions in animals with reduced production capacity.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      '0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg of body weight for 3-7 days. It is recommended to have the daily amount be drank in 4-6 hours',
  },
  {
    id: 157,
    name: 'Illovit Multivitamin',
    type: 'turkey',
    image: '/images/turkey/IllovitMultivitamin.jpg',
    composition:
      'Vitamin-A, Vitamin-NED3, Vitamin-NEE, Vitamin-K3, Vitamin-B1, Vitamin-B2, Vitamin-B6, Vitamin-B12, Niacinamide, Choline Chloride, Pantothenic acid, Biotin',
    indications_for_use:
      'Combined multivitamin preparation. To maintain and increase growth vigor in the event of colds and weather front effects. It is suitable for vitamin supplementation of pets of all species and ages, for overcoming stress and deficiency diseases. Accelerates post-disease regeneration. The synergistic beneficial effect of the added plant extracts specifically protects and supports the respiratory system. It cleans the airways, helps to remove stuck mucus. It helps in post-disease regeneration, improves feed utilization, and aids digestion efficiency. It supports the circulatory system and the heart.',
    packaging: '1 l, 5 l, 20 l',
    withdrawal_period: 'None',
    dosage:
      'General dose: 0.5 ml / 1 liter of drinking water or 0.5 ml / 10 kg body weight for 3-7 days. It is recommended to have the daily amoun be drank in 4-6 hours.',
  },
  {
    id: 170,
    name: 'Jolovit',
    type: 'turkey',
    image: '/images/turkey/Jolovit.jpg',
    composition:
      'Vitamin A 14.000 I.U., Vitamin d3 1.400 I.U., Vitamin E 10.5 mg, Vitamin K3 0.175 mg, Vitamin B1 1.4 mg, Vitamin B2 2.1 mg, Vitamin B6 1.4 mg, VitaminB12 0.014 mg, d-Panthenol 6.55 mg, Niacinamide 14.0 mg, Biotin 0.035 mg, Colin chloride 140 mg, B-carotene 0,01 mg',
    indications_for_use:
      'A combined multivitamin product to be used for vitamin supplementation in farm animals of any age and species, in order to correct vitamin deficiency caused by stress occurring at the time of placement and feed change. Owing to its composition, Jolovit is suitable for ensuring optimum vitamin supply, which is a precondition of adequate production performance. It reduces the negative effects of stress, improves the viability of young animals and the hatchability of eggs, and accelerates recovery after diseases.',
    packaging: '0.1 l bottle, 1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'see label for the details.',
  },
  {
    id: 171,
    name: 'Klorinvet Granular 161 mg/g medicated premix',
    type: 'turkey',
    image: '/images/turkey/KlorinvetGranular161mggmedicatedpremix.jpg',
    composition:
      'Chlortetracycline hydrochloride (equals 150,0 g chlortetracycline) 161 g / kg. Treatment of infections of turkey and swine caused by microorganisms sensitive for chlortetracycline.',
    indications_for_use:
      'For oral use, mixed to the feed Average dose: Swine: 20 mg/bwkg per day for 5-7 days Turkeys: for treatment 30 mg/bwkg per day for 7 days for herd treatment 10 mg/bwkg per day for 21 days',
    packaging: '1 kg in PE container; 5, 20, 25 kg in triplex foil sack',
    withdrawal_period: 'Turkeys: 3 days Swine. 10 days',
    dosage:
      '500 g/cow/day in the fresh and high milk yield group of cows. For high genetical value herds we recommend it in the medium milk yield group, too.',
  },
  {
    id: 158,
    name: 'Klorinvet Granular 500 mg/g medicated premix',
    type: 'turkey',
    image: '/images/turkey/KlorinvetGranular500mggmedicatedpremix.jpg',
    composition:
      'Chlortetracycline hydrochloride 500 g/kg (equals 465 g chlortetracycline)',
    indications_for_use:
      'Turkey: Infections caused by Pasteurella multocida (MIC 0,125-2) and Clostridium perfringens (MIC 0,0625-4). Swine: infections caused by Pasteurella multocida (MIC 0,125-1).',
    packaging: '25 kg in triplex foil sack',
    withdrawal_period: 'Turkeys at dose of 30 mg/bwkg: 3 days Swine 15 days',
    dosage:
      'For oral use, mixed to the feed Average dose: Swine: 20 mg/bwkg per day for 5-7 days Turkeys: 30 mg/bwkg per day for 7 days',
  },
  {
    id: 159,
    name: 'Norovit Amino Forte',
    type: 'turkey',
    image: '/images/turkey/NorovitAminoForte.jpg',
    composition:
      '/ ml: Vitamin A (3a672b) 40,000, IU Vitamin D3 (E671) 5,000, IU Vitamin E (3a700) 20.0 mg, Vitamin K3 (3a710) 2.0 mg, Vitamin B1 (3a820) 2.0 mg, Vitamin B2 0.2 mg, Vitamin B6 (3a831) 2.0 mg, Niacinamide (3a315) 14.0 mg, Vitamin C (E301) 5.0 mg, DL-methionine 8.2 mg, Lysine HCl 11.0 mg, Tryptophan 1.8 mg, Threonine 5.0 mg',
    indications_for_use:
      'A combination of essential amino acids and vitamins. It enhances muscle building and weight gain. Its use is recommended after placement to achieve higher constitutional strength and to pre-vent the adverse effects of stress due to movement and vaccination of the animals.',
    packaging: '1 l bottle, 5 l and 20 l plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 160,
    name: 'Norovit Hepar',
    type: 'turkey',
    image: '/images/turkey/NorovitHepar.jpg',
    composition:
      '/ ml: Vitamin B1 (3a820) 5.0 mg, Vitamin B2 4.0 mg, Vitamin B6 (3a831) 4.0 mg, D-panthenol (3a842) 20.0 mg, Biotin (3a880) 0.015 mg, Vitamin K3 (3a710) 2.0 mg, Niacinamid (3a315) 40.0 mg, Inositol 5.0 mg, Choline chloride (3a890) 200.0 mg, Lysine HCl 20.0 mg, DL-methionine 10.0 mg. Like choline, inositol has a ‘fat burning’ effect, it prevents the deposition of fats in the liver and accelerates their excretion. Vitamins of the B group provide energy for the body by supporting the metabolism of carbohydrates, proteins and fats.',
    indications_for_use:
      'To support liver function, especially in flocks and herds of intensive growth rate. The product enhances the detoxifying capacity of the liver by introducing into the body methyl donors such as methionine or choline chloride.',
    packaging: '1 liter and 5 liter plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 161,
    name: 'Norovit Thermo',
    type: 'turkey',
    image: '/images/turkey/NorovitThermo.jpg',
    composition:
      '/ ml: L-carnitine (3a910) 30.0 mg, Vitamin E (3a700) 40.0 mg, Vitamin B1 (3a820) 5.0 mg, Niacinamide (3a315) 10.0 mg, Vitamin B6 (3a831) 4.0 mg, Vitamin B12 0.02 mg, DL-methionine 10.0 mg, Lysine HCl 10.0 mg, Sodium selenite (E8) 0.40 mg, Sorbitol 160.0 mg',
    indications_for_use:
      'A product suitable for preventing the adverse effects of heat stress. Its constituents support the thermoregulation of animals. Heat stress damages the antioxidant system, and an elevated vitamin E and selenium level is needed to counter this effect. Heat shock results in a higher water and water-soluble vitamin loss for the organism, and there-fore the supplementation of vitamins of group B is absolutely necessary. In addition, vitamins B support the animals’ metabolism. Niacinamide participates in almost all enzymatic processes as a transmitter, as a signalling compound or as an energy-supplying compound.',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 162,
    name: 'Phylamic',
    type: 'turkey',
    image: '/images/turkey/Phylamic.jpg',
    composition:
      '/1 ml: Mn (E5) 12.0 mg, Zn (E6) 8.0 mg, Cu (E4) 1.6 mg, Se (E8) 0.03 mg',
    indications_for_use:
      'A microelement complex that supports enzyme reactions in the body and cartilage development in animals of a high growth rate. Its use is recommended after placement and in case of locomotor problems (extremital abnormalities caused by microelement deficiencies in poultry and turkeys, thickening of joints, hyperkeratosis, anaemia, developmental disturbances). Regular use of the product increases egg production, fertility and hatchability. When using the product, the microelement content of the diets fed to the birds must be taken into consideration.',
    packaging:
      '100 ml and 1 liter, in plastic bottle; 5 l and 20 l in plastic container.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 163,
    name: 'Tetrakolin',
    type: 'turkey',
    image: '/images/turkey/Tetrakolin.jpg',
    composition:
      '/1 ml: Vitamin B2 5.00 mg, Niacinamide (3a315) 50.00 mg, Vitamin B6 (3a831) 8.00 mg, Vitamin B12 0.05 mg, d-panthenol (3a842) 86.00 mg, Biotin (3a880) 0.10 mg, Choline chloride (3a890) 21.75 mg',
    indications_for_use:
      'A complex vitamin B product to facilitate utilisation of the young-age growth capacity of farm animals of high growth rate. A complex vitamin B product to facilitate utilisation of the young-age growth capacity of farm animals of high growth rate. The product increases the resistance of the liver to hepato-toxic effects. Through its hepatoprotective effect, the product supports the prevention and treatment of ketosis in dairy cows.',
    packaging: '1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 164,
    name: 'Tetraselenium400-E',
    type: 'turkey',
    image: '/images/turkey/Tetrakolin.jpg',
    composition:
      '/1 ml: Vitamin E (3a700) 80 mg, Sodium selenite (E8) 400 µg. The product is indicated for supporting the antioxidant system of animals, for increasing their stress tolerance and for the prevention and treatment of reproductive disturbances associated with vitamin E deficiency. Selenium, which is a constituent of an enzyme playing a key role in the antioxidant system, and vitamin E, which has antioxidant properties, protect cells from the harmful effects of peroxides.',
    indications_for_use:
      'Their deficiency primarily leads to degenerative changes of the myocardium, the skeletal muscles and the wall of blood vessels (skeletal muscle degeneration, encephalomalacia, exudative diathesis). Its use is recommended in the period of seualx maturation and fertilisation, and for the treatment of reproductive disturbances.',
    packaging: 'in containers 1 and 5 l.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 165,
    name: 'Tetravit ADE',
    type: 'turkey',
    image: '/images/turkey/TetravitADE.jpg',
    composition:
      '/1 ml: Nutritional additives – Vitamins and provitamins: Vitamin A (3a672b) 72.000 IU, Vitamin d3 (E671) 36.000 IU, Vitamin E (3a700) 72 mg. Owing to its high concentrations of vitamins A-, d3 – and E, the product strengthens the resistance of animals to infectious diseases. It supports normal feathering, the healthy development of bones and normal cardiac function. The product is excellently suitable for enhancing the general re-sistance of animals.',
    indications_for_use:
      'In growing animals, it is recommended for use once or twice monthly as a treatment course. When administered to pregnant female animals in the last third of gestation, the product increases the general resistance of the newborn animals, improves the regeneration of postparturient female animals, supports reconception, and shortens the service period. When administered to laying flocks once or twice a month as a treatment course, the product increases eggshell strength and in breeder flocks it improves fertility and hatchability.',
    packaging: '1 l plastic bottle. Packaging: 1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 166,
    name: 'Vitamix B',
    type: 'turkey',
    image: '/images/turkey/VitamixB.jpg',
    composition:
      '/1 ml: Nutritional additives – Vitamins and provitamins: Vitamin A (3a672b) 72.000 IU, Vitamin d3 (E671) 36.000 IU, Vitamin E (3a700) 72 mg. Owing to its high concentrations of vitamins A-, d3 – and E, the product strengthens the resistance of animals to infectious diseases. It supports normal feathering, the healthy development of bones and normal cardiac function. The product is excellently suitable for enhancing the general re-sistance of animals.',
    indications_for_use:
      'In growing animals, it is recommended for use once or twice monthly as a treatment course. When administered to pregnant female animals in the last third of gestation, the product increases the general resistance of the newborn animals, improves the regeneration of postparturient female animals, supports reconception, and shortens the service period. When administered to laying flocks once or twice a month as a treatment course, the product increases eggshell strength and in breeder flocks it improves fertility and hatchability.',
    packaging: '1 l plastic bottle. Packaging: 1 l plastic bottle.',
    withdrawal_period: 'None',
    dosage: 'for details see the label.',
  },
  {
    id: 167,
    name: 'Vitapol Antitox',
    type: 'turkey',
    image: '/images/turkey/VitapolAntitox.jpg',
    composition:
      'Humic and fulvic acids, yeast cell wall extract, modified clay components',
    indications_for_use:
      'To neutralize the impact of mycotoxins without the drawbacks of conventional toxinbinders. VitaTox is unique on the market, a multi-component, wide spectrum feed additive, which provides an effective neutralisation of the damage caused by the mycotoxins. It eliminates toxic heavy metals from the body, detoxifyes and strengthens the immune system. It is in an easy to mix powder form, for mixing to feeds. It is suggested to mix to the feed before granulating. It can be mixed in one round with all medicals, vitamins and other feed supplements. Its active ingredients are not degraded by heat or pressure, and are stable.',
    packaging: 'in 25 kg paper bag',
    withdrawal_period: 'None',
    dosage:
      'depending on the toxin contamination, its dosage is from 2-4 kg/1000 kg of feed.',
  },
  {
    id: 168,
    name: 'Vitapol Liquid',
    type: 'turkey',
    image: '/images/turkey/VitapolLiquid.jpg',
    composition: 'humic and fulvic acids',
    indications_for_use:
      'VITAPOL® Liquid is a natural mineral feed for livestock, which supports immunesystem and production of animals. It is easy to be used in drinking water dispensing systems. The main active ingredients are humic and fulvic acids derived from the natural humification process of plant materials. It contains minerals and microelements in humic acid-complex form.',
    packaging: '100 ml, 1 – 5 – 10 – 20 liter',
    withdrawal_period: 'None',
    dosage:
      '1-2 l per 1000 liters of drinking water, depending on the immune status of the animals.',
  },
  {
    id: 169,
    name: 'Vitapol Pulvis',
    type: 'turkey',
    image: '/images/turkey/VitapolPulvis.jpg',
    composition:
      'Contains natural materials only. Binds toxicants and chemical residues. Enhances stress endurance. Its use results in a faster weigth gain and in a healthier stock. Decreases mortality.',
    indications_for_use:
      'Vitapol® mineral feed exclusively contains natural raw materials, natural acids, minerals and micro elements. It contains minerals and micronutrients in an organic humic acid complex form, so they are easily absorbed in the digestive tract of animals, thus promoting the digestibility of feed and a better feed consumption. Humic acids as natural agents, are possible alternatives of antibiotics and chemotherapeutic preparations in many ways. Thanks to its unique composition Vitapol® has a positive effect on every species, it strengthens the immune system, and it helps the profitabilty of farming.',
    packaging: '25 kg',
    withdrawal_period: 'None',
    dosage:
      'The recommended amount is added mixed with feed. It can also be mixed into foods at home. It does not interact with other active ingredients, is sterilizable and stable. Sows: 0.5% continuously mixed from the choice into the sow diet. Newborn piglets: 0.5-1.0% mixed or sprinkled on the first solid feed.',
  },
];

export default species;
