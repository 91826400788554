import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import MuiTheme from './util/theme';
// components
import Header from './components/Header';
// pages
import HomePage from './pages/HomePage';
import SpeciesPage from './pages/SpeciesPage';
import ProductsPage from './pages/ProductsPage';
import BirdsPage from './pages/BirdsPage';
import SingleProductPage from './pages/SingleProductPage';
import ContactUs from './pages/ContactUs';

const theme = createMuiTheme(MuiTheme);

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <main className='App'>
        <Router>
          <Header />
          <div>
            <Switch>
              <Route path='/' component={HomePage} exact />
              <Route path='/species' component={SpeciesPage} />
              <Route path='/products' component={ProductsPage} />
              <Route path='/generalhealth' component={BirdsPage} />
              <Route path='/contactus' component={ContactUs} />
              <Route path='/singlespecie/:id' component={SingleProductPage} />
            </Switch>
          </div>
        </Router>
      </main>
    </MuiThemeProvider>
  );
}

export default App;
