import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: 300,
    background: 'rgba(0,0,0,0.5)',
    margin: '20px',
  },
  media: {
    height: 300,
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: '#fff',
  },
  desc: {
    fontFamily: 'Nunito',
    fontSize: '1.1rem',
    color: '#ddd',
  },
  buttonText: {
    fontWeight: 'bold',
  },
});

const ContactUsCard = ({ data }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={data.image}
        title={data.name}
      />
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          gutterBottom
          variant='h5'
          component='h2'
          className={classes.title}
        >
          {data.name}
        </Typography>

        <Typography
          variant='body2'
          color='textSecondary'
          component='p'
          className={classes.desc}
        >
          {data.position}
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
          component='p'
          className={classes.desc}
        >
          {data.title}
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
          component='p'
          className={classes.desc}
        >
          {data.phoneNumber}
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
          component='p'
          className={classes.desc}
        >
          {data.email}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ContactUsCard;
