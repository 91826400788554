const supplements = [
  {
    id: 246,
    name: 'HVA ACIDPRE',
    line: 'ACIDIFIERS LINE',
    image: '/images/supplements/supplement.png',
    indications:
      '• Prevents the proliferation of pathogenic bacteria ( Salmonella, E. Coli, Campylobacter).• Prevents the contamination of healthy ingredients when processed in contaminated plants/structures. • Behaves as acidifiers in the gastrointestinal tract. • Stimulates the animal’s welfare through a trophic actions on the intestinal villus. • Stimulates the drinking water intake.',
    additives_per_liter: 'Acetic Acid, Citric Acid, Butyric Acid.',
    excipients: 'Check label',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: 1 liter/2000-3000 liters of drinking water.',
    packaging: '• Bottle: 1 liter • Tanks: 5-10-25-200 and 1.000 liters',
  },
  {
    id: 247,
    name: 'HVA PLUS',
    line: 'ACIDIFIERS LINE',
    image: '/images/supplements/supplement.png',
    indications:
      '• Preventive against pathogens proliferation (Salmonella, Coli, Campylobacter, etc.); • Preventive of the secondary contamination of healthy ingredients; • Able to decrease the intestinal pH; • Formulated to be not corrosive for the skin and the metals.',
    additives_per_liter:
      'Citric acid, Formic acid, Ammonium Formate, Propionic Acid.',
    excipients:
      'Water, Monopropylene Glycol, Glucose syrup, Glycerol up to 1 liter.',
    recommended_dosage:
      'To be administered with a maximum inclusion of 1% or according to the following dosages: - Broiler and Layers: 2-4 liters per 1.000 liters of drinking water. - Turkeys: 4-6 liters per 1.000 liters of drinking water. Close the bottle after the usage. Keep in a fresh and dry place, away from direct sunlight or heat sources. Do not freeze.',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25-200 and 1.000 liters',
  },
  {
    id: 248,
    name: 'HVA AD3E',
    line: 'VITAMINS LINE',
    image: '/images/supplements/supplement.png',
    indications:
      '• To be use in all cases it is necessary to add fat-soluble vitamins. • Anti-stress supplement. • Increases growth. • Better egg production and stronger shells.',
    additives_per_liter:
      'Vitamin A 100.000.000 UI, Vitamin D3 20.000.000 UI, Vitamin. E 20.000 mg.',
    excipients: 'Monoprpylene glycol, Water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: • Poultry: 1 ml/10 liters of drinking water for 3-5 consecutive days. • Sheep and goats: 0.2-0.5 ml/head/day for 5-10 consecutive days. • Cattle and camel: 1-2 ml/head/day for 7-14 consecutive days. Shake well before to use. Always close the packaging after use. To stock in a dry, cool and clean environment, away from direct sunlight. Do not freeze..',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25-200 liters',
  },
  {
    id: 249,
    name: 'HVA Antiox',
    line: 'SPECIALITIES LINE',
    image: '/images/supplements/supplement.png',
    indications:
      '• Kidney disorder due to urolithiasis. • kidney form of infectious after use of sulphonamides or antibiotic treatment, in cases of mycotoxicosis. • Against stress due to temperature changes. • After medication, vaccination, peak, laying period, changing feed and during transportation.',
    additives_per_liter:
      'Niacinamide, Zinc (zinc chelate of glycine, hydrate) 5.000 mg, Glicyne, Menthol, Arctium (Burdock) dry extract, Taraxacum (Dandelion) dry extract',
    excipients:
      'Sodium chloride, Potassium chloride, Magnesium chloride, Monopropyle glycol, water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: Poultry: 1 liter / 1.000 liters of drinking water. Sheep and goats: 5-10 ml/head/day. Cattle and camel: 20-50 ml/head/day. Shake well before to use. Always close the packaging after use. To stock in a dry, cool and clean environment, away from direct sunlight. Do not freeze.',
    packaging: 'Bottle: 1 liter. Tanks: 5-10-25-200 liters',
  },
  {
    id: 250,
    name: 'HVA B',
    line: 'VITAMINS LINE',
    image: '/images/supplements/supplement.png',
    indications:
      'Dietary supplements. To improve reproductive performance and animal fertility.',
    additives_per_liter:
      'Vitamin A 10.000.000 UI, Vitamin D3 200.000 UI, Vitamin E 20.500 mg, Vitamin K3 1.000 mg, Vitamin B1 590 mg, Vitamin B2 630 mg, Vitamin B6 875 mg, Vitamin B12 5 mg, Folic Acid 810 mg, Beta-Carotene 520 mg.',
    excipients: 'Sorbitol, water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: • Poultry: 1-2 liter / 4.000 liters of drinking water. • Sheep and goats: 1-2ml/head/day. • Cattle and camel: 6-10ml /head/day. Shake well before to use. Always close the packaging after use.Tostock in a dry, cool and clean environment, away from direct sunlight. Do not freeze.',
    packaging: '• Bottle: 1liter. • Tanks: 5-10-25-200 liters',
  },
  {
    id: 251,
    name: 'HVA BCY',
    line: 'SPECIALITIES LINE',
    image: '/images/supplements/supplement.png',
    indications:
      '• Inulin contributes to the development of the positive bacterial population, in particular bifidobacteria. This process allows the control and the reduction of pathogenic bacteria such as E. Choli, Salmonella and Clostridia. • improves absorption of minerals (Ca,Mg, etc.) in the large intestine. • stimulates the immune system. • Liver protector. • Choeleretic',
    additives_per_liter:
      'Yeast extract from Saccharomyces Cerevisiae, Extract from chicory pulp (Inuline), Plant extract (Boldo)',
    excipients: 'Sorbitol, Monopropylen glycol , water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: Poultry: 1 liter / 1.000 liters of drinking water Sheep and goats: 5-10 ml/head/day Cattle and camel: 20-50 ml/head/day. Shake well before to use. Always close the packaging after use. To stock in a dry, cool and clean environment, away from direct sunlight. Do not freeze.',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25-200 liters',
  },
  {
    id: 252,
    name: 'HVA FAP',
    line: 'ACIDIFIERS LINE',
    image: '/images/supplements/supplement.png',
    indications:
      '• Prevents the proliferation of pathogenic bacteria such as Salmonella, E.Coli and Campylobacter in feed and machinery. • Prevents the secondary contamination of the feed following heat treatments. • Acts as acidifier of gastro-intestinal tract of animals. • It is not corrosive for the skin and the metals. • Reduces the pollution from nitrogen and phosphates. • Easy to use and dose. • It is not dangerous',
    additives_per_liter: 'Formic acid , Ammonium formiate, Propionic acid',
    excipients: 'Monopropylene Glycol, Water up to 1 liter',
    recommended_dosage:
      'Dose depends on contamination concentration of water, type of water and state of feed. Maintenance Dose: 1 litre / 2000 - 3000 litres water for 4-5 days. Curative Dose: 1 litre / 1000 litres water to maintain water pH up to 4.',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25-200 and 1.000 liters',
  },
  {
    id: 253,
    name: 'HVA Herbs',
    line: 'SPECIALITIES LINE',
    image: '/images/supplements/supplement.png',
    indications: 'anti-inflammatory, antidiarrheal, anti-pyretic action',
    additives_per_liter:
      'Echinacea Angustifoglia fluid extract, Boswellia Serrata dry extract, Spirea Ulmaria dry extract, Salix (white willow bark) dry extract',
    excipients: 'Monopropylen glycol , water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: Poultry: 1 liter / 1.000 liters of drinking water. Sheep and goats: 5-10 ml/head/day. Cattle and camel: 20-50 ml/head/day. Shake well before to use. Always close the packaging after use. To stock in a dry, cool and clean environment, away from direct sunlight. Do not freeze.',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25-200 liters',
  },
  {
    id: 254,
    name: 'HVA ME',
    line: 'SPECIALITIES LINE',
    image: '/images/supplements/supplement.png',
    indications:
      'Prevents and relieves all type of stress conditions especially heat stress, overcrowding, pre and post vaccination. Improves immunity-system. Anti-inflammatory',
    additives_per_liter:
      'Vitamin E, Inositol, Olive leaves dry extract, vitis vinifera grape extract',
    excipients: 'Monopropyle glycol , water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: Poultry: 1 liter / 1.000 liters of drinking water. Sheep and goats: 5-10 ml/head/day. Cattle and camel: 20-50 ml/head/day.',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25-200 and 1.000 liters',
  },
  {
    id: 255,
    name: 'HVA Minerals',
    line: 'SPECIALITIES LINE',
    image: '/images/supplements/supplement.png',
    indications:
      '• Improve the immunity system. • Improve the fertility. • Improve the animal metabolism and the enzymatic activity. • Improve the mineral metabolism. • Help animals under stress (climatic changes, heat stress, illness, birth)',
    additives_per_liter:
      'Iron chelate of glycine, hydrate (E1) 43.000 mg (Iron 10.000 mg), Copper chelate of glycine, hydrate 9.750 mg (Copper 2500 mg), Zinc chelate of glycine, hydrate (E6) 57.300 mg (Zinc 15.000 mg), Manganese chelate of glycine, hydrate (E5) 65.250 mg (Manganese 15.000 mg).',
    excipients: 'Water up to 1 liter',
    recommended_dosage:
      '• Poultry: 2-4 liters per 1.000 liters of drinking water. • Hens: 2-4 liters per 1.000 liters of drinking water before and during the mute • Dairy cow: 20-30 ml/head/day one month before birth and 15 days after birth • Beef cattle: 15-25 ml/head/day during the weaning and when the animals arrive in the farm. 45 days before slaughter The product contains copper, verify that the presence in other sources doesn’t exceed the limits for the single species. Do not use in sheep and goats. Shake before use. Close the bottle after the usage. Keep in a fresh and dry place, away from direct sunlight or heat sources. Do not freeze.',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25 and 200 liters',
  },
  {
    id: 256,
    name: 'HVA Multi',
    line: 'VITAMINS LINE',
    image: '/images/supplements/supplement.png',
    indications:
      'Dietary supplements. In phase of reproduction, heat stress, growth and fattening',
    additives_per_liter:
      'Vitamin A 15.000.000 UI, Vitamina D3 400.000 UI, Vitamin E 8.000 mg, Vitamina K3 1.000 mg, Vitamin B1 1.800 mg, Vitamin B2 1.300 mg, Vitamin B6 800 mg, Vitamin B12 12 mg, Niacinamide 12.500 mg, Betaine 10.000 mg, Calcium D-pantothenate 5.000 mg, Folic acid 250 mg.',
    excipients:
      'Brewery yeast from hydrolyzed Saccharomyces Cerevisiae, water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: • Poultry: 1 liter / 4.000 liters of drinking water • Sheep and goats: 1 ml/head/day • Cattle and camel: 4-8 ml /head/day Shake well before to use. Always close the packaging after use. To stock in a dry, cool and clean environment, away from direct sunlight. Do not freeze.',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25-200 liters',
  },
  {
    id: 257,
    name: 'HVA Recover',
    line: 'SPECIALITIES LINE',
    image: '/images/supplements/supplement.png',
    indications: 'Metabolic booster',
    additives_per_liter:
      'Vitamin B1, Vitamin B2, Vitamin B6, L-Carnitine base, Extract from Guaranà, Extract from Ginseng',
    excipients: 'Monopropylen glycol , water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: Poultry: 1 liter / 1.000 liters of drinking water Sheep and goats: 5-10 ml/head/day Cattle and camel: 20-50 ml/head/day Shake well before to use. Always close the packaging after use. To stock in a dry, cool and clean environment, away from direct sunlight. Do not freeze',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25-200 liters',
  },
  {
    id: 258,
    name: 'HVA RESP .',
    line: 'SPECIALITIES LINE',
    image: '/images/supplements/supplement.png',
    indications:
      '• Improves respiration and air passage. • Reduces Mucus accumulation and discharge out of respiratory cavities. • Reduces the negative effects of causing disease as well as quick recovery. • Reduces stress and loss of valuable feed intake. • Improves resistance to all respiratory infections. • Reduces the negative effect and stress due to live vaccination. • Improves survivability and reduces mortality.',
    additives_per_liter:
      'Carvacrol,Eucalyptol, Menthol, Cinnami Aldehyde, Thymol, Lauric Acid',
    excipients: 'Monopropyle Glycol up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: • Broilers and Turkeys in stress condition: 200 ml /1.000 liters of drinking water per 5-6 days • Prevention for Broilers: 100-150 ml /1.000 liters of drinking water per 4-5 days • Prevention for Layers: 80-100 ml / 1.000 liters of drinking water twice daily Shake well before to use. Always close the packaging after use. To stock in a dry, cool and clean environment, away from direct sunlight. Do not freeze.',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25-200 and 1.000 liters',
  },
  {
    id: 259,
    name: 'HVA Sel-E',
    line: 'VITAMINS LINE',
    image: '/images/supplements/supplement.png',
    indications: 'To support metabolism antioxidant of animals',
    additives_per_liter:
      'Vitamin. E 200.000 mg, Selenium (Sodium selenite) 200 mg',
    excipients: 'Monoprpylene glycol, Water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: • Poultry: 100 ml/1.000 liters of drinking water • Sheep and goats: 2-6ml/head/day • Cattle and camel: 10-15 ml /head/day Shake well before to use. Always close the packaging after use.Tostock in a dry, cool and clean environment, away from direct sunlight. Do not freeze.',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25-200 liters',
  },
  {
    id: 260,
    name: 'HVA-C',
    line: 'VITAMINS LINE',
    image: '/images/supplements/supplement.png',
    indications:
      '• Reduces coliform present in water distribution line • Prevents the formation of biofilm and removes it • Increases water intake • Improves digestion processes • Reduces heat stress • Higher available cupper • Improve animal welfare',
    additives_per_liter:
      'Citric Acid, copper sulfhate (copper 2.500 mg), Citrus extract (C. paradisi, C. aurantium, C. bergamia, C. sinensis , C. reticulata )',
    excipients: 'Sodium chloride, water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: Poultry: 1 liter / 1.000 liters of drinking water Cattle and camel: 20-50 ml/head/day Shake well before to use. Always close the packaging after use. To stock in a dry, cool and clean environment, away from direct sunlight. Do not freeze. The product contains copper, verify that the presence in other sources doesn’t exceed the limits for the single species. Do not use in sheep and goats.',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25-200 liters',
  },
  {
    id: 261,
    name: 'HVA-D3',
    line: 'VITAMINS LINE',
    image: '/images/supplements/supplement.png',
    indications:
      '• Improve milk yield in cattle. • Formation of strong bones in growing animals. Improves the hardness of the eggshell • Nutritional deficiency due to calcium in • Hypocalcaemia during pregnancy • Before and after Partum',
    additives_per_liter: 'Vitamin D3 (E671) 200.000 UI.',
    excipients:
      'Calcium gluconate, Glucose, Sorbitol, Deionized water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures to the other components of the diets with the following dosages • Calves: 50-100 ml / head / day • Buffalos: 200-300 ml / head / day (from 1 day before and for 5-7 days post-partum). • Milking cows: 500 ml / head / day (from 1 day before and for 2-3 days post-partum). • Equine: 100-150 ml / head / day • Small ruminants older than 3 months: 50- 80ml/ head / day • Broiler and layers: 1,5-2 liters per 1.000 liters of drinking water. Do not exceed the limit of Vitamin D3 content considering all the integration coming from other constituents of the diet. Shake before use. Close the bottle after the usage. Keep in a fresh and dry place, away from direct sunlight or heat sources. Do not freeze.',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25 and 200 liters',
  },
  {
    id: 262,
    name: 'LIVER EXTRA',
    line: 'SPECIALITIES LINE',
    image: '/images/supplements/supplement.png',
    indications: 'Liver protection immune-stimulation action',
    additives_per_liter:
      'L-Carnitine 118.500 mg, Betaine 20.500 mg, Cynara Scolymus L',
    excipients: 'Glucose. water up to 1 liter.',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures to the other components of the diets with the following dosages: Poultry: 400-500 ml / 1.000 liters of drinking water. Shake before use. Close the bottle after the usage. Keep in a fresh and dry place, away from direct sunlight or heat sources. Do not freeze.',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25 and 200 liters',
  },
  {
    id: 263,
    name: 'LIV-PROTECT',
    line: 'SPECIALITIES LINE',
    image: '/images/supplements/supplement.png',
    indications:
      'Potent hepatoprotective properties and recovery capacity of damaged liver tissue',
    additives_per_liter:
      'Artichoke extract, Extract from Cardus Marianus (Silybum marianum), Betaine',
    excipients: 'Monopropyle glycol , water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures with the following dosages: Poultry: 1 liter / 1.000 liters of drinking water Sheep and goats: 5-10 ml/head/day Cattle and camel: 20-50 ml/head/day Shake well before to use. Always close the packaging after use. To stock in a dry, cool and clean environment, away from direct sunlight. Do not freeze.',
    packaging: 'Bottle: 1 liter. Tanks: 5-10-25-200 liters',
  },
  {
    id: 264,
    name: 'SuperPlus',
    line: 'SPECIALITIES LINE',
    image: '/images/supplements/supplement.png',
    indications:
      'Improves energy metabolism Improves the electrolyte balance It has anti-inflammatory properties It is condro-protector It protects from the action of free radicals',
    additives_per_liter:
      'Niacinamide 15.000 mg, Zinc chelate of glycine, hydrate ( Zinc 5.000 mg), L-Glycine 70.000 mg, Acetic Acid',
    excipients:
      'Monopropylene glycol, Sodium chloride, Potassium chloride, Magnesium sulphate Water up to 1 liter',
    recommended_dosage:
      'Mix carefully and according to the good manufacturing procedures to the other components of the diets with the following dosages: 1 - 1,5 liters / 1000 liter of drinking water Do not exceed the limits of Zinc with the use of other ingredient. Close the bottle after the usage. Keep in a fresh and dry place, away from direct sunlight or heat sources. Do not freeze',
    packaging: '• Bottle: 1 liter. • Tanks: 5-10-25 and 200 liters',
  },
];

export default supplements;
