// app theme Material UI/
const theme = {
  palette: {
    primary: {
      light: '#33c9dc',
      main: '#E02A23',
      dark: '#008394',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff6333',
      main: '#ff3d00',
      dark: '#b22a00',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
  },
};

export default theme;
