import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ContactUsCard from './ContactUsCard';
import ContactUsData from '../data/contactUsData';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '3.3rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const ContactUsContainer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ContactUsCard data={ContactUsData[0]} />
      <ContactUsCard data={ContactUsData[1]} />
    </div>
  );
};

export default ContactUsContainer;
