import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    width: 350,
    background: 'rgba(0,0,0,0.5)',
    margin: '20px',
  },
  media: {
    height: 400,
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: '#fff',
  },
  desc: {
    fontFamily: 'Nunito',
    fontSize: '1.1rem',
    color: '#ddd',
  },
  buttonText: {
    fontWeight: 'bold',
  },
});

const LandingPageCards = ({ data }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={data.imageUrl}
        title={data.title}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant='h5'
          component='h2'
          className={classes.title}
        >
          {data.title}
        </Typography>
        <Button
          className={classes.buttonText}
          size='medium'
          variant='contained'
          color='primary'
          component={Link}
          to={data.to}
        >
          Learn More
        </Button>
      </CardContent>
    </Card>
  );
};

export default LandingPageCards;
