import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import allProducts from '../data/products';
import ProductCard from '../components/ProductCard';
import Pagination from '../components/PaginationCom';
import {
  Container,
  Divider,
  IconButton,
  Paper,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '7rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'inherit',
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1rem',
  },

  leftFlex: {
    flex: '1',
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toggleButton: {
    width: '20vw',
  },
  searchContainer: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function ProductsPage() {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const getData = async () => {
      setProducts(allProducts);
    };
    getData();
  }, []);

  // Get current Products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (searchText) => {
    setSearch(searchText);
    if (!searchText) {
      setProducts(allProducts);
    } else {
      var searchProducts = allProducts.filter((x) =>
        x.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setProducts(searchProducts);
    }
  };

  const submitClear = () => {
    setSearch('');
    setProducts(allProducts);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.searchContainer}>
        <TextField
          id='search'
          name='search'
          type='text'
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          className={classes.input}
          placeholder='Search Products'
          inputProps={{ 'aria-label': 'search google maps' }}
        />
        <IconButton
          onClick={() => submitClear()}
          className={classes.iconButton}
          aria-label='search'
          disabled={search ? false : true}
        >
          {search ? <ClearIcon style={{ color: 'red' }} /> : <SearchIcon />}
        </IconButton>
        <Divider className={classes.divider} orientation='vertical' />
      </Paper>
      <Container>
        <Grid
          container
          className={classes.rightCon}
          spacing={2}
          justify='center'
        >
          {currentProducts.map((product) => (
            <Grid key={product.id} className={classes.container} item xs>
              <ProductCard product={product} />
            </Grid>
          ))}
        </Grid>
        {products.length > 8 ? (
          <div className={classes.paginationContainer}>
            <Pagination
              productsPerPage={productsPerPage}
              totalProducts={products.length}
              paginate={paginate}
              defaultPage={currentPage}
            />
          </div>
        ) : (
          ''
        )}
      </Container>
    </div>
  );
}
