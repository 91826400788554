import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, CssBaseline, IconButton } from '@material-ui/core';
import ContactUsCard from '../components/ContactUsContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/bg.jpg'})`,
  },
  subHeader: {
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerColor: {
    color: 'red',
  },
  container: {
    textAlign: 'center',
    fontSize: '1.5rem',
    color: '#fff',
  },
  goDown: {
    color: 'red',
    fontSize: '3rem',
  },
  logo: {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/logo.jpg'})`,
    height: '130px',
    borderRadius: '2rem',
    backgroundSize: 'cover',
    boxShadow: '5px 5px 20px -5px #2C2C2C',
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  }, [checked]);
  return (
    <div className={classes.root} id='content'>
      <div className={classes.subHeader}>
        <CssBaseline />
        <Collapse
          in={checked}
          {...(checked ? { timeout: 2000 } : {})}
          collapsedHeight={50}
        >
          <ContactUsCard />
        </Collapse>
      </div>
    </div>
  );
};

export default ContactUs;
