import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    width: 200,
    minHeight: 250,
  },
  cardHeader: {
    textAlign: 'center',
    fontSize: '0.9rem',
  },
});

const ProductCard = ({ product }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea component={Link} to={`/singlespecie/${product.id}`}>
        <CardMedia
          component='img'
          alt='Contemplative Reptile'
          height='140'
          image={product.image}
          title='Contemplative Reptile'
        />
        <CardContent>
          <Typography gutterBottom className={classes.cardHeader}>
            {product.name}
          </Typography>
          <Typography
            variant='body2'
            color='textSecondary'
            component='p'
          ></Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size='small'
          color='primary'
          component={Link}
          to={`/singlespecie/${product.id}`}
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
