import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import allProducts from '../data/data';
import ProductCard from '../components/ProductCard';
import Pagination from '../components/PaginationCom';
import { Container } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '7rem',
    height: '100%',
    display: 'flex',
    background: 'inherit',
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1rem',
  },

  leftFlex: {
    flex: '1',
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toggleButton: {
    width: '20vw',
  },
}));

export default function Species() {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);
  const [view, setView] = React.useState('all');
  const labels = [
    'all',
    'bee',
    'cat',
    'cattle',
    'chicken',
    'dog',
    'goat',
    'horse',
    'rabbit',
    'sheep',
    'swine',
    'turkey',
  ];

  const handleChange = (event, nextView) => {
    if (nextView != null) {
      setView(nextView);
      let subProducts = [];
      if (nextView !== 'all') {
        allProducts.map((product) => {
          if (product.type === nextView) {
            subProducts.push(product);
          }
        });
        setProducts(subProducts);
        setCurrentPage(1);
      } else {
        setProducts(allProducts);
        setCurrentPage(1);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      setProducts(allProducts);
    };
    getData();
  }, []);

  // Get current Products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className={classes.root}>
      <Container className={classes.leftFlex}>
        <ToggleButtonGroup
          orientation='vertical'
          value={view}
          color='blue'
          exclusive
          onChange={handleChange}
          className={classes.toggleButton}
          aria-label='text alignment'
        >
          {labels.map((item, index) => (
            <ToggleButton key={index} value={item} aria-label='list'>
              {item}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Container>
      <Container className={classes.rightFlex}>
        <Grid
          container
          className={classes.rightCon}
          spacing={2}
          justify='center'
        >
          {currentProducts.map((product) => (
            <Grid key={product.id} className={classes.container} item xs>
              <ProductCard product={product} />
            </Grid>
          ))}
        </Grid>
        {products.length > 8 ? (
          <div className={classes.paginationContainer}>
            <Pagination
              productsPerPage={productsPerPage}
              totalProducts={products.length}
              paginate={paginate}
              defaultPage={currentPage}
            />
          </div>
        ) : (
          ''
        )}
      </Container>
    </div>
  );
}
