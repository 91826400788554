const factories = [
  {
    imageUrl: process.env.PUBLIC_URL + '/assets/img-1.jpg',
    title: 'All Products',
    to: '/products',
  },
  {
    imageUrl: process.env.PUBLIC_URL + '/assets/img-2.jpg',
    title: 'Products By Species',
    to: '/species',
  },
  {
    imageUrl: process.env.PUBLIC_URL + '/assets/img-3.jpg',
    title: 'General Health',
    to: '/generalhealth',
  },
];

export default factories;
